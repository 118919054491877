import { useAppContext } from "context/AppContext";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  EmptyList,
  TableSkeleton,
  Pagination,
  Table,
  TableTab,
} from "components";
import { useSearchParams } from "react-router-dom";
import { ItemType } from ".";
import OutletService from "services/OutletService";
import ContentLayout from "layouts/ContentLayout";
import { ContentWrapper } from ".";
import users from "assets/svg/outlet-users.svg";

interface MobileTraderProps {
  filterBy: string;
}

const navItems: any = [
  { link: "Mobile traders", linkTo: "../mobiletrader-users" },
  { link: "Reoda", linkTo: "../reoda-users" },
  { link: "Active outlet", linkTo: "../active-outlet" },
  ,
];

const mobileTraderHeaders = [
  { key: 1, item: "Outlet name" },
  { key: 2, item: "URNO" },
  { key: 3, item: "Phone Number" },
  { key: 4, item: "Address" },
  { key: 5, item: "Reps" },
  { key: 6, item: "Depot" },
  { key: 7, item: "Region" },
  { key: 8, item: "Date Created" },
];

const MobiletraderOutlet = () => {
  const { state, dispatch } = useAppContext();
  const [tableData, setTableData] = useState<ItemType[]>([]);
  const [willFilter, setWillFilter] = useState("");
  const isLoading = state?.isLoading;
  const [bySpecific, setBySpecific] = useState("");
  const [byCustomStart, setByCustomStart] = useState("");
  const [byCustomEnd, setByCustomEnd] = useState("");
  const [pageParams, setPageParams] = useSearchParams();
  const [page, setPage] = useState(() => {
    let p = Number(pageParams.get("page"));
    return p > 0 ? p : 1;
  });
  const [pages, setPages] = useState<number>(0);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);

  // useEffect(() => {
  //   async function fetchData() {
  //     try {
  //       dispatch({ type: "data-loading" });
  //       const {
  //         data: {
  //           data,
  //           meta,
  //           meta: { total },
  //         },
  //       } = await OutletService.getAllOutlets("", 10, 0);
  //       setTotal(total);
  //     } catch (error) {
  //       console.log(error);
  //     } finally {
  //       dispatch({ type: "data-loaded" });
  //     }
  //   }
  //   fetchData();
  // }, [limit, page, dispatch]);

  useEffect(() => {
    async function fetchData() {
      try {
        dispatch({ type: "data-loading" });
        const {
          data: {
            data,
            meta: { pageCount, total },
          },
        } = await OutletService.getMobiletraderOutlets(
          limit,
          page,
          bySpecific,
          byCustomStart,
          byCustomEnd
        );

        console.log("///TOTAL", total);

        setTableData(data);
        setPages(pageCount);
        setTotal(total);
        setPageParams({ page: page.toString() });
      } catch (error) {
        setTableData([]);
      } finally {
        dispatch({ type: "data-loaded" });
      }
    }
    fetchData();
  }, [page, limit, willFilter, dispatch]);

  return (
    <ContentLayout titleText="Outlet Mangement" subText="Details of outlet">
      <ContentWrapper>
        <div className="chart-container">
          <div className="flex outlets">
            <div className="section-icon">
              <img src={users} alt="icon" />
            </div>
            <div className="summation text-black-900">
              <div>
                <h3 className="fs-700 fw-600">Total number of outlet</h3>
                <p className="amount-intro fw-400">
                  Number of onboarded outlet
                </p>
              </div>

              <p className="total-amount">{total}</p>
            </div>
          </div>
        </div>
        <MobileTable>
          <TableTab
            navItems={navItems}
            setWillFilter={setWillFilter}
            setBySpecific={setBySpecific}
            setByCustomStart={setByCustomStart}
            setByCustomEnd={setByCustomEnd}
          />

          {isLoading ? (
            <TableSkeleton />
          ) : tableData.length === 0 ? (
            <EmptyList />
          ) : (
            <>
              <div className="table-container">
                <Table
                  headers={mobileTraderHeaders}
                  items={tableData}
                  renderItem={Mobile}
                />
              </div>
              <Pagination
                setLimit={setLimit}
                limit={limit}
                page={page}
                pages={pages}
                setPage={setPage}
              />
            </>
          )}
        </MobileTable>
      </ContentWrapper>
    </ContentLayout>
  );
};

const Mobile = (item: ItemType) => {
  const [isViewDetails, setIsViewDetails] = useState(false);
  const [isMoreAction, setIsMoreAction] = useState(false);
  let start_date = new Date(item.createdAt);
  let startDate = `${start_date.getDate()}/${
    start_date.getMonth() + 1
  }/${start_date.getFullYear()}`;

  return (
    <tr key={item.id}>
      {/* <td onClick={() => setIsViewDetails(true)}>{item.product_code}</td> */}
      <td>{item.outletname}</td>
      <td>{item.urno}</td>
      <td>{item.phoneNumber}</td>
      <td>{"N/A"}</td>
      <td>{item.customer_code}</td>
      <td>{item.depots}</td>
      <td>{item.region}</td>
      <td>{startDate}</td>
      {/* <ActionModal
        item={item}
        display={isMoreAction}
        setIsModal={setIsMoreAction}
      />
      <ViewDetailsModal
        item={item}
        display={isViewDetails}
        setIsModal={setIsViewDetails}
      /> */}
    </tr>
  );
};

const MobileTable = styled.div`
  tbody tr {
    background-color: hsla(var(--clr-light));
    border-top: 8px solid #ececec;
    position: relative;
  }
  tbody tr td:first-child {
    text-decoration: none !important;
    cursor: auto !important;
  }
  .prod {
    display: flex;
    align-items: center;

    span:last-child {
      margin-left: 6px;
    }
  }

  .product-img {
    width: 30px;
    height: 30px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .three-dots {
    display: inline-grid;
    place-items: center;
    width: 100%;
  }
  .flex {
    display: flex !important;
  }
`;

export default MobiletraderOutlet;
