import React, { SetStateAction, useEffect, useState } from "react";
import styled from "styled-components";
import { ActiveItemType } from "..";
import toastr from "toastr";
import { useAppContext } from "context/AppContext";
import OutletService from "../../../services/OutletService";
import ClearLoaderImg from "../../../assets/images/loading.gif";
import SucsessImg from "../../../assets/images/auth/success-tick.gif";
import closeSvg from "../../../assets/svg/close.svg";
import ArrowLeft from "../../../assets/svg/arrow-left.svg";

interface SessionProps {
  item: ActiveItemType;
  handleModalClose: () => void;
  handleCloseButton: () => void;
  setIsModal: React.Dispatch<SetStateAction<boolean>>;
}
interface SuccessProps {
  handleModalClose: () => void;
  setIsModal: React.Dispatch<SetStateAction<boolean>>;
}

type Session = {
  id: string;
  mobileId: string;
  lastLoggedIn: string;
  token: string;
  urno: number;
};

const SessionForm: React.FC<SessionProps> = ({
  item,
  handleModalClose,
  handleCloseButton,
  setIsModal,
}) => {
  const { state, dispatch } = useAppContext();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [listSessionModal, setListSessionModal] = useState(true);
  const [clearSessionModal, setClearSessionModal] = useState(false);
  const [loadingSessionModal, setLoadingSessionModal] = useState(false);
  const [sucessSessionModal, setSucessSessionModal] = useState(false);
  const [allSessions, setAllSessions] = useState([]);
  const [selectedSession, setSelectedSession] = useState<Session>();

  const handleSelectSession = (id: string) => {
    const tempData = allSessions.find((session: Session) => session.id === id);
    if (tempData) {
      setSelectedSession(tempData);
    }

    setListSessionModal(false);
    setClearSessionModal(true);
  };

  const handleBackButton = () => {
    setListSessionModal(true);
    setClearSessionModal(false);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoadingSessionModal(true);
    setClearSessionModal(false);
    try {
      setIsLoading(true);

      let formData = {
        fcmToken: selectedSession?.token,
      };
      let urno = Number(selectedSession?.urno);

      const {
        data: { statusCode, message },
      } = await OutletService.clearSession(urno, formData);
    } catch (error: any) {
      console.log(error);
      toastr.error(error.message);
    } finally {
      setIsLoading(false);
      setLoadingSessionModal(false);
      setSucessSessionModal(true);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const {
          data: { data },
        } = await OutletService.CheckUserLogin(item.urNo);

        let date = new Date(data.createdAt);

        let tempedData: any = [];
        tempedData.push({
          id: data.id,
          mobileId: data.deviceId,
          lastLoggedIn: `${date.getDate()}-${
            date.getMonth() + 1
          }-${date.getFullYear()} ${" "} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`,
          token: data.fcmToken[0],
          urno: data.urno,
        });
        setAllSessions(tempedData);
        setIsLoading(true);
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <Modal className="modal-overlay">
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
          {listSessionModal && (
            <ListSession>
              <div className="closed">
                <img src={closeSvg} alt="" onClick={handleCloseButton} />
              </div>

              <div className="form-container">
                <h2>Deviced Used To Login In</h2>

                <div className="contain">
                  {isLoading && allSessions.length < 1 ? (
                    <div className="img-loader">
                      <img src={ClearLoaderImg} alt="" />
                    </div>
                  ) : !isLoading && allSessions.length < 1 ? (
                    <>
                      <div className="not-found">
                        <div className="content">
                          <h3>Session not found for this user</h3>
                          <div className="btn-container">
                            <button onClick={handleModalClose}>
                              Go to home
                            </button>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      {allSessions?.map((data: Session) => (
                        <div key={data.id} className="contain-ech">
                          <h3>
                            Mobile ID: <span>{data.mobileId}</span>
                          </h3>
                          <h3>
                            Last Logged in:<span>{data.lastLoggedIn}</span>
                          </h3>
                          <button onClick={() => handleSelectSession(data.id)}>
                            Clear login session
                          </button>
                        </div>
                      ))}
                    </>
                  )}
                </div>
              </div>
            </ListSession>
          )}
          {clearSessionModal && (
            <>
              <div className="icon-container">
                <div className="back">
                  <img src={ArrowLeft} alt="" onClick={handleBackButton} />
                </div>
                <div className="closed">
                  <img src={closeSvg} alt="" onClick={handleCloseButton} />
                </div>
              </div>
              <Form>
                <form className="form-container" onSubmit={handleSubmit}>
                  <h2>Session Form</h2>
                  <div className="form-control">
                    <label>Mobile ID:</label>
                    <span>{selectedSession?.mobileId}</span>
                  </div>
                  <div className="form-control">
                    <label>Token:</label>
                    <span>{selectedSession?.token}</span>
                  </div>
                  <div className="btn-container">
                    <button type="submit">Clear login session</button>
                  </div>
                </form>
              </Form>
            </>
          )}
          {loadingSessionModal && <SessionLoader />}
          {sucessSessionModal && (
            <SessionSuccess
              handleModalClose={handleModalClose}
              setIsModal={setIsModal}
            />
          )}
        </div>
      </Modal>
    </>
  );
};

const SessionLoader = () => {
  return (
    <Wrapper>
      <div className="img-container">
        <img src={ClearLoaderImg} alt="" />
      </div>
      <div className="text">
        <h2>Clearing session</h2>
        <p>This might take a while, so bear with us</p>
      </div>
    </Wrapper>
  );
};

const SessionSuccess: React.FC<SuccessProps> = ({ handleModalClose }) => {
  return (
    <SucessWrapper>
      <div className="img-container">
        <img src={SucsessImg} alt="" />
      </div>
      <div className="text">
        <h2>Session cleared</h2>
        <div className="btn-container">
          <button onClick={handleModalClose}>Go to home</button>
        </div>
      </div>
    </SucessWrapper>
  );
};

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;

  .modal-content {
    max-width: 650px;
    width: 100%;
    background: #fff;
    padding: 20px;
    border-radius: 5px;
    border-radius: 5px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    z-index: 11;
  }
  .icon-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .back img {
    width: 15px;
    cursor: pointer;
  }
  .closed img {
    width: 15px;
    cursor: pointer;
  }
`;

const Form = styled.div`
  .form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px;
  }
  .form-container h2 {
    font-size: 1.5rem;
    color: #000;
    font-weight: 500;
    margin-bottom: 30px;
  }

  .headertext: {
    font-size: 1.2rem;
    margin-bottom: 30px;
    font-weight: 500;
  }
  .headertext i {
    font-weight: 400;
    color: #666;
  }
  .form-control {
    width: 100%;
    display: flex;
    gap: 20px;
    margin-bottom: 40px;
  }
  .form-control label {
    color: #333;
    font-weight: 600;
    font-size: 1rem;
  }
  .form-control span {
    color: #555;
    font-size: 1rem;
    width: 80%;
    text-wrap: wrap;
    word-wrap: break-word;
    white-space: wrap;
  }

  .btn-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .btn-container button {
    background: #022f1a;
    width: 200px;
    color: #fff;
    padding: 10px 35px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 600;
  }
  .btn-container button:disabled {
    background: #ccd5d1;
    cursor: auto;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;

  .img-container {
    width: 200px;

    img {
      width: 100%;
    }
  }
  .text {
    text-align: center;

    h2 {
      font-size: 1.3rem;
      color: #000;
      font-weight: 500;
      margin-bottom: 10px;
    }
    p {
      font-size: 0.9rem;
      color: #666;
    }
  }
  .btn-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .btn-container button {
    background: #022f1a;
    width: 200px;
    color: #fff;
    padding: 10px 35px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 600;
  }
`;

const SucessWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;

  .img-container {
    width: 150px;

    img {
      width: 100%;
    }
  }
  .text {
    text-align: center;

    h2 {
      font-size: 1.3rem;
      color: #000;
      font-weight: 500;
      margin-bottom: 30px;
    }
    p {
      font-size: 0.9rem;
      color: #666;
    }
  }
  .btn-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .btn-container button {
    background: #022f1a;
    width: 200px;
    color: #fff;
    padding: 10px 35px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 600;
  }
`;

const ListSession = styled.div`
  .form-container {
    display: flex;
    flex-direction: column;
    padding: 15px;
  }
  .form-container h2 {
    font-size: 1.5rem;
    color: #000;
    font-weight: 500;
    margin-bottom: 30px;
    text-align: center;
  }

  .contain {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    row-gap: 40px;
    height: 350px;
    overflow-y: auto;
    width: 100%;
    flex-wrap: wrap;
  }
  .not-found {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }
  .img-loader {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .img-loader img {
    width: 50%;
  }
  .btn-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .btn-container button {
    background: #022f1a;
    width: 200px;
    color: #fff;
    padding: 10px 35px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 600;
    margin-top: 20px;
  }
  .btn-container button:disabled {
    background: #ccd5d1;
    cursor: auto;
  }
  .contain-ech {
    width: 250px;

    h3 {
      font-size: 1.1rem;
      color: #333;
      font-weight: 600;
      margin-bottom: 10px;
      text-wrap: wrap;
      word-wrap: break-word;
      white-space: wrap;
    }
    span {
      color: #555;
      font-size: 1rem;
      padding-left: 5px;
      font-weight: 500;
      text-wrap: wrap;
      word-wrap: break-word;
      white-space: wrap;
    }
    button {
      border: none;
      border-bottom: 3px solid #022f1a;
      color: #022f1a;
      background: transparent;
      display: flex;
      font-weight: bold;
      cursor: pointer;
      margin-top: 20px;
    }
  }
`;

export default SessionForm;
