import React, { useEffect, useState } from "react";
import ContentLayout from "../../../layouts/ContentLayout";
import { useParams, useSearchParams } from "react-router-dom";
import FinanceService from "../../../services/FinanceService";
import styled from "styled-components";
import { formatMoney } from "../../../utils/formatMoney";
import { useAppContext } from "context/AppContext";
import GetBankStatement from "../GetBankStatement";
import {
  Table,
  TableSkeleton,
  TableTab,
  EmptyList,
  Chart,
  Pagination,
} from "components";
interface RepsDetailsProps {}

type ItemType = {
  amount: number;
  createdAt: string;
  outletId: string;
};

const header = [
  { key: 1, item: "Outlet under rep" },
  { key: 2, item: "Amount" },
  { key: 3, item: "Date" },
];

export const RepsDetails: React.FC<RepsDetailsProps> = () => {
  const [tableData, setTableData] = useState<ItemType[]>([]);
  const [willFilter, setWillFilter] = useState("");
  const [bySpecific, setBySpecific] = useState("");
  const [byCustomStart, setByCustomStart] = useState("");
  const [byCustomEnd, setByCustomEnd] = useState("");
  const [pageParams, setPageParams] = useSearchParams();
  const [page, setPage] = useState(() => {
    let p = Number(pageParams.get("page"));
    return p > 0 ? p : 1;
  });
  const [pages, setPages] = useState<number>(0);
  const [limit, setLimit] = useState(10);
  const { repsId } = useParams();
  const { state, dispatch } = useAppContext();

  const renderDetail = (item: ItemType) => {
    let date = new Date(item.createdAt);
    return (
      <tr key={item.createdAt}>
        <td>{item.outletId}</td>
        <td>{formatMoney(item.amount)}</td>
        <td className="table-date">
          <p>{`${date.getDate()}/${
            date.getMonth() + 1
          }/${date.getFullYear()}`}</p>
          <p>{`${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`}</p>
        </td>
      </tr>
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch({ type: "data-loading" });
        const { data: d } = await FinanceService.getIndividualRep(
          repsId!,
          limit,
          page,
          bySpecific,
          byCustomStart,
          byCustomEnd
        );
        const { data, meta } = d.data;

        if (d.data && d.data.length === 0) setTableData(d.data);
        if (!data) return;
        setTableData(data);
        setPages(meta.pageCount);
        setPageParams({ page: page.toString() });
      } catch (error) {
      } finally {
        dispatch({ type: "data-loaded" });
      }
    };

    fetchData();
  }, [page, limit, repsId, willFilter, dispatch]);

  return (
    <ContentLayout
      titleText="Finance Management"
      subText={
        <a
          href="https://datastudio.google.com/u/0/reporting/1huaD4958Ku4DSHATkhX_rz0_tOwZUS5Q/page/p_lzm2m0d9sc"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "underline" }}
        >
          Dynamics sales report
        </a>
      }
      component={<GetBankStatement />}
    >
      <DepotDetailsWrap>
        <div className="chart-container">
          <Chart />
        </div>

        <div className="data-container">
          <TableTab
            data={tableData}
            file={"details.csv"}
            text={`${repsId} transaction details`}
            setBySpecific={setBySpecific}
            setByCustomStart={setByCustomStart}
            setByCustomEnd={setByCustomEnd}
            setWillFilter={setWillFilter}
          />

          {state?.isLoading ? (
            <TableSkeleton />
          ) : tableData.length === 0 ? (
            <EmptyList />
          ) : (
            <>
              <div className="table-container">
                <Table
                  headers={header}
                  items={tableData}
                  renderItem={renderDetail}
                />
              </div>
              <Pagination
                limit={limit}
                setLimit={setLimit}
                page={page}
                pages={pages}
                setPage={setPage}
              />
            </>
          )}
        </div>
      </DepotDetailsWrap>
    </ContentLayout>
  );
};

const DepotDetailsWrap = styled.div`
  height: 100%;
  overflow-y: scroll;
  position: relative;

  .chart-container {
    padding: 20px 0;
  }
`;
