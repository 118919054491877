import { useEffect, useState, Dispatch, SetStateAction } from "react";
import styled from "styled-components";
import { Checkbox } from "components/CheckBox";
import { v4 as uuidv4 } from "uuid";
import { ItemType } from "pages/CustormerSupport/CustomerSupportManagement";

interface MultiChoiceProps {
  setQuestionsForms: Dispatch<SetStateAction<any>>;
  setIsModal: Dispatch<SetStateAction<boolean>>;
  handleCloseButton: () => void;
  selectedId?: string;
  questionsForms?: any;
  setSelectedId: Dispatch<SetStateAction<string>>;
}

const MultiChoiceForm: React.FC<MultiChoiceProps> = ({
  handleCloseButton,
  setQuestionsForms,
  setIsModal,
  selectedId,
  questionsForms,
  setSelectedId,
}) => {
  const [title, setTitle] = useState("");
  const [options, setOptions] = useState([""]);
  const [required, setRequired] = useState(false);

  const handleOptionChange = (index: number, value: string) => {
    const newOptions = [...options];
    newOptions[index] = value;
    setOptions(newOptions);
  };

  const handleAddOption = () => {
    setOptions([...options, ""]); // Add an empty option
  };

  const handleRemoveOption = (index: number) => {
    const newOptions = [...options];
    newOptions.splice(index, 1);
    setOptions(newOptions);
  };

  const handleMultiChoice = (e: any) => {
    e.preventDefault();

    if (selectedId) {
      const index = questionsForms.findIndex(
        (obj: any) => obj.id === selectedId
      );

      if (index !== -1) {
        setQuestionsForms((prev: any) => {
          const newArray = [...prev];
          newArray[index] = {
            ...newArray[index],
            title,
            isRequired: required,
            options,
          };
          return newArray;
        });
      }
      setSelectedId("");
      handleCloseButton();
    } else {
      const obj = {
        id: uuidv4(),
        optionType: "CHECK_BOX",
        title,
        options,
        isRequired: required,
      };
      setQuestionsForms((prev: any) => {
        return [...prev, obj];
      });
      setSelectedId("");
      handleCloseButton();
    }
  };

  useEffect(() => {
    if (selectedId) {
      const filteredObj: any = questionsForms.filter(
        (data: any) => data.id === selectedId
      );
      filteredObj.forEach((data: any) => {
        setTitle(data.title);
        setRequired(data.isRequired);
        setOptions(data.options);
      });
    }
  }, [selectedId]);
  return (
    <Form>
      <form className="form-container" onSubmit={handleMultiChoice}>
        <div className="form-control">
          <div className="input-bottom">
            <input
              required
              placeholder="Type your question"
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          {options.map((option, index) => (
            <div key={index} style={{ position: "relative" }}>
              <div className="input-options">
                <p className="pfrst">
                  {String.fromCharCode("A".charCodeAt(0) + index)}
                </p>
                <input
                  required
                  placeholder="Add an option"
                  type="text"
                  value={option}
                  onChange={(e) => handleOptionChange(index, e.target.value)}
                />
                {index > 0 && (
                  <p className="plst" onClick={() => handleRemoveOption(index)}>
                    x
                  </p>
                )}
              </div>
            </div>
          ))}
          <h4 onClick={handleAddOption}>Add More Options</h4>
          <div className="btn-container">
            {!selectedId && (
              <button className="btns btn-outline" onClick={handleCloseButton}>
                Delete
              </button>
            )}
            <button className="btns">{selectedId ? "Edit" : "Create"}</button>
          </div>
          <div className="container_bottom">
            <Checkbox
              id="checkbox"
              checked={required}
              onChange={(e: any) => setRequired(e.target.checked)}
            />
            <label htmlFor="checkbox">compulsory</label>
          </div>
        </div>
      </form>
    </Form>
  );
};

const Form = styled.div`
  .form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
  }
  .form-control {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  .form-control h4 {
    color: #db8130;
    font-weight: 600;
    cursor: pointer;
    font-size: 0.9rem;
  }
  }
  .input-bottom input {
    width: 100%;
    background: #fff;
    height: 40px;
    padding: 5px;
    border: none;
    border-bottom: 2px solid #555;
    outline: none;
    color: #022f1a;
    font-weight: 600;
  }
  input-options {
    position: relative;
  }
  .input-options input {
    position: relative;
    width: 100%;
    background: #fff;
    height: 40px;
    padding: 5px;
    padding-left: 50px;
    border: 2px solid #ccc;
    border-radius: 5px;
    outline: none;
  }
  .pfrst {
    position: absolute;
    top: 10px;
    z-index: 1;
    left: 10px;
    top: 12px;
    color: #022f1a;
    font-weight: 600;
  }
  .plst {
    position: absolute;
    top: 10px;
    z-index: 1;
    right: 10px;
    top: 12px;
    color: #022f1a;
    font-weight: 900;
    cursor: pointer;
  }
  .btn-container {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 15px
  }
  .btns {
    background: #022f1a;
    color: #fff;
    padding: 10px 35px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 600;
  }

  .btn-outline {
    border: 1px solid #022f1a;
    background: #fff;
    color: #022f1a;
  }
  .btn:disabled {
    background: #CCD5D1;
    cursor: auto;
  }
  .container_bottom {
    display: flex;
    align-center: center;
    gap: 10px;
  }
  .container_bottom label {
    font-size: 0.9rem;
  }
`;

export default MultiChoiceForm;
