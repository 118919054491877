import React, { useState, useEffect } from "react";
import FinanceService from "../../../services/FinanceService";
import {
  useNavigate,
  useSearchParams,
  useOutletContext,
} from "react-router-dom";
import { formatMoney } from "../../../utils/formatMoney";
import { useAppContext } from "context/AppContext";
import { Table, TableSkeleton, EmptyList, Pagination } from "components";
import { ContextType } from "..";

interface DepotProps {}

type ItemType = {
  amount: number;
  createdAt: string;
  depot: string;
  region: string;
  repsId: string;
};

const depotHeaders = [
  { key: 1, item: "Depot ID" },
  { key: 2, item: "Region" },
  { key: 3, item: "Reps ID" },
  { key: 4, item: "Amount" },
  { key: 5, item: "Date" },
];

export const Depot: React.FC<DepotProps> = () => {
  const { state, dispatch } = useAppContext();
  const [tableData, setTableData] = useState<ItemType[]>([]);
  const [pageParams, setPageParams] = useSearchParams();
  const [page, setPage] = useState(() => {
    let p = Number(pageParams.get("page"));
    return p > 0 ? p : 1;
  });
  const [pages, setPages] = useState<number>(0);
  const [limit, setLimit] = useState(10);
  const navigate = useNavigate();

  const [toCsv, setText, setFileName, value, startDate, endDate, filter] =
    useOutletContext<ContextType>();
  const handleNavigate = (param: string) => {
    navigate(`/dashboard/finance/depot/${param}`);
  };
  const renderDepot = (item: ItemType) => {
    let date = new Date(item.createdAt);
    return (
      <tr key={item.createdAt}>
        <td onClick={() => handleNavigate(item.depot)}>{item.depot}</td>
        <td>{item.region}</td>
        <td>{item.repsId}</td>
        <td>{formatMoney(item.amount)}</td>
        <td className="table-date">
          <p>{`${date.getDate()}/${
            date.getMonth() + 1
          }/${date.getFullYear()}`}</p>
          <p>{`${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`}</p>
        </td>
      </tr>
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch({ type: "data-loading" });
        const { data: d } = await FinanceService.getAllDepotDetails(
          limit,
          page,
          value,
          startDate,
          endDate
        );
        const { data, meta } = d.data;

        if (d.data && d.data.length === 0) setTableData(d.data);
        if (!data) return;
        setTableData(data);
        setText(toCsv(data));
        setFileName("depots.csv");
        setPages(meta.pageCount);
        setPageParams({ page: page.toString() });
      } catch (error: any) {
        setTableData([]);
        if (error.response) {
          console.log(error.response);
        } else {
          console.log(error);
        }
      } finally {
        dispatch({ type: "data-loaded" });
      }
    };

    fetchData();
  }, [
    page,
    limit,
    filter,
    setFileName,
    setText,
    setPageParams,
    toCsv,
    dispatch,
  ]);

  return (
    <>
      {state?.isLoading ? (
        <TableSkeleton />
      ) : tableData.length === 0 ? (
        <EmptyList />
      ) : (
        <>
          <div className="table-container">
            <Table
              headers={depotHeaders}
              items={tableData}
              renderItem={renderDepot}
            />
          </div>
          <Pagination
            limit={limit}
            setLimit={setLimit}
            page={page}
            pages={pages}
            setPage={setPage}
          />
        </>
      )}
    </>
  );
};
