import styled from 'styled-components';

const ButtonSpinner = () => {
	return <SpinnerWrapper></SpinnerWrapper>;
};

const SpinnerWrapper = styled.div`
	position: absolute;
	width: 25px;
	height: 25px;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	border: 4px solid transparent;
	border-top-color: #fff;
	border-radius: 50%;
	animation: button-loading-spinner 1s linear infinite;

	@keyframes button-loading-spinner {
		from {
			transform: rotate(0turn);
		}
		to {
			transform: rotate(1turn);
		}
	}
`;

export default ButtonSpinner;
