import { useState, useEffect } from "react";
import { OrderTab } from "components";
import { useSearchParams, useLocation } from "react-router-dom";
import TotalOrderIcon from "../../assets/svg/totalOrder.svg";
import FailedOrderIcon from "../../assets/svg/failedOrder.svg";
import TotalDeliveryIcon from "../../assets/svg/totalDelivery.svg";
import ContentLayout from "../../layouts/ContentLayout";
import styled from "styled-components";
import OrderService from "services/OrderManagementService";
import { useAppContext } from "context/AppContext";

const navItems = [
  { link: "All Orders", linkTo: "all-orders" },
  { link: "Pending", linkTo: "pending-orders" },
  { link: "Awaiting", linkTo: "awaiting-orders" },
  { link: "Delivered", linkTo: "delivered-orders" },
  { link: "Failed", linkTo: "failed-orders" },
];

export type csvType = {
  orderId: string;
  transactionId: string;
  paymentStatus: string;
  customerInfo: any;
  deviceChannel: string;
  type: string;
  totalQuantity: number;
  totalAmount: number;
  deliveryStatus: string;
  IsMobileMoney: string;
  createdAt: string;
  paymentAmount: number;
  region: string;
  depot: string;
  orderType: string;
  salesRep: string;
  creationDate: string;
  deliveryDate: string;
};

const OrderManagement = () => {
  const [csvData, setCsvData] = useState([]);
  const [statusCount, setStatusCount] = useState<any>();
  const [status, setStatus] = useState("all");
  const [search, setSearch] = useState("");
  const [byRegion, setByRegion] = useState("");
  const [byDepots, setByDepots] = useState("");
  const [byOrderType, setByOrderType] = useState("");
  const [bySpecific, setBySpecific] = useState("");
  const [byCustomStart, setByCustomStart] = useState("");
  const [byCustomEnd, setByCustomEnd] = useState("");
  const [willFilter, setWillFilter] = useState("");
  const [pageParams, setPageParams] = useSearchParams();
  const [page, setPage] = useState(() => {
    let p = Number(pageParams.get("page"));
    return p > 0 ? p : 1;
  });
  const [pages, setPages] = useState<number>(0);
  const [limit, setLimit] = useState(10);
  const { dispatch } = useAppContext();
  const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch({ type: "data-loading" });

        const { data: d } = await OrderService.getOrders(
          status,
          search,
          byOrderType,
          byRegion,
          byDepots,
          byCustomStart,
          byCustomEnd,
          limit,
          page
        );
        const { data, meta, statusCounts } = d.data;
        if (!data) {
          return;
        }
        let csvData = data.map((item: csvType) => {
          let date = new Date(item.createdAt);
          let deliveryDate = item?.deliveryDate
            ? new Date(item?.deliveryDate)
            : null;
          return {
            orderid: item.orderId,
            transactionid: item.transactionId,
            orderStatus: item.deliveryStatus,
            quantity: item.totalQuantity,
            paymentAmount: item.totalAmount,
            region: item.customerInfo.region,
            depot: item.customerInfo.depot,
            orderType:
              item?.type && item.type?.toLowerCase() === "normal"
                ? "Non-promo"
                : item.type?.toLowerCase() === "promo"
                ? "Promo"
                : "NIL",
            salesRep: item.customerInfo?.repname,
            creationDate: `${date.getDate()}/${
              date.getMonth() + 1
            }/${date.getFullYear()}`,
            deliveryDate: deliveryDate
              ? `${deliveryDate.getDate()}/${
                  deliveryDate.getMonth() + 1
                }/${deliveryDate.getFullYear()}`
              : "NIL",
          };
        });
        setCsvData(csvData);
        setStatusCount(statusCounts);
      } catch (error) {
      } finally {
        dispatch({ type: "data-loaded" });
      }
    };
    fetchData();
  }, [limit, page, dispatch, willFilter, status, search]);

  useEffect(() => {
    const currentUrl = location.pathname;
    if (currentUrl?.split("/")[3] === undefined) return;
    let currentStatus = currentUrl?.split("/")[3].split("-")[0];
    setStatus(currentStatus);
  }, [location]);

  return (
    <ContentLayout
      titleText="Order Management"
      subText="Details of Order"
      setSearch={setSearch}
    >
      <ContentWrapper>
        <div className="chart-container flex">
          <div className="orders-total">
            <div className="section-icon">
              <img src={TotalOrderIcon} alt="icon" />
            </div>
            <div className="summation">
              <p className="amount-intro">Total order</p>
              <p className="total-amount">{statusCount?.all}</p>
            </div>
          </div>
          <div className="orders-total">
            <div className="section-icon">
              <img src={TotalDeliveryIcon} alt="icon" />
            </div>
            <div className="summation">
              <p className="amount-intro">Total delivery</p>
              <p className="total-amount">{statusCount?.delivered}</p>
            </div>
          </div>
          <div className="orders-total">
            <div className="section-icon">
              <img src={FailedOrderIcon} alt="icon" />
            </div>
            <div className="summation">
              <p className="amount-intro">Failed order</p>
              <p className="total-amount">{statusCount?.failed}</p>
            </div>
          </div>
        </div>

        <div className="grid data-container">
          <OrderTab
            navItems={navItems}
            data={csvData}
            file={`${status}-orders.csv`}
            canFilter={true}
            setByRegion={setByRegion}
            setByDepots={setByDepots}
            setByOrderType={setByOrderType}
            setWillFilter={setWillFilter}
            setBySpecific={setBySpecific}
            setByCustomStart={setByCustomStart}
            setByCustomEnd={setByCustomEnd}
            search={search}
          />
        </div>
      </ContentWrapper>
    </ContentLayout>
  );
};

const ContentWrapper = styled.div`
  height: 100%;
  overflow-y: scroll;
  position: relative;

  .chart-container {
    padding: 20px 0;
  }

  .orders-total {
    width: 350px;
    height: 180px;
    background-color: #fff;
    margin: 0 auto;
    border-radius: 8px;
    padding: 20px 25px;
  }
  .section-icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: grid;
    place-items: center;
    margin-top: 10px;

    img {
      object-fit: contain;
    }
  }
  .summation {
    display: flex;
    flex-direction: column;

    .amount-intro {
      margin-top: 15px;
    }
    .total-amount {
      margin-top: auto;
      color: #022f1a;
      font-size: 22px;
      font-weight: 600;
      margin-top: 15px;
    }
  }
`;

export default OrderManagement;
