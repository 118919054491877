export type OrderByOutletType = {
  token: number;
  paymentStatus: string;
  region: string;
  depot: string;
  deviceChannel: string;
  outletID: number;
  repsID: string;
  quantity: number;
  price: number;
  createdAt: string;
  totalQuantity: string;
  IsMobileMoney: boolean;
  totalAmount: number;
  deliveryStatus: string;
  customerInfo: { [k: string]: string | number };
  products: {
    amount: number;
    image: string;
    productCode: string;
    productName: string;
    quantity: number;
    _id: string;
  }[];
};

export const orderHeaders = [
  { key: 1, item: "Token" },
  { key: 2, item: "Payment Status" },
  { key: 3, item: "Region" },
  { key: 4, item: "Depot" },
  { key: 5, item: "Channel" },
  { key: 6, item: "Outlet ID" },
  { key: 7, item: "Reps ID" },
  { key: 8, item: "Quantity" },
  { key: 9, item: "Method" },
  { key: 10, item: "Price" },
  { key: 11, item: "Delivery Status" },
  { key: 12, item: "Date" },
];

export type csvType = {
  token: number;
  paymentStatus: string;
  region: string;
  depot: string;
  channel: string;
  outletID: number;
  repsID: string;
  quantity: number;
  price: number;
  status: string;
  method: string;
  createdAt: string;
};

export type RouterOutletContextType = [
  toCsv: (table: {}[]) => string,
  setText: React.Dispatch<React.SetStateAction<string>>,
  setFileName: React.Dispatch<React.SetStateAction<string>>,
  value: string,
  startDate: string,
  endDate: string,
  filter: string
];

export type OrdersProp = {
  status?: "all" | "pending" | "awaiting" | "delivered" | "failed" | "canceled";
};

export type ContextType = [
  toCsv: (table: {}[]) => string,
  setText: React.Dispatch<React.SetStateAction<string>>,
  setFileName: React.Dispatch<React.SetStateAction<string>>,
  value: string,
  startDate: string,
  endDate: string,
  filter: string
];
