import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useParams, useNavigate } from "react-router-dom";
import AuthService from "services/AuthenticationService";
import BaseLoader from "components/Loaders/BaseLoader";
import { useAppContext } from "context/AppContext";
import ButtonSpinner from "components/Loaders/ButtonSpinner";

interface ChangePasswordProps {}

const ResetPassword: React.FC<ChangePasswordProps> = () => {
  const [isVerified, setIsVerified] = useState(false);
  const [id, setId] = useState("");
  const [pwd, setPwd] = useState("");
  const [confirmPwd, setConfirmPwd] = useState("");
  const isSame = pwd === confirmPwd;
  // const isSame = pwd.toLowerCase() === confirmPwd.toLowerCase();
  const matched = isSame ? null : "Password & Confirm password must match";
  const isEmpty = pwd === "";
  const [error, setError] = useState({ isError: false, message: "" });
  const [isHideP, setIsHideP] = useState(true);
  const [isHideCP, setIsHideCP] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const { token } = useParams();
  const navigate = useNavigate();
  const { dispatch } = useAppContext();

  useEffect(() => {
    async function verifyToken() {
      if (!token) return;
      try {
        const {
          status,
          data: {
            data: {
              success,
              user: { _id },
            },
          },
        } = await AuthService.verifyToken(token, "password");
        if (status === 200 && success) {
          setIsVerified(true);
          setId(_id);
        } else {
          dispatch({ type: "logout" });
          navigate("/dashboard");
        }
      } catch (error) {
        console.warn(error);
        dispatch({ type: "logout" });
        navigate("/login");
      }
    }

    verifyToken();
  }, [dispatch, navigate, token]);

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    const body = {
      token,
      newPassword: pwd,
    };
    try {
      setIsLoading(true);
      AuthService.resetPassword(body)
        .then((payload) => {
          if (payload.status === 200) {
            navigate("/forgot-success");
          }
        })
        .catch((err) => {
          console.log(err.response);
          if (err.response) {
            setError({ isError: true, message: err.response.data.message });
          } else if (err.request) {
            setError({ isError: true, message: "No response received" });
          } else {
            setError({ isError: true, message: err.message });
          }
        });
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Main className="grid bg-blue-dark-700">
      <div className="logo">
        <svg
          width="167"
          height="26"
          viewBox="0 0 167 26"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.58602 6.53625H12.9268L15.3961 6.97201L17.8653 7.98877L19.3179 9.15078L20.4799 10.3128L20.9156 11.1843L21.2061 11.039L21.4966 12.2011L22.0776 14.0893L22.2229 15.5418L22.0776 18.5921L21.7871 19.4636L21.3514 19.6089L21.4966 19.8994L20.9156 20.1899L21.2061 20.6256H20.6251L20.0441 21.2066L20.3346 21.3519L20.1894 21.7877L19.8989 21.6424V21.3519H19.6084L19.4631 22.0782L19.6084 22.3687L19.0274 22.2234V22.6592L18.4464 22.5139L18.5916 22.9497L18.1559 23.2402L17.7201 22.8044L17.5748 23.5307H17.1391H16.7033L16.5581 23.9664L15.9771 23.8212L15.6866 24.4022L15.3961 24.5474L13.2173 24.6927H9.73127L7.262 24.2569L4.93797 23.3854L3.34021 22.3687L2.1782 21.2066L1.30669 19.7541L0.580432 17.7206L0.435181 15.1061L0.725683 12.7821L1.30669 11.4748L2.1782 10.1675L3.92121 8.42452L6.09999 7.26251L8.27876 6.68151L9.58602 6.53625Z"
            fill="#022F1A"
          />
          <path
            d="M43.866 0.726196H47.0615V13.3631L47.2067 18.3016L47.6425 19.3184L48.8045 20.3351L50.5475 21.2066L51.5643 21.4971L52 21.9329V24.5474L51.419 24.9832H50.257L48.2235 24.4022L46.19 23.0949L44.8827 22.0782H43.866L42.7039 23.0949L41.6872 23.6759L39.5084 24.4022L37.7654 24.6927H34.4246L31.5196 24.1117L29.7766 23.3854L28.3241 22.5139L27.0168 21.2066L26.1453 19.7541L25.2738 18.0111L24.9833 16.2681V14.9608L25.2738 13.2178L26.4358 10.8938L27.4525 9.58653L28.4693 8.56977L30.0671 7.55301L32.2458 6.82675L33.1174 6.6815H38.4917L40.0894 6.82675L40.5252 7.11726L40.0894 9.87703L39.5084 9.73178V10.1675H38.4917L36.0224 9.87703L34.1341 10.0223L31.8101 10.8938L30.5028 11.7653L29.6313 12.7821L29.0503 14.2346V17.2849L29.6313 18.4469L30.5028 19.4636L31.9553 20.3351L34.1341 20.9161L36.3129 21.0614L38.7822 20.6256L40.8157 19.7541L42.1229 18.5921L42.9944 17.2849L43.1397 16.8491V1.0167L43.866 0.726196Z"
            fill="#022F1A"
          />
          <path
            d="M62.7486 6.53625H66.0894L68.4134 6.82676L71.1732 8.42452V7.84352L71.6089 7.98877H71.7542L72.1899 6.68151H74.9497L75.3855 6.82676V24.6927H72.1899L72.0447 16.7039L71.7542 14.6703L70.4469 12.3463L69.2849 11.3296L66.9609 10.1675L65.5084 9.87704H63.4749L61.7318 10.3128L59.6983 11.3296L58.5363 12.2011L57.6648 13.6536L57.2291 14.9608V16.4134L57.9553 18.1564L58.8268 19.1731L60.7151 20.3351L62.8939 20.9161H68.2682L68.5587 22.3687V24.5474L68.1229 24.6927H62.4581L59.8436 24.1117L57.8101 23.2402L56.2123 22.0782L55.0503 20.9161L54.1788 19.4636L53.5978 17.8659L53.4525 17.1396V14.2346L53.8883 12.4916L54.7598 10.7485L56.3575 9.00553L58.3911 7.69827L60.1341 6.97201L61.4413 6.68151L62.7486 6.53625Z"
            fill="#022F1A"
          />
          <path
            d="M10.1676 9.73181H12.4916L14.2346 10.1676L16.1229 11.1843L17.2849 12.3463L18.3017 14.3799L18.4469 14.8156V16.5586L17.8659 18.1564L16.5586 19.6089L15.3966 20.3352L12.4916 21.0614H10.0223L7.55305 20.6257L5.51953 19.4637L4.50277 18.3016L4.06702 16.8491V14.5251L4.64802 12.9273L5.37428 11.9106L6.82679 10.8938L8.42456 10.1676L10.1676 9.73181Z"
            fill="#E7F9F2"
          />
          <path
            d="M141.184 14.6704H143.944L144.67 14.9609L144.525 15.3967H142.782L141.911 15.5419H144.67L144.816 15.9777H145.251L145.542 16.4134L145.687 15.3967L147.43 15.2514L147.575 15.3967V24.6928H146.413L145.832 24.4023V24.257L146.559 24.1118L145.687 23.9665V23.676L144.38 24.1118L144.089 24.6928H141.475L140.168 24.257L138.86 23.095L138.279 21.933L137.989 20.9162V18.8827L138.425 16.9944L139.587 15.5419L140.749 14.8157L141.184 14.6704Z"
            fill="#022F1A"
          />
          <path
            d="M102.983 14.5251H104.145L105.452 14.9609L106.614 15.9777L107.485 17.1397L107.921 18.3017V20.3352L107.631 20.4805H104.29L101.094 20.3352V20.6257H100.513L100.949 21.933L101.675 22.8045L102.983 23.2402L104.58 23.095L105.307 22.6592L106.033 21.7877H107.776L107.485 22.6592L106.469 23.8212L105.307 24.4022L104.726 24.5475H102.402L100.368 23.8212L99.2061 22.514L98.6251 21.0615V18.7374L99.2061 17.1397L100.078 15.8324L100.949 15.1062L102.111 14.6704L102.983 14.5251Z"
            fill="#022F1A"
          />
          <path
            d="M44.0112 0H46.6257L47.0615 0.145251L47.4972 1.16201L47.6425 4.93855L47.7877 18.1564L48.0782 18.8827L49.095 20.0447L50.5475 20.7709L52 21.2067L52.4358 21.933V24.838L52.1453 25.5642L51.7095 26L50.6928 25.8547L48.0782 25.1285L47.6425 24.6927L46.771 24.257L45.609 23.095L44.1565 22.3687L43.5755 22.9497H43.285L43.1397 23.3855H42.5587V23.676H42.1229L42.2682 24.1117L40.2347 25.1285L37.7654 25.5642H34.1341L31.8101 25.1285L29.7766 24.1117L29.0503 23.5307L27.7431 22.514L26.2905 20.6257L26 19.8994L25.419 19.7542L25.5643 19.0279L25.1285 19.1732L24.9833 17.8659V16.2682H25.1285L25.419 18.0112L26.8715 20.7709L28.1788 22.2235L29.4861 23.095L30.7933 23.676L32.6816 24.257L34.4246 24.5475H37.7654L39.9442 24.1117L41.6872 23.5307L42.9944 22.6592L43.866 21.933H44.8827L46.19 22.9497L47.7877 23.9665L48.8045 24.4022L50.257 24.838H51.419L51.8548 24.5475V21.933L51.2738 21.4972L50.4023 21.2067L48.3688 20.1899L47.352 19.1732L47.0615 18.3017L46.9162 13.3631V7.26257L47.0615 0.726257L43.866 0.871508L43.285 1.01676V16.8492L42.8492 17.7207L41.8324 19.0279L40.8157 19.8994L38.7822 20.7709L36.3129 21.2067L34.1341 21.0615L31.9553 20.4804L30.3576 19.4637L29.1956 18.0112L28.9051 16.9944V14.2346L29.1956 13.7989V16.8492L29.6313 17.8659L30.3576 18.5922L31.6648 19.6089L32.6816 20.1899L34.1341 20.6257L35.0056 20.7709H37.3296L38.9274 20.3352L40.2347 19.7542L41.5419 18.7374L42.1229 18.0112L42.4134 17.1397L42.7039 13.2179L42.8492 9.29609V0.581006L43.1397 0.290503L44.0112 0Z"
            fill="#022F1A"
          />
          <path
            d="M127.24 11.6201H128.983L129.129 15.1062L129.274 16.7039L129.419 16.2682L129.855 15.8324V15.1062L130.726 14.6704L131.453 14.5251L133.341 14.6704L134.212 15.1062L135.229 16.7039L135.955 17.7207L136.101 19.0279V24.5475H134.067L133.922 18.1564L133.341 16.9944L132.324 16.5587H131.162L129.71 17.1397L129.274 17.7207L129.129 18.3017L128.983 24.6927H127.24V11.6201Z"
            fill="#022F1A"
          />
          <path
            d="M84.8274 14.6704H87.1514L88.3135 15.2514L89.0397 15.8324L89.185 16.4134L90.347 15.2514L90.6375 15.3967L90.0565 15.9777L90.347 16.5587L90.7827 16.4134V16.8492L90.347 17.2849L90.0565 18.0112L89.9112 19.1732L89.766 24.5475H88.023L87.8777 18.5922L87.5872 17.7207L87.0062 16.8492L85.6989 16.4134H84.3917L83.5202 16.9944L82.9392 18.5922L82.7939 23.095L82.6487 24.257H82.3582V24.5475H81.0509L80.9056 24.257V21.2067L81.0509 15.5419L85.9894 15.3967L83.9559 15.2514V15.1062H84.8274V14.6704Z"
            fill="#022F1A"
          />
          <path
            d="M120.559 14.5251L122.156 14.6704L123.609 15.3967L124.626 16.2682L125.061 16.9944L124.916 18.1564L123.899 18.3017L122.883 17.7207L121.866 16.8492L121.43 16.7039H119.978L118.816 17.2849L118.235 18.0112L117.944 19.1732V20.9162L118.38 21.933L119.106 22.6592L120.268 23.095L121.721 22.9497L123.609 21.6425L124.48 21.4972L125.352 21.933L124.48 23.2402L123.318 24.1117L122.302 24.5475L120.268 24.6927L118.816 24.257L117.363 23.3855L116.346 22.0782L115.911 20.6257V19.1732L116.492 17.2849L117.363 15.9777L118.38 15.1062L119.542 14.6704L120.559 14.5251Z"
            fill="#022F1A"
          />
          <path
            d="M141.911 16.4135H143.654L144.525 16.8492L145.397 18.0112L145.832 19.3185V20.771L145.397 22.0783L144.089 22.9498L142.927 23.2403L141.475 23.095L140.313 22.3688L139.587 21.2067L139.441 20.771V19.1732L140.022 17.7207L141.184 16.704L141.911 16.4135Z"
            fill="#E7F9F2"
          />
          <path
            d="M154.258 14.5251H155.42L156.727 14.9609L157.889 15.9777L158.47 16.9944L158.615 17.5754L158.76 19.6089V24.5475H157.598L157.453 20.771L157.308 18.5922L156.872 17.4302L155.855 16.5587L154.258 16.2682V16.5587L153.096 16.9944L152.224 18.3017L152.079 18.8827L151.934 24.5475H150.917L150.626 17.2849V14.8156H151.788V15.5419L152.224 15.6872L152.369 16.7039L152.805 15.2514L153.531 14.6704L154.258 14.5251Z"
            fill="#022F1A"
          />
          <path
            d="M163.117 12.4916L163.553 12.6368L163.989 14.8156L163.844 22.9497L165.587 23.0949L165.877 23.2402V24.5474L165.732 24.8379H164.715L163.408 24.4022L162.101 23.8212V17.2849L161.52 16.7039L161.229 16.5586V14.9609L161.81 14.3798H162.101L162.391 12.7821L163.117 12.4916Z"
            fill="#022F1A"
          />
          <path
            d="M113.732 14.6704H114.749V16.4134L114.023 16.8492L112.861 17.2849L111.989 18.3017L111.844 24.4023L111.554 24.838L110.537 24.6928L110.101 24.5475L109.956 24.1118V21.0615L110.101 15.2514H112.135L112.716 15.6872L113.151 14.8157L113.732 14.6704Z"
            fill="#022F1A"
          />
          <path
            d="M92.0894 14.5251H93.3966L94.7039 14.9609L95.7206 15.6872L96.3016 16.7039L96.5921 18.7374V24.4022L96.4469 24.5475H94.8491L94.7039 24.1117L94.5586 18.1564L94.1229 17.2849L93.6871 16.8492L92.3799 16.5587V16.4134H93.2514L93.1061 15.9777L92.6704 15.6872H92.0894V15.3967L91.0726 15.5419L90.9273 15.2514L90.6368 15.1062L91.3631 14.6704L92.0894 14.5251Z"
            fill="#022F1A"
          />
          <path
            d="M72.4804 6.39111L75.0949 6.53636L76.1117 6.82687L76.2569 12.3464V24.838L75.3854 24.9833L76.2569 25.1285V25.2738L75.0949 25.419H71.7542L71.8994 25.1285L72.3352 24.9833L72.1899 24.6928H75.3854L75.2402 22.3688V9.7319L75.3854 6.82687L72.1899 6.68162L72.4804 6.39111Z"
            fill="#022F1A"
          />
          <path
            d="M63.4749 9.73181H65.5084L67.3967 10.1676L69.2849 11.1843L70.7374 12.4916L71.8994 14.6704L72.1899 16.7039V24.6927H71.1732V18.1564L70.8827 16.9944L70.5922 14.0894L69.5754 12.3463L67.9777 11.1843L65.5084 10.4581H64.0559L61.7318 10.8938L60.7151 10.7486L61.2961 10.3128L62.6034 9.87706L63.4749 9.73181Z"
            fill="#022F1A"
          />
          <path
            d="M1.74302 10.4581V10.8939L1.01676 12.3464L0.726257 13.3632L0.581006 15.8324L0.726257 17.7207L1.45251 19.7542L2.32402 21.2067L3.34078 22.2235L4.93855 23.2403L7.26257 24.1118L9.73184 24.5475H13.2179L15.3966 24.4023L15.9777 23.8213H16.5587L16.7039 23.5308L17.5754 23.2403L17.7207 22.8045L18.3017 23.095L18.4469 22.514H18.7374L19.0279 23.3855L17.4302 23.8213L17.5754 23.9665L17.8659 24.1118L17.1397 24.4023L16.4134 24.257V24.6928L15.8324 25.1285L14.3799 25.419L12.7821 25.5643H9.44134L7.11732 25.1285L5.0838 24.1118L3.92179 23.2403L2.46927 21.933L1.45251 20.771L0.435754 19.4637L0 17.4302V14.8157L0.435754 12.4916L1.30726 10.8939L1.74302 10.4581Z"
            fill="#022F1A"
          />
          <path
            d="M156.145 14.6704L157.162 14.8157L158.469 15.8324L159.05 16.9944L159.196 18.0112V24.6928H156.872L156.726 24.5475L156.581 18.1564L156.145 17.2849L155.129 16.8492L153.676 16.7039L154.257 16.5587V16.2682L154.693 16.1229L156 16.5587L157.017 17.4302L157.453 18.5922L157.743 23.2402V24.4023H158.615V19.609L158.469 17.5754L158.034 16.4134L157.162 15.3967L156.145 14.8157V14.6704Z"
            fill="#022F1A"
          />
          <path
            d="M102.693 16.2682H104.001L105.017 16.7039L105.744 17.4302L106.034 18.1565V19.028H100.66L100.805 17.7207L101.677 16.7039L102.693 16.2682Z"
            fill="#E7F9F2"
          />
          <path
            d="M10.1676 9.73181H12.4916L14.2346 10.1676L16.1229 11.1843L17.2849 12.3463L17.7206 13.0726L17.5754 13.3631L16.7039 12.4916V12.2011L15.9776 11.9106L14.6704 11.0391L12.9274 10.6033H10.6033L7.9888 11.0391L6.68154 11.6201L5.22903 12.7821L4.79327 13.3631L4.50277 14.2346L4.35752 15.6871L4.50277 16.7039L5.08378 18.0111L5.81003 18.7374L5.95528 19.1732L6.53629 19.4637L8.71506 20.4804L10.3128 20.7709L12.4916 20.9162V21.0614H10.0223L7.55305 20.6257L5.51953 19.4637L4.50277 18.3016L4.06702 16.8491V14.5251L4.64802 12.9273L5.37428 11.9106L6.82679 10.8938L8.42456 10.1676L10.1676 9.73181Z"
            fill="#022F1A"
          />
          <path
            d="M42.9938 4.79333H43.2843V16.8492L42.8486 17.7207L41.8318 19.028L40.815 19.8995L38.7815 20.771L36.3122 21.2067L34.1335 21.0615L31.9547 20.4805L30.3569 19.4637L29.1949 18.0112L28.9044 16.9945V14.2347L29.1949 13.7989V16.8492L29.6307 17.866L30.3569 18.5922L31.6642 19.609L32.681 20.19L34.1335 20.6257L35.005 20.771H37.329L38.9268 20.3352L40.234 19.7542L41.5413 18.7375L42.1223 18.0112L42.4128 17.1397L42.7033 13.2179L42.9938 4.79333Z"
            fill="#022F1A"
          />
          <path
            d="M56.5034 22.3688L57.2297 22.6593L58.6822 23.5308L60.861 24.257L62.4587 24.5475L66.9615 24.6928L68.8498 25.1286V25.2738L67.6878 25.4191L63.6207 25.5643L60.861 25.2738L59.2632 24.6928L58.3917 24.1118L57.0844 23.095L56.5034 22.3688Z"
            fill="#022F1A"
          />
          <path
            d="M24.9833 16.2682H25.1285L25.419 18.0112L26.8715 20.771L28.1788 22.2235L29.4861 23.095L30.7933 23.676L32.6816 24.257L34.4246 24.5475H37.7654L39.9442 24.1118L41.6872 23.5308H42.1229L41.8324 23.9665L40.5252 24.5475L38.0559 24.838H39.6537L39.3632 25.1285L38.7822 25.2738H35.1509L34.4246 24.9833L31.3743 24.4023L29.6313 23.676L28.6146 23.2403L27.7431 22.514L26.2905 20.6257L26 19.8995L25.419 19.7542L25.5643 19.028L25.1285 19.1732L24.9833 17.866V16.2682Z"
            fill="#022F1A"
          />
          <path
            d="M1.74302 10.4581V10.8939L1.01676 12.3464L0.726257 13.3632L0.581006 15.8324L0.726257 17.7207L1.45251 19.7542L2.32402 21.2067L3.34078 22.2235L4.93855 23.2403L7.26257 24.1118L9.73184 24.5475L12.7821 24.6928V24.838H9.15084L6.68156 24.4023L4.50279 23.3855L2.90503 22.3687L1.30726 20.6257L0.435754 19.4637L0 17.4302V14.8157L0.435754 12.4916L1.30726 10.8939L1.74302 10.4581Z"
            fill="#022F1A"
          />
          <path
            d="M90.6375 15.2513L91.7995 15.3966L92.8162 15.6871L93.252 15.9776V16.7039L91.6542 16.8491L90.7827 17.2849L90.2017 18.4469L90.0565 24.6927L89.9112 24.9832H88.1682V24.6927H87.8777V18.5921H88.0229V24.5474H89.766V19.1731L90.0565 17.5754L90.4922 16.8491H90.7827V16.4134L90.2017 16.7039L89.9112 15.9776L90.6375 15.2513Z"
            fill="#022F1A"
          />
          <path
            d="M39.7995 9.58655L40.09 10.1676L39.509 10.4581V10.8938H37.9113L36.3135 10.7486H34.4252L32.6822 11.0391L30.7939 12.2011L29.6319 13.2178L29.1962 13.7988L29.3414 13.0726L29.9224 12.2011L31.2297 11.0391L32.8275 10.3128L34.1347 9.87705L36.023 9.7318L38.4923 10.0223L39.509 10.1676V9.7318L39.7995 9.58655Z"
            fill="#022F1A"
          />
          <path
            d="M10.0224 5.81006H13.0727L15.3967 6.39106L16.8492 7.11732L17.8659 7.84358L17.5754 7.98883L15.3967 7.11732L12.9274 6.68157H9.58662L7.5531 6.97207L5.81008 7.55308L4.93857 7.98883L4.64807 7.84358L5.37433 7.26257L7.2626 6.39106L9.00561 5.95531L10.0224 5.81006Z"
            fill="#022F1A"
          />
          <path
            d="M84.3917 16.2682H85.6989L87.0062 16.7039L87.5872 17.4302L87.442 17.7207L86.7157 16.9944L86.2799 16.8492H84.6822L83.5202 17.5755L83.3749 18.5922L83.5202 24.6928L82.7939 24.838L81.6319 24.9833L80.9056 24.6928L81.0509 24.257V24.5475H82.3582V24.257H82.6487L82.7939 18.5922L83.3749 16.9944L84.1012 16.4134L84.3917 16.2682Z"
            fill="#022F1A"
          />
          <path
            d="M162.391 12.2012L163.553 12.3464L163.844 13.3632V14.0894L163.553 13.5084V12.6369L162.536 12.7822L162.101 14.3799L161.374 14.9609L161.229 16.5587L161.955 16.8492L162.246 17.285V20.6258L162.101 23.8213L161.955 23.5308H161.665L161.52 22.2235L161.374 16.9945L161.084 16.8492L160.939 16.4135V15.2515L161.374 14.0894L161.665 13.2179H161.955L162.101 12.3464L162.391 12.2012Z"
            fill="#022F1A"
          />
          <path
            d="M34.7145 5.81006H37.4742L39.2172 6.10056L39.7983 6.39106V6.82682H33.1167L30.7927 7.40782L30.0664 7.55308V7.26257L31.6642 6.53632L33.6977 5.95531L34.7145 5.81006Z"
            fill="#022F1A"
          />
          <path
            d="M29.3408 23.5308L30.3576 23.8213L31.8101 24.4023L35.1509 24.9833V25.2738L35.5866 25.1285H38.7822L39.2179 24.9833H37.0391V24.838L40.2347 24.4023L41.2514 24.1118L41.8324 23.9665L41.9777 23.5308L42.2682 24.1118L40.2347 25.1285L37.7654 25.5643H34.1341L31.8101 25.1285L29.7766 24.1118L29.3408 23.5308Z"
            fill="#022F1A"
          />
          <path
            d="M47.2073 0.726196L47.4978 1.16195L47.643 4.93849L47.7883 18.1564L48.0788 18.8826L48.805 19.8994L48.224 19.6089L47.4978 18.7374L47.3525 18.0111L47.2073 7.84351V0.726196Z"
            fill="#022F1A"
          />
          <path
            d="M150.336 16.2682H150.626L150.772 17.2849L150.917 21.352V24.5475H151.934L151.643 24.6928V24.9833L151.934 25.1285L151.788 25.2738H150.917L151.062 24.838L150.481 24.5475H150.045V20.19L150.191 16.5587L150.336 16.2682Z"
            fill="#022F1A"
          />
          <path
            d="M69.2855 11.9106L69.8665 12.2011L70.738 13.3632L71.319 14.5252L71.6095 15.6872L71.7548 17.5754L71.9 20.4805L71.7548 23.2403L71.4643 23.095L71.319 24.1118H71.1738V18.1565L70.8833 16.9944L70.5928 14.0894L69.576 12.3464L69.2855 11.9106Z"
            fill="#022F1A"
          />
          <path
            d="M63.1838 5.81006H65.7983L67.1055 6.10056L67.8318 6.53632L68.4128 6.82682L66.0888 6.68157H62.748L60.7145 6.97207L58.681 7.69833L58.2452 7.84358L57.9547 7.55308L59.9882 6.53632L62.0218 5.95531L63.1838 5.81006Z"
            fill="#022F1A"
          />
          <path
            d="M156.145 14.6704L157.161 14.8157L158.469 15.8324L159.05 16.9944L159.195 18.0112V24.6928H157.597V24.5475L158.614 24.4023V19.609L158.469 17.5754L158.033 16.4134L157.161 15.3967L156.145 14.8157V14.6704Z"
            fill="#022F1A"
          />
          <path
            d="M44.0105 0H46.625L47.0608 0.145251L47.2061 0.581006V7.26257H47.0608V0.726257L43.8653 0.871508L43.2843 1.01676V3.63128L43.139 4.7933L42.8485 4.21229V0.581006L43.139 0.290503L44.0105 0Z"
            fill="#022F1A"
          />
          <path
            d="M63.4749 9.73181H65.5084L67.3967 10.1676L68.9944 11.0391L69.2849 11.9106L67.5419 11.0391L65.5084 10.4581H64.0559L61.7318 10.8938L60.7151 10.7486L61.2961 10.3128L62.6034 9.87706L63.4749 9.73181Z"
            fill="#022F1A"
          />
          <path
            d="M47.0609 7.26257L47.3514 7.84358L47.4967 18.0112L47.7872 18.8827L48.5134 19.7542L49.0944 20.0447L49.3849 20.6257L48.5134 20.3352L47.3514 19.1732L47.0609 18.3017L46.9156 13.3631V7.40782L47.0609 7.26257Z"
            fill="#022F1A"
          />
          <path
            d="M83.6654 14.5251H87.1515V14.6704H84.8274V15.1062L87.442 15.3967V15.5419H81.0509V21.2067H80.9056V14.8156L83.6654 14.5251Z"
            fill="#022F1A"
          />
          <path
            d="M29.0497 13.7988L29.1949 14.3798V16.8491L29.6307 17.8659L30.3569 18.5921L31.6642 19.6089L32.681 20.1899L34.1335 20.6256L35.005 20.7709H37.329L38.9268 20.3351L40.234 19.7541L40.815 19.3184L41.1055 19.4636L40.5245 20.0446L38.7815 20.7709L36.3122 21.2066L34.1335 21.0614L31.9547 20.4804L30.3569 19.4636L29.1949 18.0111L28.9044 16.9944V14.2346L29.0497 13.7988Z"
            fill="#022F1A"
          />
          <path
            d="M163.844 14.2346L163.989 14.5251H165.441L165.877 14.8156L166.022 15.3966V16.4134L165.732 16.8491H163.989L163.844 17.2849V14.2346Z"
            fill="#022F1A"
          />
          <path
            d="M99.2061 22.5139L100.368 23.6759L102.402 24.4022H104.726L105.888 24.2569L106.323 24.4022L105.307 25.1284L104.726 25.2737H102.547L101.094 24.8379L99.9323 23.8212L99.2061 22.8044V22.5139Z"
            fill="#022F1A"
          />
          <path
            d="M116.347 22.0782L117.799 23.5308L119.542 24.4023L121.14 24.5475L122.738 24.257L123.9 23.5308L124.19 23.676L123.609 24.5475L123.028 24.6928L122.593 25.1285L121.866 25.2738H120.269L118.961 24.9833L117.654 24.257L116.347 22.3687V22.0782Z"
            fill="#022F1A"
          />
          <path
            d="M10.1682 9.73181H12.4923L14.2353 10.1676L16.1236 11.1843L17.2856 12.3463L17.7213 13.0726L17.5761 13.3631L16.7046 12.4916V12.2011L15.9783 11.9106L14.671 11.0391V10.7486L13.3638 10.4581L12.4923 10.1676H9.87775L8.13473 10.6033L7.69897 10.4581L9.29674 9.87706L10.1682 9.73181Z"
            fill="#022F1A"
          />
          <path
            d="M9.87647 10.0223H12.491L14.3793 10.6033L14.6698 10.8938L13.653 10.7486L12.9268 10.6033H10.6027L7.98821 11.0391L6.68094 11.6201L5.95469 12.2011L5.66418 12.0559L6.53569 11.0391L7.4072 10.6033L9.87647 10.0223Z"
            fill="#022F1A"
          />
          <path
            d="M87.8777 18.5922H88.0229V24.5475H89.766V19.1732L90.0565 19.0279V24.6927L89.9112 24.9832H88.1682V24.6927H87.8777V18.5922Z"
            fill="#022F1A"
          />
          <path
            d="M145.251 23.676H145.687V23.9665L147.43 24.1118V24.257H145.687L144.525 24.1118L144.234 25.1285H141.038L139.876 24.5475L140.022 24.257L141.474 24.5475L144.089 24.6928L144.234 24.1118L144.525 23.8213L145.251 23.676Z"
            fill="#022F1A"
          />
          <path
            d="M21.2067 11.0391L21.6424 11.4748L22.2234 13.0726L22.6592 15.1061L22.5139 17.1396L22.2234 18.5921L21.9329 18.4469L22.0782 14.9609L21.4972 12.6368L21.2067 11.6201V11.0391Z"
            fill="#022F1A"
          />
          <path
            d="M156.871 19.1732H157.161L157.307 21.2067V24.5475H156.726V19.4637L156.871 19.1732Z"
            fill="#022F1A"
          />
          <path
            d="M146.268 14.5251L147.575 14.6704V15.3967H145.687V16.8492L145.251 16.2682V15.9777H144.816L144.67 15.6872H141.184L141.765 15.3967L143.508 15.2514H144.525V14.8156L145.106 14.9609H146.268V14.5251Z"
            fill="#022F1A"
          />
          <path
            d="M52.2899 21.933H52.4351V24.838L52.1446 25.5643L51.7088 26L50.6921 25.8548L48.0776 25.1285L47.6418 24.6928L46.7703 24.257L46.3345 23.8213L45.7535 23.3855L45.8988 23.095L47.3513 24.1118L47.9323 24.5475L49.8206 25.1285L50.6921 25.419H51.9994L52.1446 24.6928L52.2899 21.933Z"
            fill="#022F1A"
          />
          <path
            d="M24.9833 16.2682H25.1285L25.419 18.0112L26.8715 20.771L28.1788 22.2235L28.9051 23.095L29.3408 23.5308L28.6146 23.2403L27.7431 22.514L26.2905 20.6257L26 19.8995L25.419 19.7542L25.5643 19.028L25.1285 19.1732L24.9833 17.866V16.2682Z"
            fill="#022F1A"
          />
          <path
            d="M13.2179 5.95532L14.5251 6.10057L16.7039 6.97208L17.8659 7.84359L17.5754 7.98884L15.3966 7.11733L14.0894 6.82683V6.53633L9.87708 6.39108V6.24583L13.2179 6.10057V5.95532Z"
            fill="#022F1A"
          />
          <path
            d="M161.374 16.8491L161.809 16.9944L161.955 17.4301V23.5307H161.664L161.519 22.2234L161.374 16.8491Z"
            fill="#022F1A"
          />
          <path
            d="M9.44189 24.9833H15.3972L15.252 25.2738L14.3804 25.419H10.4587L9.44189 25.2738V24.9833Z"
            fill="#022F1A"
          />
          <path
            d="M101.095 20.3352H104.29L107.486 20.4805V20.6257L105.743 20.771H100.659L100.514 21.352V20.6257H101.095V20.3352Z"
            fill="#022F1A"
          />
          <path
            d="M68.414 21.6425L68.7045 22.2235L69.4308 22.514V24.6927H68.1235L68.5593 24.5475L68.414 21.6425Z"
            fill="#022F1A"
          />
          <path
            d="M44.0105 0H46.625L47.0608 0.145251V0.581006H43.4295L43.139 0.726257L42.9938 4.21229H42.8485V0.581006L43.139 0.290503L44.0105 0Z"
            fill="#022F1A"
          />
          <path
            d="M71.6084 6.68152L72.1894 6.82677L71.7536 8.56979L71.4631 7.98878L71.1726 7.84353V8.42454L70.4464 8.13403L68.4128 6.97202L68.9938 6.82677L70.1559 7.40778L71.3179 7.55303L71.6084 6.68152Z"
            fill="#022F1A"
          />
          <path
            d="M3.34021 22.3688L4.06647 22.6593L5.22848 23.3855L7.262 24.1118L9.73127 24.5475L12.7816 24.6928V24.838H9.15027L6.68099 24.4023L4.50222 23.3855L3.34021 22.6593V22.3688Z"
            fill="#022F1A"
          />
          <path
            d="M150.191 19.028H150.336L150.481 20.0447V24.5475H150.045V20.19L150.191 19.028Z"
            fill="#022F1A"
          />
          <path
            d="M38.7828 6.10059L39.509 6.24584L39.7995 6.39109V6.82684H38.4923L37.4755 6.68159L33.8442 6.53634V6.39109L35.1515 6.24584L38.7828 6.10059Z"
            fill="#022F1A"
          />
          <path
            d="M136.101 19.4637H136.246V24.6928L136.101 24.9833H133.922V22.2235H134.067V24.5475H136.101V19.4637Z"
            fill="#022F1A"
          />
          <path
            d="M41.977 23.5308L42.2675 24.1118L40.234 25.1285L39.072 25.2738L39.2172 24.9833H37.0385V24.838L40.234 24.4023L41.2507 24.1118L41.8317 23.9665L41.977 23.5308Z"
            fill="#022F1A"
          />
          <path
            d="M18.4469 22.5139H18.7374L19.0279 23.3854L17.4301 23.8212L17.5754 23.9664L17.8659 24.1117L17.1396 24.4022L16.2681 24.2569V24.5474H15.3966L15.9776 23.8212H16.5586L16.7039 23.5307L17.5754 23.2402L17.7206 22.8044L18.3016 23.0949L18.4469 22.5139Z"
            fill="#022F1A"
          />
          <path
            d="M72.1899 24.6927H73.9329V24.838L76.2569 25.1285V25.2738L75.0949 25.419H71.7542L71.8994 25.1285L72.3352 24.9833L72.1899 24.6927Z"
            fill="#022F1A"
          />
          <path
            d="M65.0733 24.6927H66.9615L68.8498 25.1285V25.2738L67.6878 25.419H64.0565V25.2738L66.5258 25.1285L64.0565 24.9833V24.838L65.0733 24.6927Z"
            fill="#022F1A"
          />
          <path
            d="M157.162 19.4637H157.452L157.743 23.2403V24.4023L157.598 24.6928H156.871L157.307 24.5475L157.162 21.2068V19.4637Z"
            fill="#022F1A"
          />
          <path
            d="M43.8659 21.933H44.8827L45.609 22.514L45.7542 23.095L44.1564 22.3687L43.5754 22.9497H43.2849L43.1397 23.3855H42.5587V23.676H42.1229L42.4134 23.095L43.8659 21.933Z"
            fill="#022F1A"
          />
          <path
            d="M84.391 16.2682H85.6983L87.0056 16.7039L87.5866 17.4302L87.4413 17.7207L86.715 16.9944L86.2793 16.8492H84.6815L84.1005 17.1397H83.3743L84.1005 16.4134L84.391 16.2682Z"
            fill="#022F1A"
          />
          <path
            d="M96.5921 19.028H96.7374L96.8826 24.838L94.8491 24.9833L94.7039 24.838V24.1118L94.8491 24.5475L96.4469 24.4023L96.5921 19.028Z"
            fill="#022F1A"
          />
          <path
            d="M161.374 16.5587L162.1 16.9945L162.245 17.285V20.6258L162.1 23.8213H161.955L161.809 17.4302L161.664 16.9945L161.374 16.8492V16.5587Z"
            fill="#022F1A"
          />
          <path
            d="M6.10054 11.3296L6.39104 11.4748L4.93852 13.0726L4.50277 14.2346L4.35752 15.6871L4.50277 16.7039L4.64802 17.5754L4.21227 17.4301L4.06702 16.8491V14.5251L4.64802 12.9274L5.37428 11.9106L6.10054 11.3296Z"
            fill="#022F1A"
          />
          <path
            d="M87.8777 18.5922H88.0229V24.5475H89.766L89.9112 24.9832H88.1682V24.6927H87.8777V18.5922Z"
            fill="#022F1A"
          />
          <path
            d="M16.2681 24.257L16.4134 24.6927L15.8324 25.1285L12.7821 24.9832L13.2179 24.5475H16.2681V24.257Z"
            fill="#022F1A"
          />
          <path
            d="M10.0223 5.81006H13.0726L13.7989 6.10056V6.24581H9.87708L10.0223 5.81006Z"
            fill="#022F1A"
          />
          <path
            d="M61.2955 6.10059L61.8765 6.24584L61.005 6.53634V6.82684L58.681 7.69835L58.2452 7.8436L57.9547 7.5531L59.9882 6.53634L61.2955 6.10059Z"
            fill="#022F1A"
          />
          <path
            d="M139.876 24.257L140.893 24.4022L143.072 24.838V25.1285H141.038L139.876 24.5475V24.257Z"
            fill="#022F1A"
          />
          <path
            d="M144.525 14.8157L145.106 14.9609L145.397 15.3967V16.2682L145.251 15.9777H144.816L144.67 15.6872H141.184L141.765 15.3967L143.508 15.2514H144.525V14.8157Z"
            fill="#022F1A"
          />
          <path
            d="M162.101 23.8213L162.972 23.9665L163.989 24.5475L165.732 24.838L165.877 25.1286H163.989L162.391 24.257V23.9665L162.101 23.8213Z"
            fill="#022F1A"
          />
          <path
            d="M24.9833 16.2682H25.1285L25.419 18.0112L26 19.1732L25.8548 19.8995L25.419 19.7542L25.5643 19.028L25.1285 19.1732L24.9833 17.866V16.2682Z"
            fill="#022F1A"
          />
          <path
            d="M0.581055 19.4637H1.01681L1.74307 20.3353L2.75983 21.6425L3.34083 22.2235L3.19558 22.514L2.46932 21.933L1.45256 20.771L0.581055 19.7542V19.4637Z"
            fill="#022F1A"
          />
          <path
            d="M134.212 15.1062L134.793 15.3967L135.665 16.4135L135.955 17.1397L135.81 17.7207L134.939 16.4135L134.212 15.3967V15.1062Z"
            fill="#022F1A"
          />
          <path
            d="M99.2061 22.5139L100.368 23.6759L102.402 24.4022V24.5474H101.094L100.223 24.1117L99.3513 23.0949L99.2061 22.5139Z"
            fill="#022F1A"
          />
          <path
            d="M22.3681 14.3799H22.5134L22.6586 15.5419L22.3681 17.8659L22.2229 18.5922L21.9324 18.4469L22.0776 16.2682L22.2229 16.1229L22.3681 14.3799Z"
            fill="#022F1A"
          />
          <path
            d="M116.347 22.0782L117.799 23.5308L119.542 24.4023V24.5475H118.671L117.364 23.8213L116.347 22.3687V22.0782Z"
            fill="#022F1A"
          />
          <path
            d="M1.74364 10.4581V10.8939L1.01738 12.3464L0.872131 12.7822H0.581628L0.291125 14.8157H0.145874V13.6537L0.581628 12.2011L1.45314 10.7486L1.74364 10.4581Z"
            fill="#022F1A"
          />
          <path
            d="M7.11792 19.8994L9.29669 20.6257L12.4922 20.9162V21.0614H10.0229L8.13468 20.7709L7.11792 20.0447V19.8994Z"
            fill="#022F1A"
          />
          <path
            d="M150.771 21.3519H150.916V24.5475H151.933L151.643 24.6927V24.9832L151.933 25.1285L151.788 25.2737H150.916L151.061 24.838L150.771 24.6927V21.3519Z"
            fill="#022F1A"
          />
          <path
            d="M129.565 15.1062L130.001 15.2515L129.855 16.123L129.42 16.2682L129.274 17.1397H129.129V15.2515L129.565 15.1062Z"
            fill="#022F1A"
          />
          <path
            d="M110.101 14.6704H110.682V14.9609L110.972 14.8157H112.134L112.57 15.2514L112.425 15.5419L112.134 15.3967L110.101 15.2514V14.6704Z"
            fill="#022F1A"
          />
          <path
            d="M6.82684 6.53625L7.2626 6.68151L6.97209 7.11726L5.22908 7.84352L4.64807 7.98877L5.37433 7.26251L6.82684 6.53625Z"
            fill="#022F1A"
          />
          <path
            d="M43.5754 22.2235L43.7207 22.8045L43.2849 22.9498L43.1397 23.3855H42.5587V23.676H42.1229L42.4134 23.095L43.5754 22.2235Z"
            fill="#022F1A"
          />
          <path
            d="M40.8162 19.3185L41.1067 19.4637L40.5257 20.0447L38.7827 20.771L37.3302 20.9162V20.771L38.928 20.3352L40.2352 19.7542L40.8162 19.3185Z"
            fill="#022F1A"
          />
          <path
            d="M105.743 15.1062L106.469 15.3967L107.486 16.5587L107.777 17.285L107.631 17.866L106.905 16.4135L105.743 15.2515V15.1062Z"
            fill="#022F1A"
          />
          <path
            d="M68.9944 22.3688L69.4301 22.514V24.4023L68.9944 24.1118L68.8491 22.514L68.9944 22.3688Z"
            fill="#022F1A"
          />
          <path
            d="M162.392 12.2012L163.554 12.3464L163.844 13.3632V14.0894L163.554 13.5084V12.6369L162.537 12.7822L162.246 13.3632V12.3464L162.392 12.2012Z"
            fill="#022F1A"
          />
          <path
            d="M82.6487 23.095H82.7939V24.6927L82.3582 24.9832H81.6319L80.9056 24.6927L81.0509 24.257V24.5475H82.3582V24.257H82.6487V23.095Z"
            fill="#022F1A"
          />
          <path
            d="M31.2297 11.0391L31.5202 11.1843L31.0844 11.6201L30.0677 12.4916L29.3414 13.7988L29.1962 13.3631L29.9224 12.2011L31.2297 11.0391Z"
            fill="#022F1A"
          />
          <path
            d="M15.6871 6.82678L16.7039 6.97203L17.8659 7.84354L17.5754 7.98879L15.3966 7.11729L15.6871 6.82678Z"
            fill="#022F1A"
          />
          <path
            d="M49.095 20.1899L49.8212 20.4804L51.7095 21.0614L51.2737 21.352H50.5475L49.3855 20.7709L49.095 20.1899Z"
            fill="#022F1A"
          />
          <path
            d="M156.145 14.6704L157.161 14.8157L158.178 15.5419L158.033 15.9777L157.452 15.6872L156.145 14.8157V14.6704Z"
            fill="#022F1A"
          />
          <path
            d="M131.161 16.4135H132.323L133.485 16.9945L133.776 17.7207L132.469 16.8492L131.161 16.5587V16.4135Z"
            fill="#022F1A"
          />
          <path
            d="M162.101 12.3463H162.246V13.9441L161.229 14.5251L161.665 13.2178H161.955L162.101 12.3463Z"
            fill="#022F1A"
          />
          <path
            d="M19.1732 9.00562L19.7542 9.29612L21.0615 10.6034L21.2067 11.0391L20.9162 11.3296L20.0447 10.0224L19.1732 9.00562Z"
            fill="#022F1A"
          />
          <path
            d="M154.548 16.1229L155.855 16.4134L155.564 16.7039H153.676L154.257 16.5587V16.2682L154.548 16.1229Z"
            fill="#022F1A"
          />
          <path
            d="M21.9324 18.5922L22.0776 19.1732L21.6419 20.1899L21.3514 20.3352L21.2061 20.6257L20.7704 20.1899L20.9156 19.8994L21.3514 19.7542L21.2061 19.4637H21.7871L21.9324 18.5922Z"
            fill="#022F1A"
          />
          <path
            d="M16.5593 19.1732L16.8498 19.3185L15.6878 20.19L14.671 20.3352L14.9615 20.0447L16.5593 19.1732Z"
            fill="#022F1A"
          />
          <path
            d="M142.201 24.6927H144.234V25.1285H143.072V24.838H142.201V24.6927Z"
            fill="#022F1A"
          />
          <path
            d="M81.0503 14.9609L82.6481 15.1062L82.2123 15.5419H81.0503V14.9609Z"
            fill="#022F1A"
          />
          <path
            d="M71.6089 6.68152L72.1899 6.82677L71.7541 8.56979L71.4636 7.98878L71.6089 6.68152Z"
            fill="#022F1A"
          />
          <path
            d="M94.7039 24.1117L94.8491 24.5474H96.1564L95.8659 24.9832H94.8491L94.7039 24.838V24.1117Z"
            fill="#022F1A"
          />
          <path
            d="M109.956 24.1117L110.101 24.5474L111.554 24.6927L111.844 24.4022L111.699 24.9832H110.246L109.956 24.838V24.1117Z"
            fill="#022F1A"
          />
        </svg>
      </div>
      <section className="bg-light">
        <div className="flow">
          <div className="text-center">
            <h1 className="fw-600 text-black-900">Reset Password</h1>
            <p className="text-black-900 fs-400">Enter a new password</p>
            {error.isError && (
              <pre className="text-error fs-200">{error.message}</pre>
            )}
          </div>

          <form action="" className="flow" onSubmit={handleSubmit}>
            <div className="flex input-container ff-serif">
              <label className="sr-only" htmlFor="passwordInput">
                Password
              </label>
              <input
                type={isHideP ? "password" : "text"}
                name="passwordInput"
                id="passwordInput"
                placeholder="Enter password"
                onChange={(e) => setPwd(e.target.value)}
                value={pwd}
              />
              <span className="pointer" onClick={() => setIsHideP(!isHideP)}>
                <Hide isHide={isHideP} />
              </span>
            </div>

            <div>
              <div className="flex input-container ff-serif">
                <label className="sr-only" htmlFor="confirmPasswordInput">
                  Confirm Password
                </label>
                <input
                  type={isHideCP ? "password" : "text"}
                  name="confirmPasswordInput"
                  id="confirmPasswordInput"
                  placeholder="Confirm password"
                  onChange={(e) => setConfirmPwd(e.target.value)}
                  value={confirmPwd}
                />
                <span
                  className="pointer"
                  onClick={() => setIsHideCP(!isHideCP)}
                >
                  <Hide isHide={isHideCP} />
                </span>
              </div>
              <pre className="text-error fs-200">{matched}</pre>
            </div>

            <div>
              <button
                disabled={Boolean(matched) || isEmpty}
                type="submit"
                className="btn form-btn fw-600 pointer"
              >
                {isLoading ? "Pleasw wait..." : "Confirm"}
              </button>
            </div>
          </form>

          {/* <div className='login-text'>
						<Link to='/'>Back To Login</Link>
					</div> */}
        </div>
      </section>
    </Main>
  );
};

interface HideProps {
  isHide: boolean;
}

const Hide: React.FC<HideProps> = ({ isHide }) => {
  if (isHide) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="feather feather-eye-off"
      >
        <path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24"></path>
        <line x1="1" y1="1" x2="23" y2="23"></line>
      </svg>
    );
  }
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 12C1 12 5 4 12 4C19 4 23 12 23 12C23 12 19 20 12 20C5 20 1 12 1 12Z"
        stroke="#0D0C0E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 14.9999C13.6568 14.9999 15 13.6567 15 11.9999C15 10.343 13.6568 8.99988 12 8.99988C10.3431 8.99988 8.99999 10.343 8.99999 11.9999C8.99999 13.6567 10.3431 
                      14.9999 12 14.9999Z"
        stroke="#0D0C0E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const Main = styled.main`
  height: 100vh;
  place-items: center;

  .logo {
    margin: 2em auto 0;
    align-self: flex-start;
  }

  section {
    padding: 5em 8em;
    width: min(50em, 100%);
    border-radius: 0.6em;
    align-self: flex-start;
  }
  section > div {
    text-align: center;
    --flow-space: 2.5rem;
  }

  .input-container {
    padding: 0.75em 1.5em;
    border-radius: 8px;
    border: 1px solid hsla(var(--clr-border));
    outline-offset: 2px;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }
  input {
    outline: transparent solid 2px;
    border: none;
    width: 100%;
  }
  .input-container + input:invalid {
    border: 2px solid red;
    /* background: red; */
  }
  input::placeholder .input-container {
    color: hsla(var(--clr-black-shade-300));
    opacity: 1;
  }
  span {
    /* position: absolute; */
    top: 10%;
    right: 5%;
  }

  button {
    transition: background-color 0.2s;
    background-color: #022f1a;
  }

  button:hover {
    opacity: 0.9;
  }

  button:disabled {
    background-color: hsla(var(--clr-black-tint-900));
  }

  .form-btn {
    width: 80%;
  }

  .login-text {
    margin-top: 30px;
    text-align: center;
    cursor: pointer;

    a {
      color: #060607;
      font-weight: 600;
      :hover {
        text-decoration: underline;
      }
    }
  }
`;

export default ResetPassword;
