import { Dispatch, useEffect, useState } from "react";
import { useAppContext } from "context/AppContext";
import ContentLayout from "../../layouts/ContentLayout";
import styled from "styled-components";
import {
  Button,
  TableTab,
  EmptyList,
  TableSkeleton,
  Pagination,
  Table,
} from "components";
import { ActionModal } from "./ActionModal";
import CreateSurveyForm from "./CreateSurvey";
import { useSearchParams, useNavigate } from "react-router-dom";
import SurveyService from "../../services/SurveryService";

const navItems = [{ link: "Survey table", linkTo: "" }];

const surveyHeaders = [
  { key: 1, item: "Name of Survey" },
  // { key: 2, item: "Questions" },
  { key: 2, item: "Duration" },
  { key: 3, item: "Survey Type" },
  { key: 4, item: "Date Created" },
  { key: 5, item: "Status" },
  { key: 6, item: "Action" },
];

export type ItemType = {
  _id: string;
  title: string;
  mandatory: boolean;
  isActive: boolean;
  surveyType: string;
  duration: {
    startDate: string;
    endDate: string;
  };
  question: {
    _id: string;
    questionId: string;
    title: string;
    optionType: string;
    options: [string];
    isRequired: boolean;
  }[];
  feature?: {
    salutation: string;
    information: string;
    positive_flag: string;
    positive_flag_info: string;
    negative_flag: string;
    negative_flag_info: string;
  };
  createdAt: string;
  updatedAt: string;
  _v: string;
};

const CustomerSupportManagement = () => {
  const { state, dispatch } = useAppContext();
  const [tableData, setTableData] = useState<ItemType[]>([]);
  const isLoading = state?.isLoading;
  const [willFilter, setWillFilter] = useState("");
  const [bySpecific, setBySpecific] = useState("");
  const [byCustomStart, setByCustomStart] = useState("");
  const [byCustomEnd, setByCustomEnd] = useState("");
  const [pageParams, setPageParams] = useSearchParams();
  const [page, setPage] = useState(() => {
    let p = Number(pageParams.get("page"));
    return p > 0 ? p : 1;
  });
  const [pages, setPages] = useState<number>(0);
  const [limit, setLimit] = useState(10);
  const [isModal, setIsModal] = useState(false);
  const [csvData, setCsvData] = useState();

  useEffect(() => {
    async function fetchData() {
      dispatch({ type: "data-loading" });

      try {
        const {
          data: {
            data: { survey, meta },
          },
        } = await SurveyService.getAllSurvey(
          limit,
          page,
          bySpecific,
          byCustomStart,
          byCustomEnd
        );
        console.log("////kjssksks", survey);
        const csvDatas = survey.map((item: ItemType) => {
          let start_date = new Date(item.duration.startDate);
          let end_date = new Date(item.duration.endDate);
          let startDate = `${start_date.getDate()}/${
            start_date.getMonth() + 1
          }/${start_date.getFullYear()}`;
          let endDate = `${end_date.getDate()}/${
            end_date.getMonth() + 1
          }/${end_date.getFullYear()}`;
          return {
            id: item._id,
            name: item.title,
            duration: `${startDate} - ${endDate}`,
            survey_type: item.surveyType,
            status: item.isActive ? "Active" : "Inactive",
            createdAt: item.duration.startDate,
          };
        });
        setCsvData(csvDatas);

        setTableData(survey);
        setPages(meta.pageCount);
        setPageParams({ page: page.toString() });
      } catch (error) {
        console.log(error);
      } finally {
        dispatch({ type: "data-loaded" });
      }
    }
    fetchData();
  }, [page, limit, willFilter, dispatch]);

  return (
    <ContentLayout
      titleText="Customer Support"
      subText="Manage Customer"
      component={<AddSurveyButton {...{ setIsModal }} />}
    >
      <ContentWrapper>
        <CreateSurveyForm setIsModal={setIsModal} isModal={isModal} />
        <TableTab
          // canFilter={true}
          data={csvData}
          file="customer-support.csv"
          navItems={navItems}
          setBySpecific={setBySpecific}
          setByCustomStart={setByCustomStart}
          setByCustomEnd={setByCustomEnd}
          setWillFilter={setWillFilter}
        />
        {isLoading ? (
          <TableSkeleton />
        ) : tableData.length === 0 ? (
          <EmptyList />
        ) : (
          <ProductsTable>
            <div className="table-container">
              <Table
                headers={surveyHeaders}
                items={tableData}
                renderItem={Survey}
              />
            </div>
            <Pagination
              setLimit={setLimit}
              limit={limit}
              page={page}
              pages={pages}
              setPage={setPage}
            />
          </ProductsTable>
        )}
      </ContentWrapper>
    </ContentLayout>
  );
};

const AddSurveyButton = ({
  setIsModal,
}: {
  setIsModal: Dispatch<React.SetStateAction<boolean>>;
}) => (
  <Button variant="ghost" onClick={() => setIsModal(true)}>
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15"
        height="14"
        viewBox="0 0 15 14"
        fill="none"
      >
        <path
          d="M7.5 2.91699V11.0837"
          stroke="#022f1a"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.41675 7H11.5834"
          stroke="#0F172A"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
    <span className="fs-100 fw-600">Create Survey</span>
  </Button>
);

const Survey = (item: ItemType) => {
  const navigate = useNavigate();
  const [isViewDetails, setIsViewDetails] = useState(false);
  const [isMoreAction, setIsMoreAction] = useState(false);
  let start_date = new Date(item.duration.startDate);
  let end_date = new Date(item.duration.endDate);
  let startDate = `${start_date.getDate()}/${
    start_date.getMonth() + 1
  }/${start_date.getFullYear()}`;
  let endDate = `${end_date.getDate()}/${
    end_date.getMonth() + 1
  }/${end_date.getFullYear()}`;

  const handleNavigate = (param: string) => {
    navigate(`survey/${param}`);
  };

  return (
    <tr key={item._id}>
      <td onClick={() => handleNavigate(item._id)}>{item.title}</td>
      <td>{`${startDate} - ${endDate}`}</td>
      <td>{item.surveyType}</td>
      <td>{startDate}</td>
      <td>{item.isActive ? "Active" : "Inactive"}</td>
      <td onClick={() => setIsMoreAction(true)} className="pointer three-dots">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="3"
          height="24"
          viewBox="0 0 3 24"
          fill="none"
        >
          <path
            d="M1.50016 15.9965L1.50016 16.0054"
            stroke="#333333"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M1.50016 11.9955L1.50016 12.0045"
            stroke="#333333"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M1.50016 7.99451L1.50016 8.00349"
            stroke="#333333"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </td>
      <ActionModal
        item={item}
        display={isMoreAction}
        setIsModal={setIsMoreAction}
      />
      {/* <ViewDetailsModal
        item={item}
        display={isViewDetails}
        setIsModal={setIsViewDetails}
      /> */}
    </tr>
  );
};

const ContentWrapper = styled.div`
  height: 100%;
  overflow-y: scroll;
  position: relative;
  padding-top: 5em;

  .chart-container {
    padding: 20px 0;
  }
`;
const ButtonContainer = styled.div`
  margin-bottom: 20px;
  padding-left: 30px;

  .button {
    background: #db8130;
    color: #160d05;
    padding: 8px 35px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 600;
  }
`;

const ProductsTable = styled.div`
  tbody tr {
    background-color: hsla(var(--clr-light));
    border-top: 8px solid #ececec;
    position: relative;
  }
  tbody tr td {
    text-transform: capitalize;
  }

  .prod {
    display: flex;
    align-items: center;

    span:last-child {
      margin-left: 6px;
    }
  }

  .product-img {
    width: 30px;
    height: 30px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .three-dots {
    display: inline-grid;
    place-items: center;
    width: 100%;
  }
`;

export default CustomerSupportManagement;
