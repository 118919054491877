import { Navigate } from "react-router-dom";
import { useAppContext } from "../../context/AppContext";

const PrivateRoute = ({ children }: any) => {
  const { state } = useAppContext();
  const userRoles: string[] = state?.user?.roles || [];

  if (userRoles.includes("ADMIN") || userRoles.includes("SUPER_ADMIN")) {
    return children;
  } else {
    return <Navigate to="/" replace />;
  }
};

export default PrivateRoute;
