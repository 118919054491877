import { Table } from "components";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { formatMoney } from "utils/formatMoney";
import { OrderByOutletType } from "./types";

interface DetailsProps {}

interface LocationState {
  item: OrderByOutletType;
}
type ItemType = {
  _id: string;
  amount: number;
  image: string;
  productCode: string;
  productName: string;
  quantity: number;
};

const headers = [
  { key: 1, item: "Brand" },
  { key: 2, item: "Item No" },
  { key: 3, item: "Quantity" },
  { key: 4, item: "Price" },
  // { key: 5, item: "Date" },
];

const Details: React.FC<DetailsProps> = (props) => {
  const location = useLocation();
  const state = location.state as LocationState;

  console.log("##: ", state.item);
  const renderItem = (item: ItemType) => {
    return (
      <tr>
        <td>{item.productName}</td>
        <td>{item.productCode}</td>
        <td>{item.quantity}</td>
        <td>{formatMoney(item.amount)}</td>
      </tr>
    );
  };
  return (
    <Table
      headers={headers}
      items={state.item.products}
      renderItem={renderItem}
    />
  );
};

export default Details;
