
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import Sidebar from '../components/Sidebar';

interface LayoutProps {
    
}

const DashboardLayout = (props: LayoutProps) => {
    
  return (
    <DashboardWrap>
        <div className="inner-wrap">
            <aside className="side-bar">
                <Sidebar />
            </aside>

            <main className="dashboard-content">
                <Outlet />
            </main>
        </div>
    </DashboardWrap>
  );
};

const DashboardWrap = styled.div`

    .side-bar{
        position: fixed;
        width: 280px;
        height: 100vh;
    }

    .dashboard-content{
        width: calc(100% - 280px);
        margin-left: 280px;
        background-color: #ececec;
        
    }

`

export default DashboardLayout;
