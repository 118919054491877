import styled from "styled-components";
import ReactDOM from "react-dom";
import { Button } from "components";
import React, { Dispatch, useRef, useState, useEffect } from "react";
import ProductService from "services/ProductService";
import { ItemType } from ".";
import { useAppContext } from "context/AppContext";

interface ModalProps {
  item: ItemType;
  display: boolean;
  setIsModal: Dispatch<React.SetStateAction<boolean>>;
}

export const ViewDetailsModal: React.FC<ModalProps> = ({
  item,
  display,
  setIsModal,
}) => {
  const { state, dispatch } = useAppContext();
  const [productName, setProductName] = useState(item.product_name);
  const [productCode, setProductCode] = useState(item.product_code);
  const [brandName, setBrandName] = useState(item.brand_name);
  const [image, setImage] = useState([]);
  let isDisabled =
    item.brand_name !== brandName || item.product_name !== productName;

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    // var formData = new FormData(e.target as HTMLFormElement);
    // formData.append("file", image[0]);

    const payload = {
      product_name: productName,
      brand_name: brandName,
    };
    try {
      dispatch({ type: "action-loading" });
      const {
        data: { data },
      } = await ProductService.updateProduct(productCode, payload);

      item.product_name = data.product_name;
      item.brand_name = data.brand_name;
    } catch (error) {
      console.log("%%: ", error);
    } finally {
      dispatch({ type: "action-loaded" });
    }
  }
  function handleChange(e: React.ChangeEvent<HTMLSelectElement>) {
    console.log("%%: ", e.target.value);
    setBrandName(e.target.value);
  }

  if (!display) return null;
  return ReactDOM.createPortal(
    <>
      <StyledOverlay />
      <StyledModal className="grid">
        <Close className="pointer close" onClick={() => setIsModal(false)} />
        <h1 className="fs-600 fw-400 text-blue-dark-050">Product Details</h1>
        <ImageBox
          {...{ setImage, image }}
          src={item.image}
          itemCode={item.product_code}
          item={item}
        />
        <form onSubmit={handleSubmit} className="grid flow">
          <div className="flex">
            <div className="flex fs-500">
              <label htmlFor="product_code">Item No:</label>
              <input
                placeholder="Edit sku code"
                name="product_code"
                onChange={(e) => setProductCode(e.target.value)}
                value={productCode}
                readOnly
              />
            </div>
            <div className="flex fs-500">
              <label htmlFor="brand_name">Brands:</label>
              <input
                placeholder="Edit brand name"
                name="brand_name"
                onChange={(e) => setBrandName(e.target.value)}
                value={brandName}
              />
            </div>
          </div>

          <div className="flex fs-500">
            <div className="flex">
              <label htmlFor="product_name">Product Name:</label>
              <input
                placeholder="Edit Sku number"
                name="product_name"
                onChange={(e) => setProductName(e.target.value)}
                value={productName}
              />
            </div>
            <div className="flex">
              <label htmlFor="Status">Status:</label>
              <input
                placeholder="Change status"
                name="status"
                onChange={(e) => setProductName(e.target.value)}
                value={item.isDeleted ? "Inactive" : "Active"}
                readOnly
              />
            </div>
          </div>
          <div className="flex">
            <Button disabled={!isDisabled}>
              {state?.isActionLoading ? <Loader /> : "Save Changes"}
            </Button>
          </div>
        </form>
      </StyledModal>
    </>,
    document.getElementById("portal")!
  );
};

const Close = ({ ...props }) => {
  return (
    <div {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18 6L6 18M6 6L18 18"
          stroke="#0D0C0E"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

interface ImageBoxProps {
  image: never[];
  src: string;
  itemCode: string;
  setImage: Dispatch<React.SetStateAction<never[]>>;
  item: ItemType;
}

const ImageBox = ({ setImage, image, src, itemCode, item }: ImageBoxProps) => {
  const [fileSrc, setFileSrc] = useState(src);
  const [editedFile, setEditedFile] = useState([]);
  const inputRef = useRef<HTMLInputElement>(null);
  const { state, dispatch } = useAppContext();
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    setIsDisabled(fileSrc.split(":")[0] === "https" ? true : false);
  }, [fileSrc]);

  function handleClick(e: React.FormEvent<HTMLAnchorElement>) {
    e.preventDefault();
    inputRef.current?.click();
  }

  function handleFile(e: ProgressEvent<FileReader>) {
    setFileSrc(e.target?.result as string);
  }

  function handleChange() {
    const reader = new FileReader();
    reader.onloadend = handleFile;
    if (inputRef.current && inputRef.current.files) {
      reader.readAsDataURL(inputRef.current.files[0]);
      // setImage([...image, inputRef.current.files[0]] as never);

      setEditedFile([
        inputRef.current.files[inputRef.current.files.length - 1],
      ] as never);
    }
  }

  const handleSubmit = async () => {
    try {
      dispatch({ type: "action-loading" });

      const formData = new FormData();
      formData.append(`file`, editedFile[0]);

      const {
        data: { data },
      } = await ProductService.updateImage(itemCode, formData);
      item.image = data.image;
    } catch (error) {
      console.log(error);
    } finally {
      dispatch({ type: "action-loaded" });
      setIsDisabled(true);
    }
  };

  return (
    <StyledDiv className="grid">
      <div className="product">
        <img className="product-img" src={fileSrc} alt="product" />
      </div>
      <a
        href="settings"
        onClick={(e) => handleClick(e)}
        className="underline text-blue-tint-600"
      >
        edit image
      </a>
      <input
        type="file"
        ref={inputRef}
        name="avatar"
        id="avatar"
        onChange={() => handleChange()}
        accept="image/png, image/jpeg, image/jpg"
      />
      <Button disabled={isDisabled} onClick={handleSubmit}>
        {state?.isActionLoading ? <Loader /> : "Save Edited Image"}
      </Button>
    </StyledDiv>
  );
};

const BrandSelect = ({ ...props }) => {
  return (
    <StyledSelect name="title" id="brand" {...props}>
      <option defaultValue="">Select Brand</option>
      <option value="a brand">A brand</option>
      <option value="another brand">Another Brand</option>
      <option value="and another brand">And Another Brand</option>
    </StyledSelect>
  );
};

const StyledOverlay = styled.div.attrs(() => {})`
  /* position: relative; */
  position: absolute;
  inset: 0;
  height: 100vh;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.7);
  transition: 0.5s;
  &::after {
  }
`;

const StyledModal = styled.div`
  position: fixed;
  width: 66.5em;
  height: 47.125em;
  top: 50%;
  left: 55%;
  background-color: hsla(var(--clr-light));
  margin: 0 auto;
  padding: 3em;
  transform: translate(-50%, -50%);
  z-index: 99;
  transition: 0.3s;
  place-items: center;

  .close {
    position: absolute;
    right: 5%;
    top: 5%;
  }

  button {
    width: 237px;
  }

  form {
    width: 100%;
    margin-inline: auto;
    place-items: center;
    > * {
      width: 100%;
      justify-content: center;
      > * {
        justify-content: center;
        &:first-child:where(:not(:last-child)) {
          flex: 2;
        }
        align-items: center;
        input {
          outline: transparent solid 2px;
          border: none;
        }
      }
    }
    button {
      align-self: center;
    }
  }

  & > * {
    align-self: flex-start;
  }
`;

const StyledDiv = styled("div")`
  place-items: center;
  .product {
    width: 7.5em;
    height: 7.5em;
    border: 1px solid hsla(var(--clr-gray-50));
    background-color: hsla(var(--clr-blue-tint-800));
    border-radius: 50%;

    .product-img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }

  input[type="file"] {
    display: none;
  }
`;

const StyledSelect = styled("select")`
  background: none;
  border: 1px solid hsla(var(--clr-black-tint-200));
  width: 50%;
  border-radius: 8px;
  padding: 0.75em 1.5em;
  color: hsla(var(--clr-black-shade-100));
`;

const Loader = () => {
  return (
    <StyledLoader className="lds-ellipsis">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </StyledLoader>
  );
};

const StyledLoader = styled("div")`
  display: inline-block;
  position: relative;
  width: 80px;
  height: 30px;
  > * {
    position: absolute;
    top: 10px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);

    &:nth-child(1) {
      left: 8px;
      animation: lds-ellipsis1 0.6s infinite;
    }
    &:nth-child(2) {
      left: 8px;
      animation: lds-ellipsis2 0.6s infinite;
    }
    &:nth-child(3) {
      left: 32px;
      animation: lds-ellipsis2 0.6s infinite;
    }
    &:nth-child(4) {
      left: 56px;
      animation: lds-ellipsis3 0.6s infinite;
    }
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
`;
