import React from "react";
import styled from "styled-components";

export const Checkbox = (props: any) => {
  return (
    <Input>
      <input {...props} type="checkbox" className="checkbox" />
    </Input>
  );
};

const Input = styled.div`
  .checkbox {
    appearance: none;
    -webkit-appearance: none;
    height: 15px;
    width: 15px;
    border: 3px solid #413d45;
    border-radius: 2px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .checkbox:disabled {
    border: 3px solid #d3d3d3;
    cursor: auto;
  }
  .checkbox:after {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f00c";
    color: #413d45;
    font-size: 13px;
    display: none;
  }
  .checkbox:checked:after {
    display: block;
    background: #413d45;
    color: #fff;
    border: 3px solid #413d45;
  }
`;
