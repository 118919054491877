import React from "react";
import styled from "styled-components";
import CloseImg from "../../../../assets/svg/close.svg";

interface ImageProps {
  data: any;
  handleCloseImg: any;
  id: number;
}

const ImageItem: React.FC<ImageProps> = ({ data, handleCloseImg, id }) => {
  return (
    <Container>
      <div className="close">
        <img
          src={CloseImg}
          alt="close-icon"
          onClick={() => handleCloseImg(id)}
        />
      </div>
      <div className="img-cont">
        <img src={data} alt="apk-image" />
      </div>
    </Container>
  );
};

export default ImageItem;

const Container = styled.div`
  position: relative;

  .close {
    position: relative;
    display: flex;
    justify-content: flex-end;
  }
  .close img {
    width: 10px;
    cursor: pointer;
  }

  .img-cont {
    position: relative;
    width: 70px;
    height: 70px;
  }
  .img-cont img {
    width: 100%;
    height: 100%;
  }
`;
