import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import FinanceService from "services/FinanceService";
import AccessBankImage from "assets/images/access.png";
import PolarisBankImage from "assets/images/polaris.png";
import WemaBankImage from "assets/images/wema.png";
import { bankStatementToCsv } from "utils/csv";

type BankType = "wema" | "access" | "polaris";

const GetBankStatement = () => {
  const menuRef = useRef<HTMLDivElement>(null);
  const popRef = useRef<HTMLDivElement>(null);
  const [showBankDropdown, setBankDropdown] = useState(false);
  const [showDateDropdown, setDateDropdown] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [bank, setBank] = useState<BankType>("wema");
  const [timeText, setTimeText] = useState("All Time");
  const [bankStatement, setBankStatement] = useState("");
  const [statementAvailable, setStatementAvailable] = useState(false);
  const [customTimePopup, setPopup] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  useEffect(() => {
    const getStatement = async () => {
      try {
        let date = new Date();
        let day = date.getDate().toString();
        let month = (date.getMonth() + 1).toString();
        const endTemp = `${day.length > 1 ? day : "0" + day}/${
          month.length > 1 ? month : "0" + month
        }/${date.getFullYear()}`;
        const { data } = await FinanceService.getBankStatement(
          bank,
          startDate || "01/01/2021",
          endDate || endTemp
        );

        if (!data.data) throw Error();

        let d = data.data.data;

        d = JSON.parse(d.substring(0, d.indexOf("]") + 1));
        if (d.length === 0) throw Error();
        setBankStatement(bankStatementToCsv(d));
        setStatementAvailable(true);
      } catch (error: any) {
        setStatementAvailable(false);
      }
    };
    getStatement();
  }, [bank, startDate, endDate]);

  useEffect(() => {
    let eventHandler = (e: MouseEvent) => {
      if (
        !popRef.current?.contains(e!.target as Node) &&
        !menuRef.current?.contains(e!.target as Node)
      ) {
        setPopup(false);
      }
    };
    document.addEventListener("mousedown", eventHandler);

    return () => {
      document.removeEventListener("mousedown", eventHandler);
    };
  });
  useEffect(() => {
    let eventHandler = (e: MouseEvent) => {
      if (!customTimePopup && !menuRef.current?.contains(e!.target as Node)) {
        setShowModal(false);
      }
    };
    document.addEventListener("mousedown", eventHandler);

    return () => {
      document.removeEventListener("mousedown", eventHandler);
    };
  });
  const handleBankSelect = (bank: BankType) => {
    setBank(bank);
    setBankDropdown(false);
  };

  const handleStartDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const date = new Date(e.target.value);
    let day = date.getDate().toString();
    let month = (date.getMonth() + 1).toString();
    const start = `${day.length > 1 ? day : "0" + day}/${
      month.length > 1 ? month : "0" + month
    }/${date.getFullYear()}`;
    setStartDate(start);
  };
  const handleEndDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const date = new Date(e.target.value);
    let day = date.getDate().toString();
    let month = (date.getMonth() + 1).toString();
    const end = `${day.length > 1 ? day : "0" + day}/${
      month.length > 1 ? month : "0" + month
    }/${date.getFullYear()}`;
    setEndDate(end);
  };
  const handleAllTime = () => {
    setStartDate("01/01/2021");
    let dateNow = new Date();
    let day = dateNow.getDate().toString();
    let month = (dateNow.getMonth() + 1).toString();
    const endTemp = `${day.length > 1 ? day : "0" + day}/${
      month.length > 1 ? month : "0" + month
    }/${dateNow.getFullYear()}`;
    setEndDate(endTemp);
    setDateDropdown(false);
    setTimeText("All Time");
  };
  return (
    <>
      <Wrapper>
        <button
          className="bank-statement-btn"
          onClick={() => setShowModal(!showModal)}
        >
          Get payment platform statement
        </button>
        <div className={`modal-wrap ${showModal ? "show-modal" : ""}`}>
          <div ref={menuRef} className="modal-item-wrap">
            <div className="modal-text">
              <p>Select bank and date</p>
            </div>
            <div className="bank-select dropdown">
              <div
                className="dropdown-select"
                onClick={() => setBankDropdown(!showBankDropdown)}
              >
                <span>{bank}</span>
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <polyline points="6 9 12 15 18 9"></polyline>
                  </svg>
                </span>
              </div>
              <div
                className={`dropdown-list ${
                  showBankDropdown ? "reveal-dropdown" : ""
                }`}
              >
                <div
                  className="dropdown-list-item"
                  onClick={() => handleBankSelect("wema")}
                >
                  <div className="bank-img-wrap">
                    <img src={WemaBankImage} alt="wema" />
                  </div>
                  <span>Wema bank</span>
                </div>
                <div
                  className="dropdown-list-item"
                  onClick={() => handleBankSelect("access")}
                >
                  <div className="bank-img-wrap">
                    <img src={AccessBankImage} alt="access" />
                  </div>
                  <span>Access bank</span>
                </div>
                <div
                  className="dropdown-list-item"
                  onClick={() => handleBankSelect("polaris")}
                >
                  <div className="bank-img-wrap">
                    <img src={PolarisBankImage} alt="polaris" />
                  </div>
                  <span>Polaris bank</span>
                </div>
              </div>
            </div>
            <div className="date-select dropdown">
              <div
                className="dropdown-select"
                onClick={() => setDateDropdown(!showDateDropdown)}
              >
                <span>{timeText}</span>
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <polyline points="6 9 12 15 18 9"></polyline>
                  </svg>
                </span>
              </div>
              <div
                className={`dropdown-list ${
                  showDateDropdown ? "reveal-dropdown" : ""
                }`}
              >
                <div className="dropdown-list-item" onClick={handleAllTime}>
                  <span>All Time</span>
                </div>
                <div
                  className="dropdown-list-item"
                  onClick={() => {
                    setPopup(true);
                    setTimeText("Custom Time");
                  }}
                >
                  <span>Custom Time</span>
                </div>
              </div>
            </div>
            {statementAvailable && (
              <a
                className="export-btn pointer"
                download={`bank_statement`}
                href={`data:text/csv;charset=utf-8,${encodeURIComponent(
                  bankStatement
                )}`}
              >
                Export as CSV
              </a>
            )}
            {!statementAvailable && (
              <p className="error">
                CSV data not available for selected parameters
              </p>
            )}
          </div>
          {customTimePopup && (
            <div ref={popRef} className="pop-up">
              <div className="grid">
                <p>Custom time</p>
                <div className="flex">
                  <div className="grid">
                    <label htmlFor="startDate">From:</label>
                    <input
                      type="date"
                      id="startDate"
                      max={new Date().toISOString().split("T")[0]}
                      onChange={handleStartDate}
                    />
                  </div>

                  <div className="grid">
                    <label htmlFor="endDate">To:</label>
                    <input
                      type="date"
                      id="endDate"
                      max={new Date().toISOString().split("T")[0]}
                      onChange={handleEndDate}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  .bank-statement-btn {
    background: none;
    padding: 8px 16px;
    border: 1px solid #022f1a;
    color: #022f1a;
    font-weight: 600;
    font-size: 12px;
    border-radius: 5px;
    cursor: pointer;

    :active {
      transform: scale(0.999);
    }
  }

  .modal-wrap {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.8);
    display: none;
    place-items: center;

    .modal-item-wrap {
      width: 280px;
      background-color: #fff;
      padding: 30px 20px;
    }

    .modal-text {
      text-align: center;
    }

    .dropdown-select {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: 1px solid #060607;
      padding: 5px 8px;
      cursor: pointer;
      margin-top: 16px;
    }
    .dropdown-list {
      box-shadow: 1px 1px 5px #999393cc;
      margin-top: 8px;
      border-radius: 4px;
      height: 0;
      overflow: hidden;
      transition: height 0.1s ease-in-out;
    }
    .dropdown-list.reveal-dropdown {
      height: 170px;
      padding-bottom: 4px;
    }
    .dropdown-list-item {
      display: flex;
      align-items: center;
      padding: 10px;
      font-weight: 600;
      font-size: 14px;

      .bank-img-wrap {
        width: 30px;
        height: 30px;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      span {
        margin-left: 12px;
      }

      :hover {
        background-color: #f1cdac;
        cursor: pointer;
      }
    }

    .export-btn {
      margin-top: 50px;
      display: block;
      background-color: #e9e9ef;
      cursor: pointer;
      border-radius: 5px;
      width: 100%;
      padding: 10px;
      text-align: center;
      color: #545056;
      font-weight: 600;
    }
  }
  .modal-wrap.show-modal {
    display: grid;
  }
  p.error {
    font-size: 10px;
    color: red;
    font-weight: 600;
    text-align: center;
    margin-top: 8px;
  }

  .pop-up {
    width: max-content;

    margin: auto;
    padding: 0.5em 1em;
    position: absolute;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
    background: hsla(var(--clr-light));
    z-index: 99;

    input {
      padding: 0.625em;
      border: 1px solid hsla(var(--clr-border));
      border-radius: 2px;
      width: 12em;
    }
  }
`;

export default GetBankStatement;
