import React, { Dispatch } from "react";
import styled from "styled-components";

interface InputFieldProps {
  isHide?: boolean;
  setIsHide?: Dispatch<React.SetStateAction<boolean>> | undefined;
  inputId: string;
}

export const InputField: React.FC<
  InputFieldProps &
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >
> = ({ isHide, setIsHide, inputId, ...others }) => {
  const shouldHide = isHide && isHide ? "password" : "text";
  return (
    <StyledDiv className="flex input-container ff-serif">
      <label className="sr-only" htmlFor={inputId}>
        Current Password
      </label>
      <input type={shouldHide} id={inputId} {...others} />
      {shouldHide && (
        <span
          className="pointer"
          onClick={() => {
            if (!setIsHide) return;
            setIsHide(!isHide)!;
          }}
        >
          {/* <Hide isHide={isHide} /> */}
        </span>
      )}
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  padding: 0.75em 1.5em;
  border-radius: 8px;
  border: 1px solid hsla(var(--clr-border));
  outline-offset: 2px;
  width: 50%;
  align-items: center;
  justify-content: space-between;

  input {
    outline: transparent solid 2px;
    border: none;
    width: 100%;
  }
`;
