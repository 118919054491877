import ContentLayout from "layouts/ContentLayout";
import styled from "styled-components";
import Dashboard from "./Dashboard";
import Spanner from "../../assets/svg/spanner.svg";
import Manage from "../../assets/svg/manage.svg";
import Cloud from "../../assets/svg/cloud.svg";
import Profile from "../../assets/svg/set-user.svg";
import { useAppContext } from "context/AppContext";

const dashboardData = [
  {
    icon: Cloud,
    title: "App Distribution",
    content:
      "Manage app updates by providing detailed release notes and selecting specific user groups for the rollout",
    btnText: "Upload New APK",
    link: "/dashboard/settings/app_distribution",
  },
  {
    icon: Manage,
    title: "Admin Management",
    content:
      "Manage all users on the dashboard: view active users, invite new users, and block users.",
    btnText: "Manage Admin",
    link: "/dashboard/settings/admin",
  },
  {
    icon: Spanner,
    title: "Configuration setting",
    content:
      "Manage and configure the Reoda app, including order limits, wait times, and more., invite new users, and block users.",
    btnText: "Configure App",
    link: "/dashboard/settings/configuration",
  },
  {
    icon: Profile,
    title: "Profile",
    content:
      "Easily manage your profile by changing your password and updating your profile picture.",
    btnText: "Manage Profile",
    link: "/dashboard/settings/profile",
  },
];

const DashboardSettings = () => {
  const { state } = useAppContext();
  const userRoles: string[] = state?.user?.roles || [];
  const isSuperAdmin = userRoles.includes("SUPER_ADMIN");

  const filteredData = dashboardData.filter((item) => {
    if (item.title === "Admin Management" && !isSuperAdmin) {
      return false;
    }
    return true;
  });

  return (
    <>
      <ContentLayout titleText="Dashboard Settings">
        <Container>
          {filteredData.map((item, index) => (
            <Dashboard key={index} {...item} />
          ))}
        </Container>
      </ContentLayout>
    </>
  );
};

const Container = styled.div`
  padding: 20px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
  row-gap: 60px;
`;

export default DashboardSettings;
