import { Dispatch, useState, useEffect } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { useAppContext } from "context/AppContext";
import BannerService from "services/BannerService";
import { Button, TableTab } from "components";
import ContentLayout from "layouts/ContentLayout";
import styled from "styled-components";
import { Modal } from "./AddProductModal";
import { ItemType } from ".";
import { Bundle_ItemType } from ".";

const navItems = [
  { link: "All SKU", linkTo: "all-skus" },
  { link: "Active SKU", linkTo: "active-skus" },
  { link: "Disable SKU", linkTo: "disabled-skus" },
];

export const ProductsManagement = () => {
  const location = useLocation();
  const [promoPath, setPromoPath] = useState("");
  const [productsCsvData, setProductsCsvData] = useState([]);
  const [promoCsvData, setPromoCsvData] = useState([]);
  const [isModal, setIsModal] = useState(false);
  const [skuProducts, setSkuProducts] = useState([]);
  const [willFilter, setWillFilter] = useState("");
  const [byRegion, setByRegion] = useState("");
  const [byStatus, setByStatus] = useState("");
  const [byDepots, setByDepots] = useState("");
  const [byAdsType, setByAdsType] = useState("");
  const [bySpecific, setBySpecific] = useState("");
  const [byCustomStart, setByCustomStart] = useState("");
  const [byCustomEnd, setByCustomEnd] = useState("");
  const [pageParams, setPageParams] = useSearchParams();
  const [page, setPage] = useState(() => {
    let p = Number(pageParams.get("page"));
    return p > 0 ? p : 1;
  });
  const [pages, setPages] = useState<number>(0);
  const [limit, setLimit] = useState(10);

  const { dispatch } = useAppContext();

  useEffect(() => {
    async function fetchData() {
      dispatch({ type: "data-loading" });
      try {
        const {
          data: {
            data: { products, meta },
          },
        } = await BannerService.getAllBundleProducts("active");

        setSkuProducts(products);

        const csvDatas = products.map((item: ItemType) => {
          return {
            item_no: item.product_code,
            product_name: item.product_name,
            brand_name: item.brand_name,
            status: item.isDeleted ? "Inactive" : "Active",
          };
        });
        setProductsCsvData(csvDatas);
      } catch (error) {
      } finally {
        dispatch({ type: "data-loaded" });
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      dispatch({ type: "data-loading" });
      try {
        const {
          data: {
            data: { banners, meta },
          },
        } = await BannerService.getAllBanner(
          byRegion,
          byStatus,
          byAdsType,
          byDepots,
          byCustomStart,
          byCustomEnd,
          limit,
          page
        );

        const csvDatas = banners.map((item: Bundle_ItemType) => {
          return {
            id: item._id,
            title: item.title,
            description: item.description,
            promotion_type:
              item.banner_type === "buy_actions"
                ? "Bundle Offer Ads"
                : "Promotion Ads",
            depots: convertToString(item.depots),
            // banner_type: item.banner_type,
            start_date: item.start_date,
            end_date: item.end_date,
            status: item.is_active ? "Active" : "Inactive",
            createdAt:
              new Date().toISOString().substr(0, 10) + "T00:00:00.000Z",
          };
        });
        setPromoCsvData(csvDatas);
      } catch (error) {
      } finally {
        dispatch({ type: "data-loaded" });
      }
    }

    fetchData();
  }, [limit, page, willFilter, dispatch]);

  const convertToString = (itemKey: any) => {
    const convertedString = itemKey.join(" | ");

    return convertedString;
  };

  useEffect(() => {
    const currentUrl = location.pathname;
    setPromoPath(currentUrl.split("/")[3]);
  }, [location]);

  return (
    <ContentLayout
      titleText="Product Mangement"
      subText="SKU Inventory"
      component={<AddProductButton {...{ setIsModal }} />}
    >
      <ContentWrapper>
        <Modal display={isModal} setIsModal={setIsModal} />
        <TableTab
          // canFilter={false}
          navItems={navItems}
          data={productsCsvData}
          file={`${promoPath}.csv`}
          setBySpecific={setBySpecific}
          setByCustomStart={setByCustomStart}
          setByCustomEnd={setByCustomEnd}
          setWillFilter={setWillFilter}
        />
      </ContentWrapper>
    </ContentLayout>
  );
};

const AddProductButton = ({
  setIsModal,
}: {
  setIsModal: Dispatch<React.SetStateAction<boolean>>;
}) => (
  <Button variant="ghost" onClick={() => setIsModal(true)}>
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15"
        height="14"
        viewBox="0 0 15 14"
        fill="none"
      >
        <path
          d="M7.5 2.91699V11.0837"
          stroke="#022f1a"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.41675 7H11.5834"
          stroke="#0F172A"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
    <span className="fs-100 fw-600">Add Product</span>
  </Button>
);

const ContentWrapper = styled.div`
  height: 100%;
  overflow-y: scroll;
  position: relative;
  padding-top: 5em;

  .chart-container {
    padding: 20px 0;
  }
`;
