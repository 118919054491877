import { useEffect, useState, Dispatch, SetStateAction } from "react";
import styled from "styled-components";
import { ItemType } from "../CustomerSupportManagement";

interface FeatureCtaProps {
  setSurveyForm: Dispatch<SetStateAction<{}>>;
  setFormView: Dispatch<SetStateAction<string>>;
  item?: ItemType;
}

interface Option {
  text: string;
  selectedValue: string;
}

const FeatureCtaForm: React.FC<FeatureCtaProps> = ({
  setSurveyForm,
  setFormView,
  item,
}) => {
  const [positive_flag, setPositiveFlag] = useState("");
  const [positive_flag_info, setPositiveFlagInfo] = useState("");
  const [negative_flag, setNegativeFlag] = useState("");
  const [negative_flag_info, setNegativeFlagInfo] = useState("");

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setSurveyForm((prev: any) => {
      const updatedForm = { ...prev };
      const updatedFeature = { ...updatedForm.feature };
      updatedFeature.positive_flag = positive_flag;
      updatedFeature.positive_flag_info = positive_flag_info;
      updatedFeature.negative_flag = negative_flag;
      updatedFeature.negative_flag_info = negative_flag_info;

      updatedForm.feature = updatedFeature;
      return { ...updatedForm };
    });

    setFormView("duration");
  };

  useEffect(() => {
    if (item?.feature?.positive_flag) {
      setPositiveFlag(item?.feature.positive_flag);
    }
    if (item?.feature?.positive_flag_info) {
      setPositiveFlagInfo(item?.feature.positive_flag_info);
    }
    if (item?.feature?.negative_flag) {
      setNegativeFlag(item?.feature.negative_flag);
    }
    if (item?.feature?.negative_flag_info) {
      setNegativeFlagInfo(item?.feature.negative_flag_info);
    }
  }, [item]);
  return (
    <Form>
      <form className="form-container" onSubmit={handleSubmit}>
        <h2>Feature Flag</h2>
        <p className="headertext">
          Pick a button with a CTA and choose its screen.{" "}
          <i> Eg “go to home “ to Home</i>
        </p>
        <div className="form-control">
          <div className="int-group">
            <input
              type="text"
              placeholder="Type CTA here"
              required
              value={positive_flag_info}
              onChange={(e) => setPositiveFlagInfo(e.target.value)}
            />
            <span>To</span>
            <select
              value={positive_flag}
              required
              onChange={(e) => setPositiveFlag(e.target.value)}
            >
              <option value="" disabled selected hidden>
                Select screen
              </option>
              <option value="homepage">homepage</option>
              <option value="wallet">wallet</option>
              <option value="transactionHistory">transactionHistory</option>
              <option value="bankHistory">bankHistory</option>
            </select>
          </div>
          <div className="int-group">
            <input
              type="text"
              placeholder="Type CTA here"
              required
              value={negative_flag_info}
              onChange={(e) => setNegativeFlagInfo(e.target.value)}
            />
            <span>To</span>
            <select
              value={negative_flag}
              required
              onChange={(e) => setNegativeFlag(e.target.value)}
            >
              <option value="" disabled selected hidden>
                Select screen
              </option>
              <option value="HOME">homepage</option>
              <option value="WALLET">wallet</option>
              <option value="TRANSACTIONHISTORY">transactionHistory</option>
              <option value="BANKHISTORY">bankHistory</option>
              <option value="NOTIFICATION">notification</option>
              <option value="CHECKOUT">checkout</option>
            </select>
          </div>
        </div>
        <div className="btn-container">
          <button>next</button>
        </div>
      </form>
    </Form>
  );
};

const Form = styled.div`
  .form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .form-container h2 {
    color: #022f1a;
    font-weight: 500;
    margin-bottom: 30px;
  }
  .headertext: {
    font-size: 1.2rem;
    margin-bottom: 30px;
    font-weight: 500;
  }
  .headertext i {
    font-weight: 400;
    color: #666;
  }
  .form-control {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .form-control h4 {
    color: #db8130;
    font-weight: 600;
    cursor: pointer;
    font-size: 1rem;
  }
  .int-group {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .int-group input {
    width: 45%;
    background: #fff;
    height: 40px;
    padding: 5px;
    border: 2px solid #ccc;
    border-radius: 5px;
  }
  .int-group select {
    width: 45%;
    background: #fff;
    height: 40px;
    padding: 5px;
    border: 2px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
  }
  .int-single select,
  .int-single input {
    width: 100%;
    background: #fff;
    height: 40px;
    padding: 5px;
    border: 2px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
  }
  .int-single input {
    cursor: text;
  }
  .int-single textarea {
    width: 100%;
    background: #fff;
    height: 120px;
    padding: 5px;
    border: 2px solid #ccc;
    border-radius: 5px;
  }
  .btn-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .btn-container button {
    background: #022f1a;
    width: 200px;
    color: #fff;
    padding: 10px 35px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 600;
  }
  .btn-container button:disabled {
    background: #ccd5d1;
    cursor: auto;
  }

  .int-single input[type="file"] {
    position: absolute;
    width: 100%;
    height: 100px;
    z-index: 102;
    opacity: 0;
  }
  .int-single label {
    display: block;
    color: #888;
    font-size: 0.9rem;
    font-weight: 600;
    margin-bottom: 15px;
  }
  .int-single p {
    font-size: 0.8rem;
    color: #999;
  }
  .int-single ._label {
    background: #ececec;
    width: 100%;
    height: 50px;
    border-top: 2px dashed #d4d4d4;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .lrg {
    height: 100px !important;
  }
  .int-single ._label p {
    font-size: 0.9rem;
    color: #022f1a;
    cursor: pointer;
  }
  .error_message {
    width: 100%;
    display: flex;
    justify-content: center;
    color: #d22730 !important;
    font-size: 0.9rem;
  }
  .plst {
    color: #f48383;
    font-weight: 900;
    cursor: pointer;
  }
`;

export default FeatureCtaForm;
