import React from "react";
import styled from "styled-components";

interface ConfigProps {
  title: string;
  content: string;
  action: (arg: string) => void;
  max: string;
  min: string;
  // setFileSrc: Dispatch<SetStateAction<string>>;
}

export const ConfigSkeleton: React.FC<ConfigProps> = ({
  title,
  content,
  action,
  max,
  min,
}) => {
  return (
    <Container>
      <LeftContainer>
        <h2>{title}</h2>
        <p>{content}</p>
        <div>
          <button
            onClick={() => action(title === "Order Limit" ? "order" : "wait")}
          >
            Edit
          </button>
        </div>
      </LeftContainer>
      <RightContainer>
        <div className="right-ech">
          <div className="label">Min</div>
          <div className="input">{min}</div>
        </div>
        {max && (
          <div className="right-ech">
            <div className="label">Max</div>
            <div className="input">{max}</div>
          </div>
        )}
      </RightContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 50%;

  & h2 {
    font-size: 0.9rem;
    font-weight: 600;
  }
  & p {
    font-size: 0.85rem;
    color: rgba(0, 0, 0, 0.5);
  }
  & div {
    margin-top: 15px;

    button {
      background: none;
      padding: 5px 26px;
      border: 1px solid #022f1a;
      color: #022f1a;
      border-radius: 3px;
      font-size: 0.9rem;
      font-weight: 600;
      cursor: pointer;
    }
  }
`;
const RightContainer = styled.div`
  display: flex;
  gap: 30px;
  justify-content: flex-start;
  width: 35%;

  .right-ech {
    display: flex;
    align-items: center;

    .label {
      background: rgba(0, 0, 0, 0.15);
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
      border: 1px solid rgba(0, 0, 0, 0.15);
      padding: 5px 8px;
      font-weight: 600;
    }
    .input {
      padding: 6px 8px;
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
      padding-left: 30px;
      font-size: 0.9rem;
      font-weight: 600;
      color: #555;
    }
  }
`;

export default ConfigSkeleton;
