import { useState, Dispatch, SetStateAction, useEffect } from "react";
import styled from "styled-components";
import ArrowLeft from "../../../assets/svg/arrow-left.svg";
import { SingleSku } from "./SingleSku";
import { ItemType } from "..";

interface SkuFormProps {
  setFormView: Dispatch<SetStateAction<String>>;
  setSelectedSkuProduct: Dispatch<SetStateAction<any>>;
  selectedSkuProduct: any;
  skuProducts: any;
  item?: ItemType;
}
export const SkuSelect: React.FC<SkuFormProps> = ({
  setFormView,
  selectedSkuProduct,
  setSelectedSkuProduct,
  skuProducts,
  item,
}) => {
  const handlebackArrow = () => {
    setFormView("bundle");
    setSelectedSkuProduct([]);
  };

  const handleSkuForm = () => {
    setFormView("reward");
  };

  return (
    <div>
      <Header>
        <div className="arrowBack">
          <img src={ArrowLeft} alt="" onClick={handlebackArrow} />
        </div>
        <div className="header_text">
          <h3>Select SKU for Bundle Ads</h3>
        </div>
      </Header>
      <Container>
        <div className="wrapper">
          {skuProducts.map((data: any) => {
            return (
              <SingleSku
                productData={data}
                key={data.product_code}
                setSelectedSkuProduct={setSelectedSkuProduct}
                selectedSkuProduct={selectedSkuProduct}
                item={item}
                skuProducts={skuProducts}
              />
            );
          })}
        </div>
      </Container>
      <ButtonContainer>
        <div className="btn-container">
          <button
            disabled={selectedSkuProduct.length < 1 ? true : false}
            onClick={handleSkuForm}
          >
            Confirm Items
          </button>
        </div>
      </ButtonContainer>
    </div>
  );
};

const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 50px;

  .arrowBack {
    width: 20px;
  }
  .arrowBack img {
    width: 100%;
    cursor: pointer;
  }
  .header_text {
    width: 100%;
    text-align: center;
    color: #022f1a;
  }
`;

const ButtonContainer = styled.div`
  .btn-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
  .btn-container button {
    background: #db8130;
    width: 200px;
    color: #fff;
    padding: 10px 35px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 600;
  }
  .btn-container button:disabled {
    background: #ccd5d1;
    cursor: auto;
  }
`;

const Container = styled.div`
  .wrapper {
    display: flex;
    align-items: center;
    gap: 15px;
    row-gap: 30px;
    flex-wrap: wrap;
    height: 380px;
    overflow-y: auto;
  }
`;
