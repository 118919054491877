import styled from "styled-components";
import ReactDOM from "react-dom";
import { Button, InputField } from "components";
import vector from "assets/images/vector.png";
import React, { Dispatch, useEffect, useRef, useState } from "react";
import ProductService from "services/ProductService";
import { useAppContext } from "context/AppContext";
import successIcon from "assets/images/auth/success-tick.gif";

interface ModalProps {
  display: boolean;
  setIsModal: Dispatch<React.SetStateAction<boolean>>;
}

export const Modal: React.FC<ModalProps> = ({ display, setIsModal }) => {
  const { state, dispatch } = useAppContext();
  const [productName, setProductName] = useState("");
  const [productCode, setProductCode] = useState("");
  const [description, setDescription] = useState("");
  const [brand, setBrand] = useState("");
  const [image, setImage] = useState([]);
  const isDisabled =
    productName && productCode && description && image.length > 0;
  const [isCreated, setIsCreated] = useState(false);

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    var formData = new FormData(e.target as HTMLFormElement);
    formData.append("file", image[0]);

    try {
      dispatch({ type: "data-loading" });
      const {
        data: { error, code },
      } = await ProductService.addProduct(formData);
      if (!error && code === 201) setIsCreated(true);
    } catch (error) {
      console.log("%%: ", error);
    } finally {
      dispatch({ type: "data-loaded" });
    }
  }

  function handleChange(e: React.ChangeEvent<HTMLSelectElement>) {
    setBrand(e.target.value);
  }

  useEffect(() => {
    if (!display) setIsCreated(false);
  });

  if (!display) return null;
  return ReactDOM.createPortal(
    <>
      <StyledOverlay />
      {isCreated ? (
        <StyledSuccess className="grid success">
          <img src={successIcon} alt="success" />
          <Button onClick={() => setIsModal(false)}>Close</Button>
        </StyledSuccess>
      ) : (
        <div>
          <StyledModal className="grid">
            <Close
              className="pointer close"
              onClick={() => setIsModal(false)}
            />
            <h1 className="fs-600 fw-400 text-blue-dark-050">
              Add New Product
            </h1>
            <ImageBox {...{ setImage, image }} />
            <form onSubmit={handleSubmit} className="grid flow">
              <InputField
                inputId="skuName"
                placeholder="Enter Sku Name"
                name="product_name"
                onChange={(e) => setProductName(e.target.value)}
                value={productName}
              />
              <InputField
                inputId="skuCode"
                placeholder="Enter Sku Number"
                name="product_code"
                onChange={(e) => setProductCode(e.target.value)}
                value={productCode}
              />
              <InputField
                inputId="description"
                placeholder="Enter description"
                name="description"
                onChange={(e) => setDescription(e.target.value)}
                value={description}
              />
              <BrandSelect onChange={handleChange} />
              <div>
                <Button disabled={!isDisabled}>
                  {state?.isLoading ? <Loader /> : "Add Product"}
                </Button>
              </div>
            </form>
          </StyledModal>
        </div>
      )}
    </>,
    document.getElementById("portal")!
  );
};

const Loader = () => {
  return (
    <StyledLoader className="lds-ellipsis">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </StyledLoader>
  );
};

const StyledLoader = styled("div")`
  display: inline-block;
  position: relative;
  width: 80px;
  height: 30px;
  > * {
    position: absolute;
    top: 10px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);

    &:nth-child(1) {
      left: 8px;
      animation: lds-ellipsis1 0.6s infinite;
    }
    &:nth-child(2) {
      left: 8px;
      animation: lds-ellipsis2 0.6s infinite;
    }
    &:nth-child(3) {
      left: 32px;
      animation: lds-ellipsis2 0.6s infinite;
    }
    &:nth-child(4) {
      left: 56px;
      animation: lds-ellipsis3 0.6s infinite;
    }
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
`;

const Close = ({ ...props }) => {
  return (
    <div {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18 6L6 18M6 6L18 18"
          stroke="#0D0C0E"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

interface ImageBoxProps {
  image: never[];
  setImage: Dispatch<React.SetStateAction<never[]>>;
}

const ImageBox = ({ setImage, image }: ImageBoxProps) => {
  const [fileSrc, setFileSrc] = useState(vector);
  const inputRef = useRef<HTMLInputElement>(null);

  function handleClick(e: React.FormEvent<HTMLAnchorElement>) {
    e.preventDefault();
    inputRef.current?.click();
  }

  function handleFile(e: ProgressEvent<FileReader>) {
    setFileSrc(e.target?.result as string);
  }

  function handleChange() {
    const reader = new FileReader();
    reader.onloadend = handleFile;
    if (inputRef.current && inputRef.current.files) {
      reader.readAsDataURL(inputRef.current.files[0]);
      setImage([...image, inputRef.current.files[0]] as never);
    }
  }

  return (
    <StyledDiv className="grid">
      <div className="product">
        <img className="product-img" src={fileSrc} alt="product" />
      </div>
      <a
        href="settings"
        onClick={(e) => handleClick(e)}
        className="underline text-blue-tint-600"
      >
        add image
      </a>
      <input
        type="file"
        ref={inputRef}
        name="avatar"
        id="avatar"
        onChange={() => handleChange()}
        accept="image/png, image/jpeg, image/jpg"
      />
    </StyledDiv>
  );
};

const BrandSelect = ({ ...props }) => {
  return (
    <StyledSelect name="brand_name" id="brand" {...props}>
      <option value="" defaultChecked>
        Select Brand
      </option>
      <option defaultValue="" value="Dunhill Switch">
        Dunhill Switch
      </option>
      <option value="Benson & Hedges">Benson &amp; Hedges</option>
      <option value="Pall Mall">Pall Mall</option>
    </StyledSelect>
  );
};

const StyledOverlay = styled.div.attrs(() => {})`
  /* position: relative; */
  position: absolute;
  inset: 0;
  height: 100vh;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.7);
  transition: 0.5s;
  &::after {
  }
`;

const StyledSuccess = styled.div`
  position: fixed;
  width: 66.5em;
  height: 47.125em;
  top: 50%;
  left: 55%;
  background-color: hsla(var(--clr-light));
  margin: 0 auto;
  padding: 3em;
  transform: translate(-50%, -50%);
  z-index: 99;
  transition: 0.3s;
  place-items: center;
  > img {
    aspect-ratio: 1 / 0.5;
    object-fit: contain;
    object-position: center;
    height: 100%;
    width: 100%;
  }
`;

const StyledModal = styled.div`
  position: fixed;
  width: 66.5em;
  height: 47.125em;
  top: 50%;
  left: 55%;
  background-color: hsla(var(--clr-light));
  margin: 0 auto;
  padding: 3em;
  transform: translate(-50%, -50%);
  z-index: 99;
  transition: 0.3s;
  place-items: center;

  .close {
    position: absolute;
    right: 5%;
    top: 5%;
  }

  button {
    width: 237px;
  }

  form {
    width: 100%;
    margin-inline: auto;
    place-items: center;
  }

  & > * {
    align-self: flex-start;
  }
`;

const StyledDiv = styled("div")`
  place-items: center;
  .product {
    width: 7.5em;
    height: 7.5em;
    border: 1px solid hsla(var(--clr-gray-50));
    background-color: hsla(var(--clr-blue-tint-800));
    border-radius: 50%;

    .product-img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }

  input[type="file"] {
    display: none;
  }
`;

const StyledSelect = styled("select")`
  background: none;
  border: 1px solid hsla(var(--clr-black-tint-200));
  width: 50%;
  border-radius: 8px;
  padding: 0.75em 1.5em;
  color: hsla(var(--clr-black-shade-100));
`;
