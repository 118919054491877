import { useEffect, useState, Dispatch, SetStateAction } from "react";
import styled from "styled-components";
import { Checkbox } from "components/CheckBox";
import { ItemType } from "../CustomerSupportManagement";

interface CreateSurveyProps {
  setSurveyForm: Dispatch<SetStateAction<{}>>;
  setFormView: Dispatch<SetStateAction<string>>;
  item?: ItemType;
}

const SurveyTitleForm: React.FC<CreateSurveyProps> = ({
  setSurveyForm,
  setFormView,
  item,
}) => {
  const [title, setTitle] = useState("");
  const [mandatory, setMandatory] = useState(false);

  const handleCreateForm = (e: any) => {
    e.preventDefault();
    setSurveyForm({ title, mandatory });
    setFormView("type");
  };

  useEffect(() => {
    if (item?.title) {
      setTitle(item?.title);
      setMandatory(item?.mandatory);
    }
  }, [item]);

  return (
    <Form>
      <form className="form-container" onSubmit={handleCreateForm}>
        <h2>Create Survey</h2>
        <div className="form-control">
          <div className="int-single">
            <input
              type="text"
              placeholder="Enter the title of your survey"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div className="container_bottom">
            <Checkbox
              id="checkbox"
              checked={mandatory}
              onChange={(e: any) => setMandatory(e.target.checked)}
            />
            <label htmlFor="checkbox">compulsory</label>
          </div>
          <div className="btn-container">
            <button disabled={title === "" ? true : false}>Continue</button>
          </div>
        </div>
      </form>
    </Form>
  );
};

const Form = styled.div`
  .form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .form-container h2 {
    color: #022f1a;
    font-weight: 500;
    margin-bottom: 30px;
  }
  .create {
    display: none;
  }
  .form-control {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  .int-group {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .int-group select {
    width: 48%;
    background: #fff;
    height: 40px;
    padding: 5px;
    border: 2px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
  }
  .int-single input {
    width: 100%;
    background: #fff;
    height: 40px;
    padding: 5px;
    border: 2px solid #b3b1b5;
    border-radius: 5px;
    outline: none;
  }
  .int-single select {
    width: 100%;
    background: #fff;
    height: 40px;
    padding: 5px;
    border: 2px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
  }
  .btn-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .btn-container button {
    background: #022f1a;
    width: 200px;
    color: #fff;
    padding: 10px 35px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 600;
  }
  .btn-container button:disabled {
    background: #ccd5d1;
    cursor: auto;
  }
  .int-single input[type="file"] {
    position: absolute;
    width: 100%;
    height: 100px;
    z-index: 102;
    opacity: 0;
  }
  .int-single label {
    display: block;
    color: #888;
    font-size: 0.9rem;
    font-weight: 600;
    margin-bottom: 15px;
  }
  .int-single p {
    font-size: 0.8rem;
    color: #999;
  }
  .int-single ._label {
    background: #ececec;
    width: 100%;
    height: 50px;
    border-top: 2px dashed #d4d4d4;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .lrg {
    height: 100px !important;
  }
  .int-single ._label p {
    font-size: 0.9rem;
    color: #022f1a;
    cursor: pointer;
  }
  .container_bottom {
    display: flex;
    align-center: center;
    gap: 10px;
  }
  .container_bottom label {
    font-size: 0.9rem;
  }
`;

export default SurveyTitleForm;
