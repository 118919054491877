import { TableTab } from "components";
import ContentLayout from "layouts/ContentLayout";
import { Routes, Route, Navigate } from "react-router-dom";
import styled from "styled-components";
import OutletHistory from "./OutletHistory";
import userIcon from "../../assets/svg/user.svg";
import usersIcon from "../../assets/svg/users-light.svg";
import truckIcon from "../../assets/svg/truck.svg";
import Details from "./Details";
import { useLocation } from "react-router-dom";

interface HistoryDetailsProps {}
const HistoryDetails: React.FC<HistoryDetailsProps> = (props) => {
  return (
    <ContentLayout titleText="Outlet Mangement" subText="Details of order">
      <ContentWrapper>
        <div className="chart-container">
          <div className="flex card-container">
            <div className="flex flex-col outlets">
              <section className="flex top">
                <div className="section-icon">
                  <img src={userIcon} alt="icon" />
                </div>
                <h3 className="fs-600 fw-600">Outlet Details</h3>
              </section>
              <div className="flex flex-col">
                <p>Name: Ojo seyi</p>
                <p>Outlet ID: 156887097 </p>
                <p>Phone number: 0808 234 7854 </p>
                <p>
                  Address: No 5 ajayi close, off lai ahmed street, Ajah lagos
                </p>
              </div>
            </div>
            <div className="flex flex-col outlets">
              <section className="flex top">
                <div className="section-icon">
                  <img src={usersIcon} alt="icon" />
                </div>
                <h3 className="fs-600 fw-600">Reps Details</h3>
              </section>
              <div className="flex flex-col">
                <p>Name: Augusta okechukwu</p>
                <p>Reps ID: 455487097 </p>
                <p>Phone number: 0808 234 7854 </p>
                <p>
                  Address: No 5 ajayi close, off lai ahmed street, Ajah lagos
                </p>
              </div>
            </div>
            <div className="flex flex-col outlets">
              <section className="flex top">
                <div className="section-icon">
                  <img src={truckIcon} alt="icon" />
                </div>
                <h3 className="fs-600 fw-600">Order Details(23445678)</h3>
              </section>
              <div className="flex flex-col">
                <p>Transaction ID: 156776788</p>
                <p>Status: Pending</p>
                <p>Quantity: 1000</p>
                <p>Date: 12/3/2022 (09:05:09)</p>
              </div>
            </div>
          </div>
        </div>
        <TableTab text="Items and orders details" />
        <Details />
      </ContentWrapper>
    </ContentLayout>
  );
};

const ContentWrapper = styled.div`
  height: 100%;
  overflow-y: scroll;
  position: relative;

  .flex-col {
    flex-direction: column;
  }
  .chart-container {
    padding: 20px 0;
  }

  .card-container {
    padding-inline: 2em;
  }

  .top {
    align-items: center;
  }

  .outlets {
    width: 31.125em;
    /* height: 10.125em; */
    background-color: #fff;
    margin: 0 auto;
    border-radius: 8px;
    padding: 24px 24px;
  }
  .section-icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #022f1a;
    display: grid;
    place-items: center;
    margin-top: 5px;

    img {
      object-fit: contain;
    }
  }
  .summation {
    display: flex;
    flex-direction: column;

    .total-amount {
      margin-top: auto;
      color: #022f1a;
      font-size: 23px;
      font-weight: 600;
    }
  }
`;

export default HistoryDetails;
