import Api from "./Api";

const Services = {
  getAllProducts(filterBy?: string, limit?: number, page?: number) {
    var params = {
      ...(Boolean(filterBy) && { filter: filterBy }),
      limit,
      page,
    };

    return Api().get("product", { params });
  },
  addProduct(formData: FormData) {
    return Api().post("product", formData);
  },
  activateProduct(productId: string, status: string) {
    return Api().patch(`product/activateOrDeactivate/${productId}`, {
      status,
    });
  },
  updateProduct(productId: string, params: any) {
    console.log("PARAMMSS", params);

    return Api().put(`product/${productId}/update-details`, params);
  },
  updateImage(productId: string, formData: FormData) {
    return Api().put(`product/${productId}/update-image`, formData);
  },
  getAllDepots() {
    return Api().get("banner-ads/depots");
  },
  getAllDepotsByRegion(params: any) {
    const regionId = params;
    return Api().get(
      `banner-ads/depot-by-region?regionId=${regionId.join(",")}`
    );
  },
  getAllRegions() {
    return Api().get("banner-ads/regions");
  },
  addBanner(formData: FormData) {
    return Api().post("banner-ads", formData);
  },
};

export default Services;
