import React, { useState, useEffect } from "react";
import Pagination from "../../components/Pagination";
import OrderService from "../../services/OrderManagementService";
import {
  useNavigate,
  useSearchParams,
  useOutletContext,
} from "react-router-dom";
import { formatMoney } from "../../utils/formatMoney";
import { useAppContext } from "context/AppContext";

import styled from "styled-components";
import { EmptyList, Table, TableSkeleton } from "components";

const orderHeaders = [
  { key: 1, item: "Order ID" },
  { key: 2, item: "Transaction ID" },
  { key: 3, item: "Order Status" },
  { key: 4, item: "Quantity" },
  { key: 5, item: "Payment Amount" },
  { key: 6, item: "Region" },
  { key: 7, item: "Depot" },
  { key: 8, item: "Order Type" },
  { key: 9, item: "Sales Rep" },
  { key: 10, item: "Creation Date" },
  { key: 11, item: "Delivery Date" },
];

export type ItemType = {
  token: number;
  paymentStatus: string;
  region: string;
  depot: string;
  channel: string;
  type: string;
  outletID: number;
  transactionId: string;
  orderId: string;
  repsID: string;
  totalQuantity: number;
  method: string;
  totalAmount: number;
  deliveryStatus: string;
  status: string;
  deliveryDate?: any;
  delivery?: any;
  createdAt: string;
  customerInfo: { [k: string]: string | number };
  outletInfo: { [k: string]: string | number };
  products: {
    amount: number;
    image: string;
    productCode: string;
    tag: string;
    productName: string;
    quantity: number;
    _id: string;
    description: { [k: string]: number };
  }[];
};

export type MainItemType = {
  token: number;
  paymentStatus: string;
  region: string;
  depot: string;
  channel: string;
  type: string;
  outletID: number;
  transactionId: string;
  orderId: string;
  repsID: string;
  totalQuantity: number;
  method: string;
  totalAmount: number;
  deliveryStatus: string;
  status: string;
  deliveryDate?: any;
  delivery?: any;
  createdAt: string;
  customerInfo: { [k: string]: string | number };
  outletInfo: { [k: string]: string | number };
  products: {
    amount: number;
    image: string;
    productCode: string;
    tag: string;
    productName: string;
    quantity: number;
    _id: string;
    description: { [k: string]: number };
  }[];
};
export type csvType = {
  // token: number;
  paymentStatus: string;
  region: string;
  depot: string;
  channel: string;
  type: string;
  outletID: number;
  transactionid: string;
  orderid: string;
  repsID: string;
  quantity: number;
  price: number;
  status: string;
  method: string;
  createdAt: string;
};

type RouterOutletContextType = [
  toCsv: (table: {}[]) => string,
  setText: React.Dispatch<React.SetStateAction<string>>,
  setFileName: React.Dispatch<React.SetStateAction<string>>,
  value: string,
  startDate: string,
  endDate: string,
  filteredDepot: any,
  filteredRegion: any,
  filteredOrderType: string,
  search: string,
  filter: string
];
type OrdersProp = {
  status: "all" | "pending" | "awaiting" | "delivered" | "failed";
};
const Orders = ({ status }: OrdersProp) => {
  const { state, dispatch } = useAppContext();
  const [tableData, setTableData] = useState<ItemType[]>([]);
  const [pageParams, setPageParams] = useSearchParams();
  const [page, setPage] = useState(() => {
    let p = Number(pageParams.get("page"));
    return p > 0 ? p : 1;
  });
  const [pages, setPages] = useState<number>(0);
  const [limit, setLimit] = useState(10);
  const [
    toCsv,
    setText,
    setFileName,
    value,
    startDate,
    endDate,
    filteredDepot,
    filteredRegion,
    filteredOrderType,
    search,
    filter,
  ] = useOutletContext<RouterOutletContextType>();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch({ type: "data-loading" });

        const { data: d } = await OrderService.getOrders(
          status,
          search,
          filteredOrderType,
          filteredRegion,
          filteredDepot,
          startDate,
          endDate,
          limit,
          page
        );
        const { data, meta } = d.data;

        setTableData(data);
        setPages(meta.pageCount);
        setPageParams({ page: page.toString() });
      } catch (error) {
      } finally {
        dispatch({ type: "data-loaded" });
      }
    };
    fetchData();
  }, [limit, page, status, filter, search]);

  const handleNavigate = (item: ItemType) => {
    navigate("/dashboard/orders-management/order-details", { state: { item } });
  };

  return (
    <OrderWrap className="grid">
      {state?.isLoading ? (
        <TableSkeleton />
      ) : tableData.length === 0 ? (
        <EmptyList />
      ) : (
        <>
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  {orderHeaders.map((header) => (
                    <th key={header.key}>{header.item}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {tableData.map((item, index) => {
                  let date = new Date(item.createdAt);

                  let deliveryDate = new Date(item?.deliveryDate);

                  return (
                    <tr key={item?.createdAt}>
                      <td onClick={() => handleNavigate(item)}>
                        {item?.orderId}
                      </td>
                      <td>{item?.transactionId}</td>
                      <td
                        className={`status ${
                          item?.deliveryStatus == "Not Confirmed"
                            ? "Not_Confirmed"
                            : item?.deliveryStatus
                        }`}
                      >
                        {item?.deliveryStatus}
                      </td>
                      <td className="quantity">{item?.totalQuantity}</td>
                      <td>{formatMoney(item?.totalAmount)}</td>
                      <td>{item?.customerInfo?.region}</td>
                      <td>{item?.customerInfo?.depot}</td>
                      <td>
                        {item?.type && item.type?.toLowerCase() === "normal"
                          ? "Non-promo"
                          : item.type?.toLowerCase() === "promo"
                          ? "Promo"
                          : "NIL"}
                      </td>
                      <td>{item.customerInfo?.repname}</td>
                      <td className="table-date">
                        <p>{`${date.getDate()}/${
                          date.getMonth() + 1
                        }/${date.getFullYear()}`}</p>
                        <p>{`${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`}</p>
                      </td>
                      <td className="table-date">
                        {item?.deliveryDate ? (
                          <>
                            {" "}
                            <p>{`${deliveryDate.getDate()}/${
                              deliveryDate.getMonth() + 1
                            }/${deliveryDate.getFullYear()}`}</p>
                            <p>{`${deliveryDate.getHours()}:${deliveryDate.getMinutes()}:${deliveryDate.getSeconds()}`}</p>
                          </>
                        ) : (
                          <p> NIL</p>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {/* <Table
              headers={orderHeaders}
              items={tableData}
              renderItem={Order}
            /> */}
          </div>
          <Pagination
            limit={limit}
            setLimit={setLimit}
            page={page}
            pages={pages}
            setPage={setPage}
          />
        </>
      )}
    </OrderWrap>
  );
};

const Order = (item: MainItemType) => {
  const navigate = useNavigate();
  const handleNavigate = (item: MainItemType) => {
    navigate("/dashboard/orders-management/order-details", {
      state: { item, status: item?.deliveryStatus },
    });
  };
  let date = new Date(item.createdAt);

  let deliveryDate = new Date(item?.deliveryDate);

  return (
    <tr key={item?.createdAt}>
      <td onClick={() => handleNavigate(item)}>{item?.orderId}</td>
      <td>{item?.transactionId}</td>
      <td
        className={`status ${
          item?.deliveryStatus == "Not Confirmed"
            ? "Not_Confirmed"
            : item?.deliveryStatus
        }`}
      >
        {item?.deliveryStatus}
      </td>
      <td className="quantity">{item?.totalQuantity}</td>
      <td>{formatMoney(item?.totalAmount)}</td>
      <td>{item?.customerInfo?.region}</td>
      <td>{item?.customerInfo?.depot}</td>
      <td>
        {item?.type && item.type?.toLowerCase() === "normal"
          ? "Non-promo"
          : item.type?.toLowerCase() === "promo"
          ? "Promo"
          : "NIL"}
      </td>
      <td>{item.customerInfo?.repname}</td>
      <td className="table-date">
        <p>{`${date.getDate()}/${
          date.getMonth() + 1
        }/${date.getFullYear()}`}</p>
        <p>{`${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`}</p>
      </td>
      <td className="table-date">
        {item?.deliveryDate ? (
          <>
            {" "}
            <p>{`${deliveryDate.getDate()}/${
              deliveryDate.getMonth() + 1
            }/${deliveryDate.getFullYear()}`}</p>
            <p>{`${deliveryDate.getHours()}:${deliveryDate.getMinutes()}:${deliveryDate.getSeconds()}`}</p>
          </>
        ) : (
          <p> NIL</p>
        )}
      </td>
    </tr>
  );
};
const OrderWrap = styled.div`
  .table-container {
    min-height: calc(100vh - 370px);
  }

  .table-date p:nth-child(2) {
    font-size: 11px;
  }
  .quantity {
    text-align: center;
  }
  .status {
    font-weight: 600;
  }
  .status.CONFIRMED {
    color: #198155;
  }
  .status.pending {
    color: #834d1d;
  }
  .status.Not_Confirmed {
    color: #834d1d;
  }
  .status.PARTIAL {
    color: #221f58;
  }
  .status.failed {
    color: #d3180c;
  }
  .status.awaiting {
    color: #221f58;
  }
  .status.delivered {
    color: #007731;
  }
  .status.pending {
    color: #834d1d;
  }
  .method {
    font-weight: 600;
    text-transform: capitalize;
  }
  .method.cash {
    color: #060607;
  }
  .method.wallet {
    color: #995a22;
  }
`;
const ContentWrapper = styled.div`
  height: 100%;
  overflow-y: scroll;
  position: relative;
  padding-top: 3em;

  .chart-container {
    padding: 20px 0;
  }

  .chart-container {
    padding: 20px 0;
  }

  .orders-total {
    width: 350px;
    height: 180px;
    background-color: #fff;
    margin: 0 auto;
    border-radius: 8px;
    padding: 20px 25px;
  }
  .section-icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: grid;
    place-items: center;
    margin-top: 10px;

    img {
      object-fit: contain;
    }
  }
  .summation {
    display: flex;
    flex-direction: column;

    .amount-intro {
      margin-top: 15px;
    }
    .total-amount {
      margin-top: auto;
      color: #022f1a;
      font-size: 22px;
      font-weight: 600;
      margin-top: 15px;
    }
  }
  .quantity {
    text-align: center;
  }
  .status {
    font-weight: 600;
  }
  .status.CONFIRMED {
    color: #198155;
  }
  .status.pending {
    color: #834d1d;
  }
  .status.Not_Confirmed {
    color: #834d1d;
  }
  .status.PARTIAL {
    color: #221f58;
  }
  .status.failed {
    color: #d3180c;
  }
  .status.awaiting {
    color: #221f58;
  }
  .status.delivered {
    color: #007731;
  }
  .status.pending {
    color: #834d1d;
  }
  .method {
    font-weight: 600;
    text-transform: capitalize;
  }
  .method.cash {
    color: #060607;
  }
  .method.wallet {
    color: #995a22;
  }
`;

const DiscountTable = styled.div`
  tbody tr {
    background-color: hsla(var(--clr-light));
    border-top: 8px solid #ececec;
    position: relative;
  }
  & :first-child {
    // text-decoration: none !important;
    // cursor: auto !important;
  }

  .prod {
    display: flex;
    align-items: center;

    span:last-child {
      margin-left: 6px;
    }
  }

  .product-img {
    width: 30px;
    height: 30px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .three-dots {
    display: inline-grid;
    place-items: center;
    width: 100%;
  }
  .depot {
    position: relative;
  }
  .depotDisplay {
    position: absolute;
    background: #f0f0f0;
    width: 250px;
    border-radius: 6px;
    padding: 5px 0;
    visibility: hidden;
    z-index: 3;
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    word-wrap: break-word;
    justify-content: space-around;
    row-gap: 5px;
  }
  .depot:hover .depotDisplay {
    visibility: visible;
  }
`;

export default Orders;
