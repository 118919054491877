import { useEffect, useState, Dispatch, SetStateAction } from "react";
import styled from "styled-components";
import { Checkbox } from "components/CheckBox";
import { v4 as uuidv4 } from "uuid";

interface InlineProps {
  setQuestionsForms: Dispatch<SetStateAction<any>>;
  setIsModal: Dispatch<SetStateAction<boolean>>;
  handleCloseButton: () => void;
  selectedId?: string;
  questionsForms?: any;
  setSelectedId: Dispatch<SetStateAction<string>>;
}

const InlineForm: React.FC<InlineProps> = ({
  handleCloseButton,
  setQuestionsForms,
  setIsModal,
  selectedId,
  questionsForms,
  setSelectedId,
}) => {
  const [title, setTitle] = useState("");
  const [required, setRequired] = useState(false);

  const handleInline = (e: any) => {
    e.preventDefault();

    if (selectedId) {
      const index = questionsForms.findIndex(
        (obj: any) => obj.id === selectedId
      );

      if (index !== -1) {
        setQuestionsForms((prev: any) => {
          const newArray = [...prev];
          newArray[index] = { ...newArray[index], title, isRequired: required };
          return newArray;
        });
      }
      setSelectedId("");
      handleCloseButton();
    } else {
      const obj = {
        id: uuidv4(),
        optionType: "INPUT",
        title,
        isRequired: required,
      };
      setQuestionsForms((prev: any) => {
        return [...prev, obj];
      });
      setSelectedId("");
      handleCloseButton();
    }
  };

  useEffect(() => {
    if (selectedId) {
      const filteredObj = questionsForms.filter(
        (data: any) => data.id === selectedId
      );
      filteredObj.forEach((data: any) => {
        setTitle(data.title);
        setRequired(data.isRequired);
      });
    }
  }, [selectedId]);

  return (
    <Form>
      <form className="form-container" onSubmit={handleInline}>
        <div className="form-control">
          <div className="input-bottom">
            <input
              required
              placeholder="Type your question"
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div className="btn-container">
            {!selectedId && (
              <button className="btns btn-outline" onClick={handleCloseButton}>
                Delete
              </button>
            )}

            <button className="btns">{selectedId ? "Edit" : "Create"}</button>
          </div>
          <div className="container_bottom">
            <Checkbox
              id="checkbox"
              checked={required}
              onChange={(e: any) => setRequired(e.target.checked)}
            />
            <label htmlFor="checkbox">compulsory</label>
          </div>
        </div>
      </form>
    </Form>
  );
};

const Form = styled.div`
  .form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
  }
  .form-control {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  .form-control h4 {
    color: #db8130;
    font-weight: 600;
    cursor: pointer;
    font-size: 0.9rem;
  }
  }
  .input-bottom input {
    width: 100%;
    background: #fff;
    height: 40px;
    padding: 5px;
    border: none;
    border-bottom: 2px solid #555;
    outline: none;
    color: #022f1a;
    font-weight: 600;
  }
  .btn-container {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 15px
  }
  .btns {
    background: #022f1a;
    color: #fff;
    padding: 10px 35px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 600;
  }

  .btn-outline {
    border: 1px solid #022f1a;
    background: #fff;
    color: #022f1a;
  }
  .btn:disabled {
    background: #CCD5D1;
    cursor: auto;
  }
  .container_bottom {
    display: flex;
    align-center: center;
    gap: 10px;
  }
  .container_bottom label {
    font-size: 0.9rem;
  }
`;

export default InlineForm;
