import { useEffect, useState, Dispatch, SetStateAction } from "react";
import styled from "styled-components";
import { ItemType } from "../CustomerSupportManagement";

interface SurveyTypeProps {
  setSurveyForm: Dispatch<SetStateAction<{}>>;
  setFormView: Dispatch<SetStateAction<string>>;
  item?: ItemType;
}

const SurveyTypeForm: React.FC<SurveyTypeProps> = ({
  setSurveyForm,
  setFormView,
  item,
}) => {
  const [surveyType, setSurveyType] = useState("");

  const handleTypeForm = (e: any) => {
    e.preventDefault();
    setSurveyForm((prev: any) => {
      return {
        ...prev,
        surveyType,
      };
    });

    if (surveyType === "feature flag" || surveyType === "feature-flag") {
      setFormView("featureTitle");
    } else {
      console.log(surveyType);
      setFormView("duration");
    }
  };

  useEffect(() => {
    if (item?.surveyType) {
      if (item?.surveyType === "feature-flag") {
        setSurveyType("feature flag");
      } else {
        setSurveyType(item?.surveyType);
      }
    }
  }, [item]);
  return (
    <Form>
      <form className="form-container" onSubmit={handleTypeForm}>
        <h2>Type of Survey</h2>
        <div className="form-control">
          <div className="int-single">
            <select
              value={surveyType}
              onChange={(e) => setSurveyType(e.target.value)}
            >
              <option value=" disabled selected  hidden">
                Select Survey Type
              </option>
              <option value="promotional survey">Promotional Survey</option>
              <option value="product launch">Product Launch</option>
              <option value="feedback">Feedback</option>
              <option value="feature flag">Feature Flag</option>
            </select>
          </div>
          <div className="btn-container">
            <button
              disabled={
                surveyType === "promotional survey"
                  ? false
                  : surveyType === "product launch"
                  ? false
                  : surveyType === "feedback"
                  ? false
                  : surveyType === "feature flag"
                  ? false
                  : true
              }
            >
              Select
            </button>
          </div>
        </div>
      </form>
    </Form>
  );
};

const Form = styled.div`
  .form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .form-container h2 {
    color: #022f1a;
    font-weight: 500;
    margin-bottom: 30px;
  }
  .create {
    display: none;
  }
  .form-control {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  .int-group {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .int-group select {
    width: 48%;
    background: #fff;
    height: 40px;
    padding: 5px;
    border: 2px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
  }
  .int-single select {
    width: 100%;
    background: #fff;
    height: 40px;
    padding: 5px;
    border: 2px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
  }
  .btn-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .btn-container button {
    background: #022f1a;
    width: 200px;
    color: #fff;
    padding: 10px 35px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 600;
  }
  .btn-container button:disabled {
    background: #ccd5d1;
    cursor: auto;
  }
  .int-single input[type="file"] {
    position: absolute;
    width: 100%;
    height: 100px;
    z-index: 102;
    opacity: 0;
  }
  .int-single label {
    display: block;
    color: #888;
    font-size: 0.9rem;
    font-weight: 600;
    margin-bottom: 15px;
  }
  .int-single p {
    font-size: 0.8rem;
    color: #999;
  }
  .int-single ._label {
    background: #ececec;
    width: 100%;
    height: 50px;
    border-top: 2px dashed #d4d4d4;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .lrg {
    height: 100px !important;
  }
  .int-single ._label p {
    font-size: 0.9rem;
    color: #022f1a;
    cursor: pointer;
  }
`;

export default SurveyTypeForm;
