import Api from "./Api";

const Services = {
  addSurvey(formData: any) {
    return Api().post("survey/create", formData);
  },
  getAllSurvey(
    limit: number,
    page: number,
    specificDate: string,
    startDate: string,
    endDate: string
  ) {
    var params = {
      ...(Boolean(specificDate || startDate || endDate) && { type: "filter" }),
      ...(Boolean(specificDate) && { specificDate }),
      ...(Boolean(startDate) && { startDate }),
      ...(Boolean(endDate) && { endDate }),
      limit,
      page,
    };

    return Api().get("survey", { params });
  },

  getSingleSurvey(id: any) {
    return Api().get(`survey/${id}`);
  },
  addQuestions(id: string | undefined, data: any) {
    let formData = {
      questions: data,
    };
    return Api().patch(`survey/add-questions/${id}`, formData);
  },
  updateSurveyStatus(id: string, params: any) {
    return Api().put(`survey/update-status/${id}`, params);
  },
  getSurveyAnswers(
    id: any,
    limit: number,
    page: number,
    specificDate: string,
    startDate: string,
    endDate: string
  ) {
    var params = {
      ...(Boolean(specificDate || startDate || endDate) && { type: "filter" }),
      ...(Boolean(specificDate) && { specificDate }),
      ...(Boolean(startDate) && { startDate }),
      ...(Boolean(endDate) && { endDate }),
      limit,
      page,
    };
    let body = {
      id,
    };
    return Api().post(`survey/all/answers`, body, { params });
  },
};

export default Services;
