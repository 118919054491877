import ContentLayout from "../../../layouts/ContentLayout";
import { Toggle } from "../../../components";
import avatar from "../../../assets/images/placeholder.png";
import backArrow from "../../../assets/svg/arrow-left.svg";
import React, { useState } from "react";
import styled from "styled-components";
import InviteUserForm from "./InviteUserForm";
import { Profile } from "./Profile";
import { Link } from "react-router-dom";

interface SettingsProps {}
const Settings: React.FC<SettingsProps> = ({}) => {
  const [isSwitch, setIsSwitch] = useState(false);
  const [fileSrc, setFileSrc] = useState(avatar);
  const [isInvite, setIsInvite] = useState(false);

  return (
    <>
      <ContentLayout
        titleText="Account Settings"
        subText="User Profile"
        // component={<Toggle isToggle={isSwitch} onToggle={setIsSwitch} />}
      >
        <Back className="back">
          <Link to="../dashboard/settings">
            <img src={backArrow} alt="back-arrow" />
            <p>Back</p>
          </Link>
        </Back>
        <Main className="grid container">
          <Profile {...{ fileSrc, setFileSrc }} />
          {isInvite && <InviteUserForm {...{ setIsInvite }} />}
        </Main>
      </ContentLayout>
    </>
  );
};

const Main = styled.main.attrs(() => {})`
  place-items: center;
  margin: 0 auto;

  .overlay {
    z-index: 9;
    height: 100vh;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.6);
    width: 100vw;
    inset: 0;
    transition: transform 600ms ease-in-out;
  }
  .dialog {
    --flow-space: 4rem;
    border-radius: 5px;
    position: absolute;
    z-index: 999;
    background-color: hsla(var(--clr-light));
    padding: 2em;
    width: 40%;
    & > *:first-child {
      --flow-space: 0.5rem;
    }
    form {
      --flow-space: 1.5rem;
      input {
        outline: transparent solid 2px;
        width: 100%;
        height: 25px;
        padding-inline: 0.5em;
        &::placeholder {
          color: hsla(var(--clr-black-shade-100));
          opacity: 1;
        }
      }
      select {
        background: none;
        border: 1px solid hsla(var(--clr-black-tint-200));
        width: 100%;
        height: 25px;
        padding-inline: 0.5em;
        color: hsla(var(--clr-black-shade-100));
      }
      .btn-group {
        justify-content: center;
        button {
          width: unset;
        }
      }
    }
  }
`;

const Back = styled.div`
  display: flex;
  a {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 1em 0 0 4em;
    color: #333;

    & p {
      font-weight: 600;
    }
  }
`;

export default Settings;
