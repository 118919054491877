import { useEffect, useState, Dispatch, SetStateAction } from "react";
import styled from "styled-components";
import { BackDrop } from "components/BackDrop";
import SurveyTitleForm from "./SurveyTitleForm";
import SurveyTypeForm from "./SurveyTypeForm";
import SurveyDurationForm from "./SurveyDurationForm";
import FeatureTitleForm from "./FeatureTitleForm";
import FeatureCtaForm from "./FeatureCtaForm";

interface CreateSurveyFormProps {
  isModal: boolean;
  setIsModal: Dispatch<SetStateAction<boolean>>;
}

const CreateSurveyForm: React.FC<CreateSurveyFormProps> = ({
  setIsModal,
  isModal,
}) => {
  const [surveyForm, setSurveyForm] = useState({});
  const [formView, setFormView] = useState("title");

  const handleModalClose = () => {
    setIsModal(false);
    // setIsReset(false);
  };

  const handleCloseButton = () => {
    setIsModal(false);
    // setIsReset(true);
    setFormView("title");
  };

  useEffect(() => {
    console.log("////////", surveyForm);
  }, [surveyForm]);

  return (
    <BackDrop
      isOpen={isModal}
      handleClose={handleModalClose}
      handleCloseButton={handleCloseButton}
    >
      {formView === "title" && (
        <SurveyTitleForm
          setSurveyForm={setSurveyForm}
          setFormView={setFormView}
        />
      )}
      {formView === "type" && (
        <SurveyTypeForm
          setSurveyForm={setSurveyForm}
          setFormView={setFormView}
        />
      )}
      {formView === "featureTitle" && (
        <FeatureTitleForm
          setSurveyForm={setSurveyForm}
          setFormView={setFormView}
        />
      )}
      {formView === "featureCta" && (
        <FeatureCtaForm
          setSurveyForm={setSurveyForm}
          setFormView={setFormView}
        />
      )}
      {formView === "duration" && (
        <SurveyDurationForm
          surveyForm={surveyForm}
          handleCloseButton={handleCloseButton}
        />
      )}
    </BackDrop>
  );
};

export default CreateSurveyForm;
