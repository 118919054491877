import React, { Dispatch, SetStateAction } from "react";
import { useAppContext } from "context/AppContext";
import styled from "styled-components";

interface ToggleProps {
  isToggle: boolean;
  onToggle: Dispatch<SetStateAction<boolean>>;
}

export const Toggle: React.FC<ToggleProps> = ({ isToggle, onToggle }) => {
  const { state } = useAppContext();
  const userRoles: string[] = state?.user?.roles || [];

  return (
    <>
      {userRoles.includes("SUPER_ADMIN") && (
        <Label>
          <div className="menu">
            <div className="flex">
              <p className="fs-200 fw-400">Profile</p>
              <p className="fs-200 fw-400">Admin user</p>
            </div>
          </div>
          <input
            type="checkbox"
            checked={isToggle}
            onChange={() => onToggle(!isToggle)}
          />
          <span className="pointer" />
        </Label>
      )}
      {/* <Label>
      <div className="menu">
        <div className="flex">
          <p className="fs-200 fw-400">Profile</p>
          <p className="fs-200 fw-400">Admin user</p>
        </div>
      </div>
      <input
        type="checkbox"
        checked={isToggle}
        onChange={() => onToggle(!isToggle)}
      />
      <span className="pointer" />
    </Label> */}
    </>
  );
};

const Label = styled.label`
  position: relative;
  display: inline-block;
  width: 292px;
  height: 50px;
  border-radius: 4px;
  background: hsla(var(--clr-gray));

  .menu {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    color: white;
    z-index: 9;
    padding: 0 0.5em;

    div {
      justify-content: space-around;
      align-items: center;
      height: 100%;
    }

    p {
      cursor: pointer;
    }
  }

  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + span:before {
      transform: translateX(140px);
    }
  }

  span {
    position: absolute;
    inset: 0;
  }
  span:before {
    position: absolute;
    content: "";
    width: 139px;
    height: 42px;
    left: 6px;
    bottom: 4px;
    border-radius: 5px;
    background-color: hsla(var(--clr-blue-shade-400));
    transition: 0.4s;
  }
`;
