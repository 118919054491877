import React, { useEffect, useState } from "react";
import ContentLayout from "../../../layouts/ContentLayout";
import { useParams, useSearchParams } from "react-router-dom";
import FinanceService from "../../../services/FinanceService";
import styled from "styled-components";
import { formatMoney } from "../../../utils/formatMoney";
import { useAppContext } from "context/AppContext";
import GetBankStatement from "../GetBankStatement";
import {
  Table,
  TableSkeleton,
  EmptyList,
  TableTab,
  Pagination,
} from "components";

interface OutletDetailsProps {}

type ItemType = {
  bankname: string;
  Amount: number;
  createdAt: string;
  transactionType: string;
  Narration: string;
  transactionId: string;
};

type CardType = {
  walletData: {
    bonusBalance: { point: number; volume: number };
    availableCashBalance: string;
    ledger: string;
  };
  outletData: {
    repsId: string;
    name: string;
    depot: string;
    region: string;
    outletname: string;
  };
};

const header = [
  { key: 1, item: "Transaction ID" },
  { key: 2, item: "Transaction Type" },
  { key: 3, item: "Narration" },
  { key: 4, item: "Amount" },
  { key: 5, item: "Date" },
];

export const OutletDetails: React.FC<OutletDetailsProps> = ({}) => {
  const [tableData, setTableData] = useState<ItemType[]>([]);
  const [cardData, setCardData] = useState<CardType>({} as CardType);
  const [willFilter, setWillFilter] = useState("");
  const [bySpecific, setBySpecific] = useState("");
  const [byCustomStart, setByCustomStart] = useState("");
  const [byCustomEnd, setByCustomEnd] = useState("");
  const [pageParams, setPageParams] = useSearchParams();
  const [page, setPage] = useState(() => {
    let p = Number(pageParams.get("page"));
    return p > 0 ? p : 1;
  });
  const [pages, setPages] = useState<number>(0);
  const [limit, setLimit] = useState(10);
  const { outletId } = useParams();
  const { state, dispatch } = useAppContext();

  const renderDetail = (item: ItemType) => {
    let date = new Date(item.createdAt);
    return (
      <tr key={item.transactionId}>
        <td>{item.transactionId}</td>
        <td
          className={`transaction-type ${
            item.transactionType === "debit transfer" ? "debit" : "credit"
          }`}
        >
          {item.transactionType}
        </td>
        <td className="transaction-narration">
          <span>{item.Narration.substring(0, 30)}...</span>
          <span className="narration-full">{item.Narration}</span>
        </td>
        <td>{formatMoney(item.Amount)}</td>
        <td className="table-date">
          <p>{`${date.getDate()}/${
            date.getMonth() + 1
          }/${date.getFullYear()}`}</p>
          <p>{`${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`}</p>
        </td>
      </tr>
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch({ type: "data-loading" });
        const { data: d } = await FinanceService.getIndividualOutletDetails(
          outletId!,
          limit,
          page,
          bySpecific,
          byCustomStart,
          byCustomEnd
        );
        const {
          data: { data: wData },
        } = await FinanceService.getIndividualOutletWallet(outletId!);
        const { data, meta } = d.data;

        if (d.data && d.data.length === 0) setTableData(d.data);
        if (!data) return;
        setCardData(wData);
        setTableData(data);
        setPages(meta.pageCount);
        setPageParams({ page: page.toString() });
      } catch (error) {
      } finally {
        dispatch({ type: "data-loaded" });
      }
    };

    fetchData();
  }, [page, limit, outletId, willFilter, dispatch]);

  return (
    <ContentLayout
      titleText="Finance Management"
      subText={
        <a
          href="https://datastudio.google.com/u/0/reporting/1huaD4958Ku4DSHATkhX_rz0_tOwZUS5Q/page/p_lzm2m0d9sc"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "underline" }}
        >
          Dynamics sales report
        </a>
      }
      component={<GetBankStatement />}
    >
      <DepotDetailsWrap>
        <div className="grid chart-container">
          <div className="grid card details">
            <div className="flex">
              <span className="icon-wrap">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#022f1a"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                  <circle cx="8.5" cy="7" r="4"></circle>
                  <polyline points="17 11 19 13 23 9"></polyline>
                </svg>
              </span>
              <h3 className="text-black-100">
                {cardData?.outletData?.outletname}
              </h3>
            </div>
            <table className="details-table">
              <tbody className="details-tbody">
                <tr className="details-tr">
                  <td className="details-td text-black-100 fw-600">Rep ID:</td>
                  <td className="details-td">{cardData?.outletData?.repsId}</td>
                </tr>
                <tr className="details-tr">
                  <td className="details-td text-black-100 fw-600">
                    Depot ID:
                  </td>
                  <td className="details-td">{cardData.outletData?.depot}</td>
                </tr>
                <tr className="details-tr">
                  <td className="details-td text-black-100 fw-600">Region:</td>
                  <td className="details-td">{cardData?.outletData?.region}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="grid card balance">
            <div className="flex">
              <span className="icon-wrap">
                <svg
                  width="48"
                  height="48"
                  viewBox="0 0 48 48"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="24" cy="24" r="24" fill="#CCD5D1" />{" "}
                  <g clipPath="url(#clip0_1010_7938)">
                    <path
                      d="M13.0312 22H35.7188M15.0938 16H33.6563C34.7953 16 35.7188 16.8954 35.7188 18V30C35.7188 31.1046 34.7953 32 33.6563 32H15.0938C13.9547 32 13.0312 31.1046 13.0312 30V18C13.0312 16.8954 13.9547 16 15.0938 16Z"
                      stroke="#022f1a"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1010_7938">
                      <rect
                        width="24.75"
                        height="24"
                        fill="white"
                        transform="translate(12 12)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </span>

              <h3>Wallet Details</h3>
            </div>
            <table className="details-table">
              <tbody className="details-tbody">
                <tr className="details-tr">
                  <td className="details-td text-black-100 fw-600">
                    Bonus balance point:
                  </td>
                  <td className="details-td">
                    {cardData?.walletData?.bonusBalance?.point} points
                  </td>
                </tr>
                <tr className="details-tr">
                  <td className="details-td text-black-100 fw-600">
                    Bonus balance volume:
                  </td>
                  <td className="details-td">
                    {cardData?.walletData?.bonusBalance?.volume} points
                  </td>
                </tr>
                <tr className="details-tr">
                  <td className="details-td text-black-100 fw-600">
                    Available balance:
                  </td>
                  <td className="details-td">
                    {cardData?.walletData?.availableCashBalance}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="data-container">
          <TableTab
            data={tableData}
            file={"details.csv"}
            text={`${outletId} transaction details`}
            setBySpecific={setBySpecific}
            setByCustomStart={setByCustomStart}
            setByCustomEnd={setByCustomEnd}
            setWillFilter={setWillFilter}
          />

          {state?.isLoading ? (
            <TableSkeleton />
          ) : tableData.length === 0 ? (
            <EmptyList />
          ) : (
            <>
              <div className="table-container">
                <Table
                  headers={header}
                  items={tableData}
                  renderItem={renderDetail}
                />
              </div>
              <Pagination
                limit={limit}
                setLimit={setLimit}
                page={page}
                pages={pages}
                setPage={setPage}
              />
            </>
          )}
        </div>
      </DepotDetailsWrap>
    </ContentLayout>
  );
};

const DepotDetailsWrap = styled.div`
  height: 100%;
  overflow-y: scroll;
  position: relative;

  .chart-container {
    grid-template-columns: repeat(5, 1fr);
    padding: 10px 0;
    place-items: center;
    animation: fadeIn ease-in 1s;

    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opaacity: 1;
      }
    }

    .icon-wrap {
      width: 45px;
      height: 45px;
      border-radius: 50%;
      background-color: #ccd5d1;
      display: grid;
      place-items: center;
    }
  }

  .chart-container > *:first-child {
    grid-column: 2;
  }
  .chart-container > *:nth-child(2) {
    grid-column: 3;
  }

  .card {
    padding: 1.5em 2.5em;
    margin: 2.5em;
    background-color: hsla(var(--clr-light));
    width: 400px;
  }

  .details {
    h3 {
      font-weight: var(--fw-600);
      color: hsla(var(--clr-black-shade-100));
    }
  }

  .details > *:first-child {
    align-items: center;
  }
  .balance > *:first-child {
    align-items: center;
  }

  .wallet {
    flex-direction: column;
    flex-wrap: wrap;
  }

  .data-container {
    /* overflow: scroll; */
  }

  .transaction-type {
    text-decoration: initial;
    cursor: auto;
    font-weight: 600;
  }

  .transaction-narration {
    position: relative;
    cursor: pointer;

    .narration-full {
      position: absolute;
      top: 10%;
      left: 0;
      background-color: #fff;
      border-radius: 5px;
      width: 70%;
      padding: 8px;
      font-size: 12px;
      letter-spacing: 1px;
      display: none;
    }
  }
  .transaction-narration:hover .narration-full {
    display: block;
  }
  .transaction-type.debit {
    color: #d3180c;
  }
  .transaction-type.credit {
    color: #198155;
  }

  .details-table {
    font-size: var(--fs-400);
    border-collapse: collapse;
  }

  .details-tr {
    background: none;
    display: block;
  }

  .details-tr > *:nth-child(2) {
    padding-left: 0.5em;
  }

  .details-td {
    padding: 0.5em 0;
    text-align: left;
  }
  .details-td:first-child {
    text-decoration: none;
    cursor: unset;
  }
`;
