import { Dispatch, useEffect, useState } from "react";
import toastr from "toastr";
import { useAppContext } from "context/AppContext";
import ContentLayout from "../../layouts/ContentLayout";
import styled from "styled-components";
import CreateQuestion from "./CreateQuestions/CreateQuestion";
import SurveyService from "../../services/SurveryService";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import ButtonSpinner from "components/Loaders/ButtonSpinner";
import { ItemType } from "./CustomerSupportManagement";

const SurveyQuestions = () => {
  const [questionsForms, setQuestionsForms] = useState([]);
  const [created, setCreated] = useState(false);
  const { state, dispatch } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const { surveyId } = useParams();
  const location: any = useLocation();
  const item = location.state?.item;
  const surveyType = location.state?.surveyType;

  const navigate = useNavigate();

  const handleSendSurvey = async () => {
    let formData = questionsForms.map((data: any) => {
      return {
        title: data.title,
        options: data.options,
        isRequired: data.isRequired,
        optionType: data.optionType,
      };
    });

    try {
      dispatch({ type: "action-loading" });
      setIsLoading(true);
      const {
        data: { statusCode, data },
      } = await SurveyService.addQuestions(surveyId, formData);

      if (statusCode === 200) {
        setCreated(true);
        toastr.success("Question(s) successfully added");
        navigate("../dashboard/customer-management");
        setQuestionsForms([]);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch({ type: "action-loaded" });
      setIsLoading(false);
    }
  };

  return (
    <ContentLayout
      titleText="Customer Support"
      subText="Manage Customer"
      component={
        <SendButton {...{ handleSendSurvey, questionsForms, isLoading }} />
      }
    >
      <CreateQuestion
        setQuestionsForms={setQuestionsForms}
        questionsForms={questionsForms}
        item={item}
        surveyType={surveyType}
      />
    </ContentLayout>
  );
};

const SendButton = ({
  handleSendSurvey,
  questionsForms,
  isLoading,
}: {
  handleSendSurvey: () => void;
  questionsForms: any;
  isLoading: any;
}) => (
  <Header className="header_btn">
    {questionsForms.length >= 1 && (
      <button
        className="btn btn-default pointer fs-100 fw-600 bt button"
        onClick={handleSendSurvey}
      >
        {isLoading ? <ButtonSpinner /> : "Send Survey"}
      </button>
    )}
  </Header>
);

const ContentWrapper = styled.div`
  height: 100%;
  overflow-y: scroll;
  position: relative;
  padding-top: 5em;

  .chart-container {
    padding: 20px 0;
  }
`;
const ButtonContainer = styled.div`
  margin-bottom: 20px;
  padding-left: 30px;

  .button {
    background: #db8130;
    color: #160d05;
    // padding: 8px 35px;
    width: 50px;
    height: 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 600;
  }
`;

const Header = styled.div`
  display: flex;
  align-center: center;
  gap: 15px;
  position: relative;

  .bt {
    // padding: 0.3em 1em;
    width: 100px;
    height: 20px;
  }
`;

export default SurveyQuestions;
