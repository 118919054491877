import React, { lazy, Suspense, useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "@fortawesome/fontawesome-free/css/all.min.css";
import PrivateRoute from "components/PrivateRoute/PrivateRoute";

import SignIn from "./pages/Auth/signin";
import "./App.css";
import {
  ChangePassword,
  ChangePasswordSuccess,
} from "./pages/Auth/ChangePassword";
import {
  ForgotPassword,
  RecoverySent,
  ResetPassword,
  ForgotPasswordSuccess,
} from "pages/Auth/forgotPassword";
//import FinanceManagement from './pages/FinanceManagement';
import DashboardLayout from "./layouts/DashboardLayout";
import Authenticated from "./components/PrivateRoute/Authenticated";
import { useAppContext } from "./context/AppContext";
import AuthenticationService from "./services/AuthenticationService";
import ContentLayout from "./layouts/ContentLayout";
import BaseLoader from "./components/Loaders/BaseLoader";
import Regions from "./pages/FinanceManagement/Regions/Regions";
import { Depot } from "./pages/FinanceManagement/Depot/AllDepots";
import { Reps } from "./pages/FinanceManagement/Reps/Reps";
import { Outlet } from "./pages/FinanceManagement/Outlets/Outlet";
import SingleRegion from "./pages/FinanceManagement/Regions/SingleRegion";
import DepotDetails from "./pages/FinanceManagement/Depot/DepotDetails";
import { OutletDetails } from "pages/FinanceManagement/Outlets/OutletDetails";
import { RepsDetails } from "pages/FinanceManagement/Reps/RepsDetails";
import OrdersManagement from "pages/OrderManagement";
import OrderDetails from "pages/OrderManagement/OrderDetails";
import {
  Settings,
  ProfileSettings,
  AdminSettings,
  AppDistribution,
  ConfigurationSettings,
} from "pages/Settings";

import CompleteRegistration from "pages/Auth/signup/CompleteRegistration";
import {
  CustomerSupportManagement,
  SurveyQuestions,
  SurveyDetails,
} from "pages/CustormerSupport";
import { ProductsManagement, AllSkus } from "pages/ProductManagement";
import { AdsManagement, DiscountOffer } from "pages/AdsManagement";
import { ShopKeeperExport } from "pages/Reconciliation";
import { ShopKeeperReport } from "pages/ReconciliationReport";
import { ReportManagement } from "pages/ReportManagement";
// import { OutletManagement } from "pages/OutletManagement";
import OutletsManagement from "pages/OutletManagement/OutletsManagement";
import { GrandOutlet } from "pages/OutletManagement/GrandOutlet";
import OutletHistory from "pages/OutletManagement/OutletHistory";
import HistoryDetails from "pages/OutletManagement/HistoryDetails";
import MobiletraderOutlet from "pages/OutletManagement/MobiletraderOutlet";
import ReodaOutlet from "pages/OutletManagement/ReodaOutlet";
import ActiveOutlet from "pages/OutletManagement/ActiveOutlet";
import { AllOrders } from "pages/OrderManagement/AllOrders";
import Orders from "pages/OrderManagement/Orders";

const FinanceManagement = lazy(() => import("./pages/FinanceManagement"));

function App() {
  const { dispatch, state } = useAppContext();
  const userRoles = state?.user?.roles;

  useEffect(() => {
    const getUser = async () => {
      try {
        dispatch({ type: "user-loading" });
        const {
          data: {
            data: { user },
          },
        } = await AuthenticationService.getUser();

        if (user === null) {
          throw Error("user null");
        }
        const userData = {
          email: user.email,
          firstName: user.firstName,
          lastName: user.lastName,
          roles: user.roles,
          location: user.location || "NA",
          region: user.region || "NA",
        };
        dispatch({ type: "set-user", payload: userData });
      } catch (error: any) {
        dispatch({ type: "on-login-error", payload: "" });
      }
    };
    if (localStorage.getItem("token")) {
      getUser();
    }
  }, [dispatch]);
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<SignIn />} />
        <Route path="resetPassword/:token" element={<ChangePassword />} />
        <Route path="change-success" element={<ChangePasswordSuccess />} />
        <Route path="change-password" element={<ForgotPassword />} />
        <Route path="recovery-sent/:email" element={<RecoverySent />} />
        <Route path="reset/password/:token" element={<ResetPassword />} />
        <Route path="forgot-success" element={<ForgotPasswordSuccess />} />
        <Route
          path="completeRegistration/:token"
          element={<CompleteRegistration />}
        />
        <Route element={<Authenticated />}>
          <Route element={<DashboardLayout />}>
            {/* Finance MAnagement Routes  */}
            <Route
              path="dashboard/finance"
              element={
                <PrivateRoute>
                  <Suspense fallback={<BaseLoader />}>
                    <FinanceManagement />
                  </Suspense>
                </PrivateRoute>
              }
            >
              <Route index element={<Navigate to="outlet" />} />
              <Route path="regions" element={<Regions />} />
              <Route path="depot" element={<Depot />} />
              <Route path="reps" element={<Reps />} />
              <Route path="outlet" element={<Outlet />} />
            </Route>

            <Route
              path="dashboard/finance/regions/:regionId"
              element={
                <PrivateRoute>
                  <SingleRegion />
                </PrivateRoute>
              }
            />
            <Route
              path="dashboard/finance/depot/:depotId"
              element={
                <PrivateRoute>
                  <DepotDetails />
                </PrivateRoute>
              }
            />
            <Route
              path="dashboard/finance/outlet/:outletId"
              element={
                <PrivateRoute>
                  <OutletDetails />
                </PrivateRoute>
              }
            />
            <Route
              path="dashboard/finance/reps/:repsId"
              element={
                <PrivateRoute>
                  <RepsDetails />
                </PrivateRoute>
              }
            />

            {/* Orders Management Routes */}

            <Route
              path="dashboard/orders-management"
              element={
                <PrivateRoute>
                  <Suspense fallback={<BaseLoader />}>
                    <OrdersManagement />
                  </Suspense>
                </PrivateRoute>
              }
            >
              <Route index element={<Navigate to="all-orders" />} />
              <Route
                path="all-orders"
                element={<Orders key="all" status="all" />}
              />
              <Route
                path="pending-orders"
                element={<Orders key="pending" status="pending" />}
              />
              <Route
                path="awaiting-orders"
                element={<Orders key="awaiting" status="awaiting" />}
              />
              <Route
                path="delivered-orders"
                element={<Orders key="delivered" status="delivered" />}
              />
              <Route
                path="failed-orders"
                element={<Orders key="failed" status="failed" />}
              />
            </Route>
            <Route
              path="dashboard/orders-management/order-details"
              element={
                <PrivateRoute>
                  <OrderDetails />
                </PrivateRoute>
              }
            />

            {/* <Route path="dashboard/orders-management">
              <Route index element={<Navigate to="all-orders" />} />
              <Route
                path="all-orders"
                element={
                  <PrivateRoute>
                    <AllOrders />
                  </PrivateRoute>
                }
              />
              <Route
                path="pending-orders"
                element={
                  <PrivateRoute>
                    <PendingOrders />
                  </PrivateRoute>
                }
              />
              <Route
                path="awaiting-orders"
                element={
                  <PrivateRoute>
                    <AwaitingOrders />
                  </PrivateRoute>
                }
              />
              <Route
                path="delivered-orders"
                element={
                  <PrivateRoute>
                    <DeliveredOrders />
                  </PrivateRoute>
                }
              />
              <Route
                path="failed-orders"
                element={
                  <PrivateRoute>
                    <FailedOrders />
                  </PrivateRoute>
                }
              />
            </Route>
            <Route
              path="dashboard/orders-management/order-details"
              element={
                <PrivateRoute>
                  <OrderDetails />
                </PrivateRoute>
              }
            /> */}

            {/* Product management */}
            <Route
              path="dashboard/products-management"
              element={
                <PrivateRoute>
                  <Suspense fallback={<BaseLoader />}>
                    <ProductsManagement />
                  </Suspense>
                </PrivateRoute>
              }
            >
              <Route path=":productId" element={<h1>Product details</h1>} />
              <Route index element={<Navigate to="all-skus" />} />
              <Route path="all-skus" element={<AllSkus filterBy="" />}></Route>
              <Route
                path="active-skus"
                element={<AllSkus filterBy="active" />}
              />
              <Route
                path="disabled-skus"
                element={<AllSkus filterBy="inactive" />}
              />
            </Route>

            {/* Ads management */}

            <Route path="dashboard/promo-management">
              <Route index element={<Navigate to="ads-manager" />} />
              <Route
                path="ads-manager"
                element={
                  <PrivateRoute>
                    <AdsManagement />
                  </PrivateRoute>
                }
              />
              <Route
                path="discount-offer"
                element={
                  <PrivateRoute>
                    <DiscountOffer />
                  </PrivateRoute>
                }
              />
            </Route>

            {/* Outlet management */}
            <Route
              path="dashboard/outlet-management"
              // element={
              //   <Suspense fallback={<BaseLoader />}>
              //     <OutletsManagement />
              //   </Suspense>
              // }
            >
              <Route index element={<Navigate to="mobiletrader-users" />} />
              <Route
                path="mobiletrader-users"
                element={
                  <PrivateRoute>
                    <MobiletraderOutlet />
                  </PrivateRoute>
                }
              />
              <Route
                path="reoda-users"
                element={
                  <PrivateRoute>
                    <ReodaOutlet />
                  </PrivateRoute>
                }
              />
              <Route
                path="active-outlet"
                element={
                  <PrivateRoute>
                    <ActiveOutlet />
                  </PrivateRoute>
                }
              />
            </Route>
            <Route
              path="dashboard/outlet-management/order-details"
              element={
                <PrivateRoute>
                  <HistoryDetails />
                </PrivateRoute>
              }
            />
            <Route
              path="dashboard/outlet-management/:outletId/history/*"
              element={
                <PrivateRoute>
                  <GrandOutlet />
                </PrivateRoute>
              }
            />

            {/* Settings */}
            <Route
              path="dashboard/settings"
              element={
                <PrivateRoute>
                  <Suspense fallback={<BaseLoader />}>
                    <Settings />
                  </Suspense>
                </PrivateRoute>
              }
            ></Route>
            <Route
              path="dashboard/settings/profile"
              element={<ProfileSettings />}
            />
            <Route
              path="dashboard/settings/admin"
              element={<AdminSettings />}
            />
            <Route
              path="dashboard/settings/app_distribution"
              element={<AppDistribution />}
            />
            <Route
              path="dashboard/settings/configuration"
              element={<ConfigurationSettings />}
            />

            {/* Customer Support Management Routes */}
            <Route
              path="dashboard/customer-management"
              element={
                <PrivateRoute>
                  <Suspense fallback={<BaseLoader />}>
                    <CustomerSupportManagement />
                  </Suspense>
                </PrivateRoute>
              }
            ></Route>
            <Route
              path="dashboard/customer-management/survey/:surveyId"
              element={<SurveyDetails />}
            />
            <Route
              path="dashboard/customer-management/survey-questions/:surveyId"
              element={<SurveyQuestions />}
            />

            {/* Reconcliation Report Management Routes */}
            <Route
              path="dashboard/reconciliation-report"
              element={
                <Suspense fallback={<BaseLoader />}>
                  <ShopKeeperReport />
                </Suspense>
              }
            ></Route>

            {/* Reconcliation Management Routes */}
            <Route
              path="dashboard/reconciliation"
              element={
                <PrivateRoute>
                  <Suspense fallback={<BaseLoader />}>
                    <ShopKeeperExport />
                  </Suspense>
                </PrivateRoute>
              }
            ></Route>

            {/* Report Management Routes */}
            <Route
              path="dashboard/report-management"
              element={
                <PrivateRoute>
                  <Suspense fallback={<BaseLoader />}>
                    <ReportManagement />
                  </Suspense>
                </PrivateRoute>
              }
            ></Route>
          </Route>
          <Route
            path="*"
            element={
              <ContentLayout titleText="Not Found">
                <main
                  style={{
                    maxWidth: "80rem",
                    paddingInline: "2em",
                    margin: "0 auto",
                    display: "grid",
                    placeItems: "center",
                    height: "100vh",
                  }}
                >
                  <p>There's nothing here!</p>
                </main>
              </ContentLayout>
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
