import { Dispatch, useEffect, useState } from "react";
import { useAppContext } from "context/AppContext";
import ContentLayout from "../../layouts/ContentLayout";
import styled from "styled-components";
import {
  TableTab,
  EmptyList,
  TableSkeleton,
  Pagination,
  Table,
} from "components";
import CreateSurveyForm from "./CreateSurvey";
import { useSearchParams, useParams } from "react-router-dom";
import SurveyService from "../../services/SurveryService";

const navItems = [{ link: "Survey table", linkTo: "" }];

export type ItemType = {
  questionId: string;
  question: string;
  answer: [string];
  urno: number;
};

const SurveyDetails = () => {
  const { state, dispatch } = useAppContext();
  const [tableData, setTableData] = useState<ItemType[]>([]);
  const [tableHeader, setTableHeader] = useState([]);
  const isLoading = state?.isLoading;
  const [willFilter, setWillFilter] = useState("");
  const [bySpecific, setBySpecific] = useState("");
  const [byCustomStart, setByCustomStart] = useState("");
  const [byCustomEnd, setByCustomEnd] = useState("");
  const [pageParams, setPageParams] = useSearchParams();
  const [page, setPage] = useState(() => {
    let p = Number(pageParams.get("page"));
    return p > 0 ? p : 1;
  });
  const [pages, setPages] = useState<number>(0);
  const [limit, setLimit] = useState(10);
  const [isModal, setIsModal] = useState(false);
  const { surveyId } = useParams();

  let surveyHeaders = tableHeader.map((data: any) => {
    return { key: data.questionId, item: data.title };
  });

  surveyHeaders = [{ key: 1, item: "Outlet Id" }, ...surveyHeaders];

  useEffect(() => {
    async function fetchData() {
      dispatch({ type: "data-loading" });

      try {
        const {
          data: { data },
        } = await SurveyService.getSingleSurvey(surveyId);

        setTableHeader(data.question);
        // setPages(meta.pageCount);
        setPageParams({ page: page.toString() });
      } catch (error) {
        console.log(error);
      } finally {
        dispatch({ type: "data-loaded" });
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      dispatch({ type: "data-loading" });

      try {
        const {
          data: {
            data: { surveys, meta },
          },
        } = await SurveyService.getSurveyAnswers(
          surveyId,
          limit,
          page,
          bySpecific,
          byCustomStart,
          byCustomEnd
        );
        console.log("///////", surveys);

        setTableData(surveys);
        setPages(meta.pageCount);
        setPageParams({ page: page.toString() });
      } catch (error) {
        console.log(error);
      } finally {
        dispatch({ type: "data-loaded" });
      }
    }
    fetchData();
  }, [page, limit, willFilter, dispatch]);

  return (
    <ContentLayout titleText="Customer Support" subText="Survey Details">
      <ContentWrapper>
        <CreateSurveyForm setIsModal={setIsModal} isModal={isModal} />
        <TableTab
          // canFilter={true}
          navItems={navItems}
          setBySpecific={setBySpecific}
          setByCustomStart={setByCustomStart}
          setByCustomEnd={setByCustomEnd}
          setWillFilter={setWillFilter}
        />
        {isLoading ? (
          <TableSkeleton />
        ) : tableData.length === 0 ? (
          <EmptyList />
        ) : (
          <SurveyTable>
            <div className="table-container">
              <Table
                headers={surveyHeaders}
                items={tableData}
                renderItem={Answer}
              />
            </div>
            <Pagination
              setLimit={setLimit}
              limit={limit}
              page={page}
              pages={pages}
              setPage={setPage}
            />
          </SurveyTable>
        )}
      </ContentWrapper>
    </ContentLayout>
  );
};

const Answer = (item: ItemType) => {
  const [header, setHeader] = useState([]);
  const { surveyId } = useParams();

  useEffect(() => {
    async function fetchData() {
      try {
        const {
          data: { data },
        } = await SurveyService.getSingleSurvey(surveyId);

        setHeader(data.question);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    console.log("//////HEADER", header);
  }, [header]);

  let answerArray = header.map((data: any) => {
    // Find the corresponding answer based on questionId
    const matchingAnswer: any = item.answer.find(
      (item: any) => data.questionId === item.questionId
    );

    let result;
    if (matchingAnswer.answers.length > 1) {
      result = matchingAnswer.answers.join(", ");
    } else if (matchingAnswer.answers.length === 1) {
      result = matchingAnswer.answers[0];
    } else {
      result = "-"; // Handle the case where the array is empty
    }
    return result;
  });
  answerArray = [item.urno, ...answerArray];

  return (
    <tr key={item.urno}>
      {answerArray.map((data: any) => {
        return <td key={item.questionId}>{data}</td>;
      })}
    </tr>
  );
};

const ContentWrapper = styled.div`
  height: 100%;
  overflow-y: scroll;
  position: relative;
  padding-top: 5em;

  .chart-container {
    padding: 20px 0;
  }
`;
const ButtonContainer = styled.div`
  margin-bottom: 20px;
  padding-left: 30px;

  .button {
    background: #db8130;
    color: #160d05;
    padding: 8px 35px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 600;
  }
`;

const SurveyTable = styled.div`
  tbody tr {
    background-color: hsla(var(--clr-light));
    border-top: 8px solid #ececec;
    position: relative;
  }
  tbody tr td {
    text-transform: capitalize;
    text-align: center;
  }
  tbody tr td:first-child {
    text-decoration: none !important;
    cursor: auto !important;
  }

  .prod {
    display: flex;
    align-items: center;

    span:last-child {
      margin-left: 6px;
    }
  }

  .product-img {
    width: 30px;
    height: 30px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .three-dots {
    display: inline-grid;
    place-items: center;
    width: 100%;
  }
`;

export default SurveyDetails;
