import Api from "./Api";

const Services = {
  getOrders(
    status: string,
    search: any,
    orderType: string,
    region: any,
    depot: any,
    fromDate: string,
    toDate: string,
    limit?: number,
    page?: number
  ) {
    var params = {
      ...(Boolean(region) && { region }),
      ...(Boolean(depot) && { depot }),
      ...(Boolean(region) && { region }),
      ...(Boolean(search) && { search }),
      ...(Boolean(fromDate) && { startDate: fromDate }),
      ...(Boolean(orderType) && { orderType }),
      ...(Boolean(toDate) && { endDate: toDate }),
      deliveryStatus: status,
      limit,
      page,
    };
    if (orderType === "ALL") {
      params.orderType = "";
    }
    if (depot.length >= 1) {
      params.depot = depot.join(",");
    }
    if (region.length >= 1) {
      params.region = region.join(",");
    }
    params.orderType = orderType.toUpperCase();
    return Api().get(`order/status`, { params });
  },
  collectionWalletBalance() {
    return Api().get("order/collectionWalletBalance");
  },
  getOrdersByOutlet(
    outletId: string,
    page: number,
    limit: number,
    status: string,
    startDate?: string,
    endDate?: string
  ) {
    var params = {
      ...(Boolean(startDate) && { startDate }),
      ...(Boolean(endDate) && { endDate }),
      status,
      limit,
      page,
    };
    return Api().get(`/order/orderByOutlet/${outletId}`, { params });
  },
  orderStatusCount() {
    return Api().get("order/status/count");
  },
};

export default Services;
