export function formatArrayToString(items: any, name: string) {
  if (items.length === 0) {
    return "NIL";
  }

  if (items.length === 1) {
    return items[0];
  } else if (items.length === 2) {
    return items.join(" , ");
  } else {
    const firstTwoItem = items.slice(0, 2).join(", ");
    const remainingItemCount = items.length - 2;
    return `${firstTwoItem} and ${remainingItemCount} other ${name}s`;
  }
}
