import React, { Dispatch, SetStateAction, useRef, useState } from 'react';
import passwordCheck from 'assets/svg/password-check.svg';
import AuthService from 'services/AuthenticationService';
import { useAppContext } from 'context/AppContext';
import { RecoveryModal } from './RecoveryModal';
import styled from 'styled-components';

interface ProfileProps {
	fileSrc: string;
	setFileSrc: Dispatch<SetStateAction<string>>;
}

export const Profile: React.FC<ProfileProps> = ({ fileSrc, setFileSrc }) => {
	const { state, dispatch } = useAppContext();
	const inputRef = useRef<HTMLInputElement>(null);
	const [isShow, setIsShow] = useState(false);
	const [isDone, setIsDone] = useState(false);
	const email = state?.user?.email;
	const isLoading = state?.isLoading;

	function handleClick(e: React.FormEvent<HTMLAnchorElement>) {
		e.preventDefault();
		inputRef.current?.click();
	}

	function handleFile(e: ProgressEvent<FileReader>) {
		setFileSrc(e.target?.result as string);
	}

	function handleChange() {
		const reader = new FileReader();
		reader.onloadend = handleFile;
		if (inputRef.current && inputRef.current.files) {
			reader.readAsDataURL(inputRef.current.files[0]);
		}
	}

	async function onChangePassword() {
		try {
			dispatch({ type: 'data-loading' });
			if (!state?.user) return;
			const result = await AuthService.sendChangePasswordLink(state.user.email);
			if (result?.status === 200) setIsDone(true);
		} catch (error) {
		} finally {
			dispatch({ type: 'data-loaded' });
		}
	}

	return (
		<Div className='grid profile'>
			<div className='grid avatar'>
				<div className='avatar-img'>
					<img src={fileSrc} alt='avatar' />
				</div>
				<h1 className='fs-600 fw-500'>
					{state?.user?.firstName} {state?.user?.lastName}
				</h1>
				<a href='settings' onClick={(e) => handleClick(e)} className='underline text-blue-tint-600'>
					Edit profile picture
				</a>
				<input
					type='file'
					ref={inputRef}
					name='avatar'
					id='avatar'
					onChange={() => handleChange()}
					accept='image/png, image/jpeg, image/jpg'
				/>
			</div>
			<Details />
			<div className='grid flow password-settings'>
				<div className='flex password-check'>
					<h2 className='fs-600 fw-500'>Password</h2>
					<img src={passwordCheck} alt='password check' />
				</div>
				<button
					className='ff-serif btn btn-default-ghost text-blue-dark-050 pointer fw-600'
					onClick={() => {
						if (isDone) setIsDone(false);
						setIsShow(true);
					}}>
					Change Password
				</button>
			</div>
			<RecoveryModal
				{...{ setIsShow, onChangePassword, modal: { isShow, isDone, email, isLoading } }}
			/>
		</Div>
	);

	function Details() {
		return (
			<div className='details'>
				<table className='fs-600'>
					<tbody>
						<tr>
							<td>
								Role: <span>{state?.user?.roles.join(', ')}</span>
							</td>
							<td>
								Location: <span>{state?.user?.location}</span>
							</td>
						</tr>
						<tr>
							<td>
								Email: <span>{state?.user?.email}</span>
							</td>
							<td>
								Region: <span>{state?.user?.region}</span>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		);
	}
};

const Div = styled.div`
	padding-block: 2em 6em;
	margin-block: 2em;
	place-items: center;
	background: hsla(var(--clr-light));
	width: min(90%, 1282px);
	.avatar {
		place-items: center;

		.avatar-img {
			width: 80px;
			height: 80px;
			border-radius: 50%;
			border: 1px solid hsla(var(--clr-gray-50));
		}

		img {
			height: 100%;
			width: 100%;
			object-fit: contain;
		}
	}

	.password-settings {
		padding-block: 2em;
		place-items: center;
	}
	button {
		padding: 0.3em 1em;
		width: 100%;
		transition: 200ms;
		&:hover {
			color: hsla(var(--clr-blue-tint-300));
			border-color: hsla(var(--clr-blue-tint-300));
		}
		&:disabled {
			color: hsla(var(--clr-black-tint-100));
			border-color: hsla(var(--clr-black-tint-100));
		}
	}
	.password-check {
		--gap: 0.5rem;
	}
	input[type='file'] {
		display: none;
	}
	.details {
		width: 100%;
		border-bottom: 1px solid hsla(var(--clr-black-tint-900));
		padding-block: 1em;
	}
	.details table {
		border-collapse: collapse;
		tr {
			text-align: center;
			background: none;

			td {
				padding: 0.5em 1em;
			}

			td:first-child {
				text-decoration: none;
				cursor: unset;
			}
		}
	}
`;
