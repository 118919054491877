import React, { Dispatch, useEffect, useState } from 'react';
import styled from 'styled-components';
import { AdminUserType } from './Admin';
import { AdminUser } from './AdminUser';

interface UserDetailsProps {
	tableData: AdminUserType[];
	setTableData: Dispatch<React.SetStateAction<AdminUserType[]>>;
}

export const userDetailsHeaders = [
	{ key: 1, item: 'Name' },
	{ key: 2, item: 'Email Address' },
	{ key: 3, item: 'User ID' },
	{ key: 4, item: 'Location' },
	{ key: 5, item: 'Status' },
	{ key: 6, item: 'Role' },
	{ key: 7, item: 'Action' },
];

export const UserDetailsTable: React.FC<UserDetailsProps> = ({ tableData, setTableData }) => {
	return (
		<>
			<Div>
				<table className='text-black'>
					<thead className='fs-400'>
						<tr>
							{userDetailsHeaders.map((header) => (
								<th key={header.key}>{header.item}</th>
							))}
						</tr>
					</thead>
					<tbody className='fs-300'>
						{tableData.map((item) => (
							// console.log('&&: ', item)
							<AdminUser key={item._id} {...{ item, setTableData }} />
						))}
					</tbody>
				</table>
			</Div>
		</>
	);
};
const Div = styled.div`
	overflow-x: scroll;
	white-space: nowrap;

	thead tr,
	tbody tr {
		background-color: hsla(var(--clr-light));
		border-bottom: 1px solid hsla(var(--clr-black-tint-800));
	}
	td:first-child {
		text-decoration: none;
	}
	th,
	td {
		padding: 1em;
	}
	.name {
		--gap: 0.3rem;
		align-items: flex-end;
	}
	td:last-child {
		text-align: center;
	}
`;
