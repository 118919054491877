import Api from "./Api";

const Services = {
  updateOrderConfig(params: any) {
    params.model = "ORDER";

    return Api().post("update-config", params);
  },
  getOrderConfig() {
    var params = {
      model: "ORDER",
    };

    return Api().get("get-config", { params });
  },
};

export default Services;
