import React, { SetStateAction } from 'react';
import ReactDOM from 'react-dom';
import ButtonSpinner from 'components/Loaders/ButtonSpinner';

interface RecoveryModalProps {
	modal: {
		email: string | undefined;
		isLoading: boolean | undefined;
		isShow: boolean;
		isDone: boolean;
	};
	onChangePassword: () => void;
	setIsShow: React.Dispatch<SetStateAction<boolean>>;
}
export const RecoveryModal: React.FC<RecoveryModalProps> = ({
	modal,
	setIsShow,
	onChangePassword,
}) => {
	if (!modal.isShow) return null;
	return ReactDOM.createPortal(
		<>
			<div
				style={{
					backgroundColor: 'rgba(0, 0, 0, 0.6)',
					inset: 0,
					position: 'fixed',
					transition: 'transform 600ms ease-in-out',
					zIndex: 9,
				}}
			/>
			<span
				className='pointer'
				onClick={() => setIsShow(false)}
				style={{ position: 'absolute', top: '10%', right: '5%', zIndex: 9 }}>
				<svg width='14' height='15' xmlns='http://www.w3.org/2000/svg'>
					<path
						d='m11.596.782 2.122 2.122L9.12 7.499l4.597 4.597-2.122 2.122L7 9.62l-4.595 4.597-2.122-2.122L4.878 7.5.282 2.904 2.404.782l4.595 4.596L11.596.782Z'
						fill='#FFF'
						fillRule='evenodd'
					/>
				</svg>
			</span>
			<div
				className='flow'
				style={{
					position: 'fixed',
					width: '524px',
					height: '138px',
					top: '45%',
					left: '55%',
					backgroundColor: 'hsla(var(--clr-light))',
					margin: '0 auto',
					padding: '1.3em',
					transform: 'translate(-50%, -50%)',
					zIndex: 99,
				}}>
				{modal.isDone ? (
					<>
						<p className='fs-300'>
							Request for Password change is now in progress. Email sent to{' '}
							<span className='fw-600'>“{modal.email}”</span> for further instructions.
						</p>
						<button
							className='btn btn-default fs-100 pointer'
							style={{ width: '30%', position: 'relative' }}
							onClick={() => setIsShow(false)}>
							Close
						</button>
					</>
				) : (
					<>
						<p className='fs-300'>
							We’ll send a link to this email <span className='fw-600'>“{modal.email}”</span>, to
							change your password
						</p>
						<button
							className='btn btn-default fs-100 pointer'
							style={{ width: '30%', position: 'relative' }}
							onClick={() => onChangePassword()}>
							{modal.isLoading ? <ButtonSpinner /> : 'Send Recovery Link'}
						</button>
					</>
				)}
			</div>
		</>,
		document.getElementById('portal')!
	);
};
