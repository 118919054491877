import Api from "./Api";

const Services = {
  getAllReconciliationOrder(
    specificDate: string,
    startDate: string,
    endDate: string,
    depot: string
  ) {
    if (depot === "ALL") {
      depot = "";
    }
    depot = depot.replace(/ /g, "-");

    var params = {
      ...(Boolean(specificDate || startDate || endDate) && { type: "filter" }),
      ...(Boolean(specificDate) && { specificDate }),
      ...(Boolean(startDate) && { startDate }),
      ...(Boolean(endDate) && { endDate }),
      depot,
    };

    return Api().get("order/bat/reconciliation", { params });
  },
  getAllReport(startDate: string, endDate: string) {
    var formData = {
      startDate,
      endDate,
      // reportName,
    };

    return Api().post("order/sales_rep/summary", formData);
  },
};

export default Services;
