import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import SearchIcon from "../assets/svg/headerSearch.svg";

type LayoutProps = {
  children: React.ReactNode;
  component?: React.ReactNode;
  titleText: any;
  subText?: React.ReactNode;
  setSearch?: any;
  orderStatus?: string;
};

const ContentLayout = ({
  children,
  titleText,
  subText,
  component,
  setSearch,
  orderStatus,
}: LayoutProps) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [isFocused, setIsFocused] = useState(false);
  const [query, setQuery] = useState("");

  let pathArr = pathname.split("/").splice(1);
  let rootPath = pathArr[0];
  let crumbs = pathArr.splice(1);

  const crumbNavigation = (str: string, arr: string[]) => {
    let strPath = arr.join("/");
    const reg = new RegExp(str + ".*");

    const path = strPath.replace(reg, "");
    navigate(`/${rootPath}/${path}${str}`);
  };

  // Handle input focus
  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => {
    if (query === "") setIsFocused(false);
  };
  // Clear input
  const clearInput = () => {
    setQuery("");
    setIsFocused(false);
    setSearch("");
  };

  // Handle Enter key press
  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      event.preventDefault();
      performSearch();
    }
  };

  // Perform search function
  const performSearch = () => {
    if (query.trim()) {
      setSearch(query);
      // clearInput();
    }
  };

  return (
    <ContentWrap>
      <header className="header">
        <div className="left-items">
          <div className="bread-crumbs">
            {crumbs.map((str, ind, arr) => (
              <button
                style={{
                  color: ind === arr.length - 1 ? "#022f1a" : "#8d8b8f",
                }}
                onClick={() => crumbNavigation(str, arr)}
                className="crumb"
                key={ind}
              >
                <span className="crumb-string">{str.replace("%20", "-")}</span>{" "}
                /
              </button>
            ))}
          </div>
          <div className="title">
            <div className="title-container">
              <h1>{titleText}</h1>
              {orderStatus && (
                <div className={`status ${orderStatus}`}>
                  <p>{orderStatus}</p>
                </div>
              )}
            </div>
            {subText}
          </div>
        </div>
        {setSearch && (
          <div className="mid-items">
            <div className="search-input-wrapper">
              {!isFocused && (
                <span className="search-icon">
                  <img src={SearchIcon} alt="Search Icon" />
                </span>
              )}

              <input
                type="search"
                className="search-input"
                placeholder={!isFocused ? "Search something..." : ""}
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                onFocus={handleFocus}
                onBlur={handleBlur}
                onKeyDown={handleKeyDown}
              />
              {query && (
                <button className="clear-button" onClick={clearInput}>
                  ✕
                </button>
              )}
            </div>
          </div>
        )}

        <div className="right-items">{component}</div>
      </header>
      <section className="children-node">{children}</section>
    </ContentWrap>
  );
};

const ContentWrap = styled.div`
  height: 100vh;

  .header {
    background-color: #fff;
    padding: 10px 30px 5px;
    height: 80px;
    position: sticky;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 2;

    .title-container {
      display: flex;
      align-items: center;
      gap: 20px;
    }

    .bread-crumbs {
      padding-left: 4px;
      padding-bottom: 4px;
    }
    .crumb {
      font-weight: 500;
      cursor: pointer;
      font-size: 14px;
      background: none;
      border: none;
      padding: 0;

      :not(:first-child) {
        padding-left: 2px;
      }

      :hover .crumb-string {
        text-decoration: underline;
      }
    }
  .status { 
    padding: 5px 15px;
    border-radius: 15px;
    font-size: 0.8rem;
  }
  .status.failed {
    background: #FAB8B8;
    color: #d3180c;
  }
  .status.awaiting {
    background: #AFABEE;
    color: #221f58;
  }
  .status.delivered {
    background: #B4F3C2;
    color: #007731;
  }
  .status.pending {
    color: #834d1d;
  }
    /* SearchInput.css */

    .search-input-wrapper {
      display: flex;
      align-items: center;
      width: 100%;
      width: 400px;
      border: 1px solid #ccc;
      border-radius: 4px;
      padding: 5px;
      background-color: #f9f9f9;
      position: relative;
    }

    .search-input {
      flex: 1;
      border: none;
      outline: none;
      padding: 8px;
      font-size: 14px;
      background-color: transparent;
    }
      
    .search-icon {
      width: 15px;
      margin-left: 10px;
    }
    .search-icon img {
      width: 100%
    }
      

    .search-button {
      border: none;
      background: none;
      cursor: pointer;
      padding: 0 8px;
      font-size: 18px;
      color: #333;
    }
    /* Style for the clear (X) button */
    .clear-button {
      position: absolute;
      right: 20px; /*
      background: none;
      border: none;
      color: #666;
      cursor: pointer;
      font-size: 16px;
      padding: 0;
    }

    .search-input:focus + .search-button {
      color: #007bff;
    }

    /* Optional: Change the color on focus */
    .search-input-wrapper:focus-within {
      border-color: #007bff;
    }

    h1 {
      font-size: 24px;
    }

    p {
      font-size: 14px;
    }
    .title {
      padding-bottom: 8px;
    }
  }
  .children-node {
    height: calc(100vh - 80px);
    overflow: scroll;
  }
`;
export default ContentLayout;
