import { useAppContext } from "context/AppContext";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { EmptyList, TableSkeleton, Pagination, Table } from "components";
import ProductService from "services/ProductService";
import { ActionModal } from "./ActionModal";
import { useSearchParams } from "react-router-dom";
import { ViewDetailsModal } from "./ViewDetailsModal";
import { ItemType } from ".";

interface AllSkusProps {
  filterBy: string;
}

const productHeaders = [
  { key: 1, item: "Item no" },
  { key: 2, item: "Product Name" },
  { key: 3, item: "Brands" },
  { key: 4, item: "Status" },
  { key: 5, item: "Action" },
];

export const AllSkus: React.FC<AllSkusProps> = ({ filterBy }) => {
  const { state, dispatch } = useAppContext();
  const [tableData, setTableData] = useState<ItemType[]>([]);
  const isLoading = state?.isLoading;
  const [pageParams, setPageParams] = useSearchParams();
  const [page, setPage] = useState(() => {
    let p = Number(pageParams.get("page"));
    return p > 0 ? p : 1;
  });
  const [pages, setPages] = useState<number>(0);
  const [limit, setLimit] = useState(10);
  const [isModal, setIsModal] = useState(false);

  useEffect(() => {
    async function fetchData() {
      dispatch({ type: "data-loading" });
      try {
        const {
          data: {
            data: { products, meta },
          },
        } = await ProductService.getAllProducts(filterBy, limit, page);
        console.log(products);

        setTableData(products);
        setPages(meta.pageCount);
        setPageParams({ page: page.toString() });
      } catch (error) {
      } finally {
        dispatch({ type: "data-loaded" });
      }
    }

    fetchData();
  }, [page, limit, filterBy]);

  return (
    <>
      {isLoading ? (
        <TableSkeleton />
      ) : tableData.length === 0 ? (
        <EmptyList />
      ) : (
        <ProductsTable>
          <div className="table-container">
            <Table
              headers={productHeaders}
              items={tableData}
              renderItem={Product}
            />
          </div>
          <Pagination
            setLimit={setLimit}
            limit={limit}
            page={page}
            pages={pages}
            setPage={setPage}
          />
        </ProductsTable>
      )}
    </>
  );
};

const Product = (item: ItemType) => {
  const [isViewDetails, setIsViewDetails] = useState(false);
  const [isMoreAction, setIsMoreAction] = useState(false);

  return (
    <tr key={item.product_code}>
      <td onClick={() => setIsViewDetails(true)}>{item.product_code}</td>
      <td className="prod">
        <span className="product-img">
          <img src={item.image} alt="Product" />
        </span>
        <span>{item.product_name}</span>
      </td>
      <td>{item.brand_name}</td>
      <td>{item.isDeleted ? "Inactive" : "Active"}</td>
      <td onClick={() => setIsMoreAction(true)} className="pointer three-dots">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="3"
          height="24"
          viewBox="0 0 3 24"
          fill="none"
        >
          <path
            d="M1.50016 15.9965L1.50016 16.0054"
            stroke="#333333"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M1.50016 11.9955L1.50016 12.0045"
            stroke="#333333"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M1.50016 7.99451L1.50016 8.00349"
            stroke="#333333"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </td>
      <ActionModal
        item={item}
        display={isMoreAction}
        setIsModal={setIsMoreAction}
      />
      <ViewDetailsModal
        item={item}
        display={isViewDetails}
        setIsModal={setIsViewDetails}
      />
    </tr>
  );
};

const ProductsTable = styled.div`
  tbody tr {
    background-color: hsla(var(--clr-light));
    border-top: 8px solid #ececec;
    position: relative;
  }

  .prod {
    display: flex;
    align-items: center;

    span:last-child {
      margin-left: 6px;
    }
  }

  .product-img {
    width: 30px;
    height: 30px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .three-dots {
    display: inline-grid;
    place-items: center;
    width: 100%;
  }
`;
