import Api from './Api';

const Services = {
	getAllOutletWalletBalance() {
		return Api().get('admin/finance/sumAllOutletWalletBalance');
	},
	getAllRegionTransactions(
		page: number,
		limit: number,
		specificDate: string,
		startDate: string,
		endDate: string
	) {
		var params = {
			...(Boolean(specificDate || startDate || endDate) && { type: 'filter' }),
			...(Boolean(specificDate) && { specificDate }),
			...(Boolean(startDate) && { startDate }),
			...(Boolean(endDate) && { endDate }),
			limit,
			page,
		};
		return Api().get(`admin/finance/getAllRegionDetails`, { params });
	},
	getRegionTransactionsDetail(
		region: string,
		limit: number,
		page: number,
		specificDate: string,
		startDate: string,
		endDate: string
	) {
		var params = {
			...(Boolean(specificDate || startDate || endDate) && { type: 'filter' }),
			...(Boolean(specificDate) && { specificDate }),
			...(Boolean(startDate) && { startDate }),
			...(Boolean(endDate) && { endDate }),
			region,
			limit,
			page,
		};
		return Api().get(`admin/finance/getIndividualRegionDetails`, { params });
	},
	getAllDepotDetails(
		limit: number,
		page: number,
		specificDate: string,
		startDate: string,
		endDate: string
	) {
		var params = {
			...(Boolean(specificDate || startDate || endDate) && { type: 'filter' }),
			...(Boolean(specificDate) && { specificDate }),
			...(Boolean(startDate) && { startDate }),
			...(Boolean(endDate) && { endDate }),
			limit,
			page,
		};
		return Api().get(`admin/finance/getAllDepotDetails`, { params });
	},
	getIndividulaDepotDetails(
		depot: string,
		limit: number,
		page: number,
		specificDate: string,
		startDate: string,
		endDate: string
	) {
		var params = {
			...(Boolean(specificDate || startDate || endDate) && { type: 'filter' }),
			...(Boolean(specificDate) && { specificDate }),
			...(Boolean(startDate) && { startDate }),
			...(Boolean(endDate) && { endDate }),
			depot,
			limit,
			page,
		};
		return Api().get(`admin/finance/getIndividualDepotDetails`, { params });
	},
	getAllOutletDetails(
		limit: number,
		page: number,
		specificDate: string,
		startDate: string,
		endDate: string
	) {
		var params = {
			...(Boolean(specificDate || startDate || endDate) && { type: 'filter' }),
			...(Boolean(specificDate) && { specificDate }),
			...(Boolean(startDate) && { startDate }),
			...(Boolean(endDate) && { endDate }),
			limit,
			page,
		};
		return Api().get(`admin/finance/getAllOutletDetails`, { params });
	},
	getIndividualOutletDetails(
		urno: string,
		limit: number,
		page: number,
		specificDate: string,
		startDate: string,
		endDate: string
	) {
		var params = {
			...(Boolean(specificDate || startDate || endDate) && { type: 'filter' }),
			...(Boolean(specificDate) && { specificDate }),
			...(Boolean(startDate) && { startDate }),
			...(Boolean(endDate) && { endDate }),
			urno,
			limit,
			page,
		};
		return Api().get(`admin/finance/getIndividualOutletDetails`, { params });
	},
	getAllReps(
		limit: number,
		page: number,
		specificDate: string,
		startDate: string,
		endDate: string
	) {
		var params = {
			...(Boolean(specificDate || startDate || endDate) && { type: 'filter' }),
			...(Boolean(specificDate) && { specificDate }),
			...(Boolean(startDate) && { startDate }),
			...(Boolean(endDate) && { endDate }),
			limit,
			page,
		};
		return Api().get(`admin/finance/getAllRepDetails`, { params });
	},
	getIndividualRep(
		repId: string,
		limit: number,
		page: number,
		specificDate: string,
		startDate: string,
		endDate: string
	) {
		var params = {
			...(Boolean(specificDate || startDate || endDate) && { type: 'filter' }),
			...(Boolean(specificDate) && { specificDate }),
			...(Boolean(startDate) && { startDate }),
			...(Boolean(endDate) && { endDate }),
			repId,
			limit,
			page,
		};
		return Api().get(`admin/finance/getIndividualRepDetails`, { params });
	},
	getIndividualOutletWallet(outletId: string) {
		return Api().get(`admin/finance/individualOutletWalletBalance/${outletId}`);
	},

	getBankStatement(BankName: string, StartDate: string, EndDate: string){
		return Api().post(`integrators/wema/getStatement`, {BankName, StartDate, EndDate})
	}
};

export default Services;
