import styled from "styled-components";
import Img from "../../assets/images/loading.gif";

const LoginLoader = () => {
  return (
    <LoaderWrapper>
      <div className="image-wrap">
        <img src={Img} alt="loader" />
      </div>
      <div className="text">
        <h2>We are verifying your account</h2>
        <p>This might take a while, so bear with us</p>
      </div>
    </LoaderWrapper>
  );
};

const LoaderWrapper = styled.div`
  width: 700px;
  height: 500px;
  background-color: #fff;
  margin: 50px auto;
  padding: 40px 100px;
  border-radius: 5px;

  .image-wrap {
    width: 300px;
    height: 300px;
    margin: 0 auto;
  }

  .text {
    color: #022f1a;
    text-align: center;
    .margin-top: 8px;
    p {
      font-size: 12px;
      margin-top: 16px;
    }
  }
`;

export default LoginLoader;
