import React, {
  useState,
  useEffect,
  ChangeEvent,
  Dispatch,
  SetStateAction,
} from "react";
import Dropdown from "react-dropdown-select";
import styled from "styled-components";
import { useAppContext } from "context/AppContext";
import ProductService from "services/ProductService";
import { Checkbox } from "./CheckBox";

interface SelectFormProps {
  setSelectedDepots: any;
  depots: [];
  selectedDepots: [];
  selectedRegions: [];
}
export const DepotSelect: React.FC<SelectFormProps> = ({
  setSelectedDepots,
  selectedRegions,
  depots,
}) => {
  const { dispatch } = useAppContext();
  const [options, setOptions] = useState<string[]>([]);
  const [exisitngDepot, setExistingDepot] = useState<any>();
  const [checkBox, setCheckBox] = useState(false);
  const [isSelectAllSelected, setIsSelectAllSelected] = useState(false);
  const [dropdownValue, setDropDownValue] = useState([]);

  useEffect(() => {
    let tempdata = selectedRegions?.map((region: any) => region.id);

    async function fetchData() {
      if (selectedRegions.length >= 1) {
        try {
          const {
            data: {
              data: { depots },
            },
          } = await ProductService.getAllDepotsByRegion(tempdata);
          const modifiedData = depots.map((obj: any) => {
            if (obj.region) {
              obj.name = `${obj.name} (${obj.region_code.toUpperCase()})`;
            }
            return obj;
          });
          setOptions(modifiedData);
        } catch (error) {
          // Handle error
        } finally {
          dispatch({ type: "data-loaded" });
        }
      } else {
        setOptions([]);
      }
    }
    fetchData();
  }, [selectedRegions]);

  useEffect(() => {
    let filteredData;
    if (depots) {
      const tempDepots = depots.map((depot: any) => depot.toUpperCase());
      filteredData = options.filter((obj: any) => {
        const nameWithoutRegion = obj.name.split(" (")[0];
        return tempDepots.includes(nameWithoutRegion);
      });
      setExistingDepot(filteredData);
    }
  }, [options]);

  const handleDropdownChange = (values: any) => {
    setSelectedDepots(values);
    setDropDownValue(values);
  };

  useEffect(() => {
    if (checkBox) {
      setSelectedDepots(options);
      setIsSelectAllSelected(true);
    } else {
      setIsSelectAllSelected(false);
      setSelectedDepots(dropdownValue);
    }
  }, [checkBox]);

  return (
    <DepotForm>
      <div className="drop-down">
        <Dropdown
          options={options}
          labelField="name"
          valueField="id"
          onChange={handleDropdownChange}
          placeholder="Choose depot"
          multi
          color="#8D8B8F"
          values={exisitngDepot}
          className="select_dropdown"
          searchable
          searchBy="name"
          required
          disabled={
            isSelectAllSelected || selectedRegions.length < 1 ? true : false
          }
        />
      </div>
      <div className="checkContainer">
        <label htmlFor="selectAll">Select All Depots</label>
        <Checkbox
          id="selectAll"
          checked={checkBox}
          onChange={(e: any) => setCheckBox(e.target.checked)}
          disabled={selectedRegions.length < 1 ? true : false}
        />
      </div>
    </DepotForm>
  );
};

const DepotForm = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .drop-down {
    width: 68%;
  }
  .checkContainer {
    width: 30%;
    display: flex;
    gap: 10px;
    align-items: center;
  }
  .checkContainer label {
    color: #888;
    font-size: 0.9rem;
    font-weight: 600;
  }

  .check:disabled < .checkContainer label {
    color: rgba(255, 255, 255, 0.9);
  }
`;
