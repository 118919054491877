import styled from "styled-components";
import ReactDOM from "react-dom";
import { Button } from "components";
import React, { Dispatch, useEffect, useState } from "react";
import ProductService from "services/ProductService";
import { ItemType } from ".";
import { useAppContext } from "context/AppContext";

interface ModalProps {
  item: ItemType;
  display: boolean;
  setIsModal: Dispatch<React.SetStateAction<boolean>>;
}

export const ViewDetailsModal: React.FC<ModalProps> = ({
  item,
  display,
  setIsModal,
}) => {
  const { state, dispatch } = useAppContext();

  const [title, setTitle] = useState(item.title);
  const [description, setDescription] = useState(item.description);
  const [combinations, setCombinations] = useState(item.combinations);
  const [rewards, setRewards] = useState(item.rewards);
  let startDate = new Date(item.start_date);
  let endDate = new Date(item.end_date);
  let splittedDepots = item.depots.join(", ");

  if (!display) return null;
  return ReactDOM.createPortal(
    <>
      <StyledOverlay />
      <StyledModal className="modal-container">
        <Close className="pointer close" onClick={() => setIsModal(false)} />
        <h1 className="fs-600 fw-400 text-blue-dark-050 hed">Banner Details</h1>
        <div className="contain">
          {item.combinations.length >= 1 && (
            <div className="products-container">
              <div className="bundles">
                <div className="prods-hed">BUNDLE PRODUCTS</div>
                <div className="bundle-cnt">
                  {item.combinations.map((data: any) => {
                    let quantity =
                      data.type === "roll"
                        ? `${data.quantity} Roll(s)`
                        : `${data.quantity} Pack(s)`;
                    return (
                      <div className="bundle-ech" key={data.product_code}>
                        <div className="bundle-ech-img">
                          <img src={data.image_url} alt="img" />
                        </div>
                        <div className="bundle-ech-det">
                          <p className="quant">{data.product_name}</p>
                          <p className="roll">{quantity}</p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="bundles">
                <div className="prods-hed">REWARD PRODUCTS</div>
                <div className="bundle-cnt">
                  {item.rewards.map((data: any) => {
                    let quantity =
                      data.type === "roll"
                        ? `${data.quantity} Roll(s)`
                        : `${data.quantity} Pack(s)`;
                    return (
                      <div className="bundle-ech" key={data.product_code}>
                        <div className="bundle-ech-img">
                          <img src={data.image_url} alt="img" />
                        </div>
                        <div className="bundle-ech-det">
                          <p className="quant">{data.product_name}</p>
                          <p className="roll">{quantity}</p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}

          <div className="details-container">
            <header>
              <p>Ads Detail</p>
            </header>
            <div className="det-det">
              <p>
                <b>Title: </b>
                {item.title}
              </p>
              <p>
                <b>Description: </b>
                {item.description}
              </p>
              <p>
                <b>Depot(s): </b>
                {splittedDepots}
              </p>
              <p>
                <b>Start date / End date: </b>
                {`${startDate.getDate()}/${
                  startDate.getMonth() + 1
                }/${startDate.getFullYear()} - ${endDate.getDate()}/${
                  endDate.getMonth() + 1
                }/${endDate.getFullYear()}`}
              </p>
              <p>
                <b>Ads status: </b>
                {item.is_active ? "Active" : "Inactive"}
              </p>
            </div>
          </div>
          <div
            className={`images-container ${
              item.combinations.length < 1 && "height"
            }`}
          >
            <div>
              <header>Product Image</header>
              {item.product_image_url && (
                <div className="item-product-container">
                  <div className="item-img">
                    <img src={item.product_image_url} alt="" />
                  </div>
                </div>
              )}
              {!item.product_image_url && (
                <div className="no-image">
                  <p>No Product Item Image</p>
                </div>
              )}
            </div>
            <header>Banner Image</header>
            <div className="img-contain">
              {item.small_image_url && (
                <div className="img-contain-ech">
                  <p>
                    <b>Small Banner</b>
                  </p>
                  <div className="img-contain-ech-img">
                    <img src={item.small_image_url} alt="" />
                  </div>
                </div>
              )}

              {item.large_image_url && (
                <div className="img-contain-ech">
                  <p>
                    <b>Large Banner</b>
                  </p>
                  <div className="img-contain-ech-img large">
                    <img src={item.large_image_url} alt="" />
                  </div>
                </div>
              )}

              {item.preview_image_url && (
                <div className="img-contain-ech">
                  <p>
                    <b> {item.size === "S" ? "Small" : "Large"} Banner</b>
                  </p>
                  <div
                    className={`img-contain-ech-img ${
                      item.size === "L" && "large"
                    }`}
                  >
                    <img src={item.preview_image_url} alt="" />
                  </div>
                </div>
              )}
              {!item.small_image_url &&
                !item.large_image_url &&
                !item.preview_image_url && (
                  <div className="no-image">
                    <p>No Banner Images</p>
                  </div>
                )}
            </div>
          </div>
        </div>
      </StyledModal>
    </>,
    document.getElementById("portal")!
  );
};

const Close = ({ ...props }) => {
  return (
    <div {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18 6L6 18M6 6L18 18"
          stroke="#0D0C0E"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

// interface ImageBoxProps {
//   image: never[];
//   src: string;
//   setImage: Dispatch<React.SetStateAction<never[]>>;
// }

// const ImageBox = ({ setImage, image, src }: ImageBoxProps) => {
//   const [fileSrc, setFileSrc] = useState(src);
//   const inputRef = useRef<HTMLInputElement>(null);

//   function handleClick(e: React.FormEvent<HTMLAnchorElement>) {
//     e.preventDefault();
//     inputRef.current?.click();
//   }

//   function handleFile(e: ProgressEvent<FileReader>) {
//     setFileSrc(e.target?.result as string);
//   }

//   function handleChange() {
//     const reader = new FileReader();
//     reader.onloadend = handleFile;
//     if (inputRef.current && inputRef.current.files) {
//       reader.readAsDataURL(inputRef.current.files[0]);
//       setImage([...image, inputRef.current.files[0]] as never);
//     }
//   }

//   return (
//     <StyledDiv className="grid">
//       <div className="product">
//         <img className="product-img" src={fileSrc} alt="product" />
//       </div>
//       <a
//         href="settings"
//         onClick={(e) => handleClick(e)}
//         className="underline text-blue-tint-600"
//       >
//         edit image
//       </a>
//       <input
//         type="file"
//         ref={inputRef}
//         name="avatar"
//         id="avatar"
//         onChange={() => handleChange()}
//         accept="image/png, image/jpeg, image/jpg"
//       />
//     </StyledDiv>
//   );
// };

// const BrandSelect = ({ ...props }) => {
//   return (
//     <StyledSelect name="title" id="brand" {...props}>
//       <option defaultValue="">Select Brand</option>
//       <option value="a brand">A brand</option>
//       <option value="another brand">Another Brand</option>
//       <option value="and another brand">And Another Brand</option>
//     </StyledSelect>
//   );
// };

const StyledOverlay = styled.div.attrs(() => {})`
  /* position: relative; */
  position: absolute;
  inset: 0;
  height: 100vh;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.7);
  transition: 0.5s;
  &::after {
  }
`;

const StyledModal = styled.div`
  position: fixed;
  width: 66.5em;
  height: 47.125em;
  top: 50%;
  left: 55%;
  background-color: hsla(var(--clr-light));
  background-color: #ececec;
  margin: 0 auto;
  transform: translate(-50%, -50%);
  z-index: 99;
  transition: 0.3s;
  place-items: center;

  .close {
    position: absolute;
    right: 5%;
    top: 5%;
  }
  .hed {
    text-align: center;
    padding: 20px 0;
    padding: 2em;
  }

  .contain {
    height: 600px;
  overflow-y: auto;
  }
  .details-container,
  .images-container,.products-container {
    background-color: hsla(var(--clr-light));
  }
  .images-container {
    margin-top: 10px;
  }
  .height {
    height: 100%;
  }
  header {
    padding: 1.5em 3em;
    font-size: var(--fs-500);
    border-bottom: 1px solid #ececec;
  }
  .det-det {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 1.5em 3em;
    font-size: var(--fs-250);
  }
  .img-contain {
    display: flex;
    justify-content: space-between;
    gap: 15px;
    padding: 1.5em 3em;
    font-size: var(--fs-250);
  }
  .img-contain-ech {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  .img-contain-ech-img {
    width: 300px;
  }
    
  .large {
    width: 400px;
  }
  .img-contain-ech-img img {
    width: 100%;
  }
  .item-img {
  width: 200px;
  }
  .item-img img {
    width: 100%;
    object-fit: contain;
  }
  .no-image {
    width: 100%
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .products-container {
    margin-bottom: 10px;
    display: flex;
  }
  .bundles {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .prods-hed{
    width: 100%;
    padding: 1.5em 0;
    border: 1px solid #ececec;
    text-align: center
  }
  .bundle-cnt {
    width: 100%;
  }
  .bundle-ech {
    width: 100%;
    padding: 1.5em 0;
    border: 1px solid #ececec;
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center
  }
  .bundle-ech-img{
    width: 20px;
    height: 30px;
  }
  .bundle-ech-img img{
    width: 100%;
    height: 100%;
  }
  .bundle-ech-det{
    display: flex;
    gap: 5px;
    justify-content: center;
    align-items: center;
    text-transform: capitalize;
  }
  // .bundle-ech-det p{
  //   color: #777
  // }
  .quant{
    color: #777
  }
  .roll {
    color: #000
  }
    .item-product-container {
    padding: 1.5em 3em
      }
    }
`;
