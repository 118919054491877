import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import Logo from "../assets/svg/logo-white.svg";
import Placeholder from "../assets/images/placeholder.png";
import { useAppContext } from "../context/AppContext";

const Sidebar = () => {
  const { state, dispatch } = useAppContext();

  const userRoles: string[] = state?.user?.roles || [];
  const handleLogout = () => {
    dispatch({ type: "logout" });
  };

  const roleMenus: any = {
    ADMIN: [
      "orders-management",
      "products-management",
      "promo-management",
      "outlet-management",
      "finance",
      "customer-management",
      "settings",
      "reconciliation-report",
      "reconciliation",
      "report-management",
    ],
    SUPER_ADMIN: [
      "orders-management",
      "products-management",
      "promo-management",
      "outlet-management",
      "finance",
      "customer-management",
      "settings",
      "reconciliation-report",
      "reconciliation",
      "report-management",
    ],
    STORE_KEEPER: ["reconciliation-report"],
  };

  const isMenuAllowed = (menu: string) => {
    return userRoles.some((role) => roleMenus[role]?.includes(menu));
  };

  return (
    <SidebarWrap>
      <div className="logo">
        <div className="logo-wrap">
          <img src={Logo} alt="logo" />
        </div>
      </div>

      <div className="side-menu">
        {isMenuAllowed("orders-management") && (
          <NavLink to="/dashboard/orders-management" className="menu-link">
            <div className="menu-item">
              <span className="menu-logo">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#7A777D"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <circle cx="9" cy="21" r="1"></circle>
                  <circle cx="20" cy="21" r="1"></circle>
                  <path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path>
                </svg>
              </span>
              <span className="menu-text">Orders Management</span>
            </div>
          </NavLink>
        )}
        {isMenuAllowed("products-management") && (
          <NavLink to="/dashboard/products-management" className="menu-link">
            <div className="menu-item">
              <span className="menu-logo">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#7A777D"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"></path>
                  <line x1="3" y1="6" x2="21" y2="6"></line>
                  <path d="M16 10a4 4 0 0 1-8 0"></path>
                </svg>
              </span>
              <span className="menu-text">Products Management</span>
            </div>
          </NavLink>
        )}
        {isMenuAllowed("promo-management") && (
          <NavLink to="/dashboard/promo-management" className="menu-link">
            <div className="menu-item">
              <span className="menu-logo">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#7A777D"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"></path>
                  <line x1="3" y1="6" x2="21" y2="6"></line>
                  <path d="M16 10a4 4 0 0 1-8 0"></path>
                </svg>
              </span>
              <span className="menu-text">Promo Management</span>
            </div>
          </NavLink>
        )}

        {isMenuAllowed("outlet-management") && (
          <NavLink to="/dashboard/outlet-management" className="menu-link">
            <div className="menu-item">
              <span className="menu-logo">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#7A777D"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                  <circle cx="9" cy="7" r="4"></circle>
                  <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                  <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                </svg>
              </span>
              <span className="menu-text">Outlet Management</span>
            </div>
          </NavLink>
        )}

        {isMenuAllowed("finance") && (
          <NavLink to="/dashboard/finance" className="menu-link">
            <div className="menu-item">
              <span className="menu-logo">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#7A777D"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M21.21 15.89A10 10 0 1 1 8 2.83"></path>
                  <path d="M22 12A10 10 0 0 0 12 2v10z"></path>
                </svg>
              </span>
              <span className="menu-text">Finance Management</span>
            </div>
          </NavLink>
        )}

        {isMenuAllowed("customer-management") && (
          <NavLink to="/dashboard/customer-management" className="menu-link">
            <div className="menu-item">
              <span className="menu-logo">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2 12V8C2 6.4087 2.63214 4.88258 3.75736 3.75736C4.88258 2.63214 6.4087 2 8 2C9.5913 2 11.1174 2.63214 12.2426 3.75736C13.3679 4.88258 14 6.4087 14 8V12M14 12.6667C14 13.0203 13.8595 13.3594 13.6095 13.6095C13.3594 13.8595 13.0203 14 12.6667 14H12C11.6464 14 11.3072 13.8595 11.0572 13.6095C10.8071 13.3594 10.6667 13.0203 10.6667 12.6667V10.6667C10.6667 10.313 10.8071 9.97391 11.0572 9.72386C11.3072 9.47381 11.6464 9.33333 12 9.33333H14V12.6667ZM2 12.6667C2 13.0203 2.14048 13.3594 2.39052 13.6095C2.64057 13.8595 2.97971 14 3.33333 14H4C4.35362 14 4.69276 13.8595 4.94281 13.6095C5.19286 13.3594 5.33333 13.0203 5.33333 12.6667V10.6667C5.33333 10.313 5.19286 9.97391 4.94281 9.72386C4.69276 9.47381 4.35362 9.33333 4 9.33333H2V12.6667Z"
                    stroke="#7A777D"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
              <span className="menu-text">Customer Support</span>
            </div>
          </NavLink>
        )}
        {isMenuAllowed("settings") && (
          <NavLink to="/dashboard/settings" className="menu-link">
            <div className="menu-item">
              <span className="menu-logo">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#7A777D"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <circle cx="12" cy="12" r="3"></circle>
                  <path
                    d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 
                                    1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 
                                    1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 
                                    1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 
                                    1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"
                  ></path>
                </svg>
              </span>
              <span className="menu-text">Settings</span>
            </div>
          </NavLink>
        )}

        {isMenuAllowed("reconciliation-report") && (
          <NavLink to="/dashboard/reconciliation-report" className="menu-link">
            <div className="menu-item">
              <span className="menu-logo">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 18 18"
                  fill="none"
                  stroke="#7A777D"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.33329 1.33331H3.99996C3.64634 1.33331 3.3072 1.47379 3.05715 1.72384C2.8071 1.97389 2.66663 2.31302 2.66663 2.66665V13.3333C2.66663 13.6869 2.8071 14.0261 3.05715 14.2761C3.3072 14.5262 3.64634 14.6666 3.99996 14.6666H12C12.3536 14.6666 12.6927 14.5262 12.9428 14.2761C13.1928 14.0261 13.3333 13.6869 13.3333 13.3333V5.33331M9.33329 1.33331L13.3333 5.33331M9.33329 1.33331V5.33331H13.3333M10.6666 8.66665H5.33329M10.6666 11.3333H5.33329M6.66663 5.99998H5.33329"
                    stroke="#7A777D"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
              <span className="menu-text">Reconciliation Report</span>
            </div>
          </NavLink>
        )}

        {isMenuAllowed("reconciliation") && (
          <NavLink to="/dashboard/reconciliation" className="menu-link">
            <div className="menu-item">
              <span className="menu-logo">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 18 18"
                  fill="none"
                  stroke="#7A777D"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.33329 1.33331H3.99996C3.64634 1.33331 3.3072 1.47379 3.05715 1.72384C2.8071 1.97389 2.66663 2.31302 2.66663 2.66665V13.3333C2.66663 13.6869 2.8071 14.0261 3.05715 14.2761C3.3072 14.5262 3.64634 14.6666 3.99996 14.6666H12C12.3536 14.6666 12.6927 14.5262 12.9428 14.2761C13.1928 14.0261 13.3333 13.6869 13.3333 13.3333V5.33331M9.33329 1.33331L13.3333 5.33331M9.33329 1.33331V5.33331H13.3333M10.6666 8.66665H5.33329M10.6666 11.3333H5.33329M6.66663 5.99998H5.33329"
                    stroke="#7A777D"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
              <span className="menu-text">Reconciliation</span>
            </div>
          </NavLink>
        )}

        {isMenuAllowed("report-management") && (
          <NavLink to="/dashboard/report-management" className="menu-link">
            <div className="menu-item">
              <span className="menu-logo">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 18 18"
                  fill="none"
                  stroke="#7A777D"
                  strokeWidth="2"
                  strokeLinecap="round"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 13.3333V6.66667M8 13.3333V2.66667M4 13.3333V9.33333"
                    stroke="#7A777D"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
              <span className="menu-text">Report Management</span>
            </div>
          </NavLink>
        )}

        <div className="active-line"></div>
      </div>

      <div className="user">
        <div className="user-innerwrap">
          <div className="profile-info">
            <div className="img-wrap">
              <img src={Placeholder} alt="user-pic" />
            </div>
            <span className="user-name">
              {state?.user?.firstName + " " + state?.user?.lastName}
            </span>
          </div>
          <span className="logout-icon" onClick={handleLogout}>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7 19H3C2.46957 19 1.96086 18.7893 1.58579 18.4142C1.21071 18.0391 1 17.5304 1 17V3C1 2.46957 
                                1.21071 1.96086 1.58579 1.58579C1.96086 1.21071 2.46957 1 3 1H7M14 15L19 10M19 10L14 5M19 10H7"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
        </div>
      </div>
    </SidebarWrap>
  );
};

const SidebarWrap = styled.div`
  width: 100%;
  height: 100%;
  background-color: #022f1a;
  display: flex;
  flex-direction: column;

  .logo {
    height: 80px;
    border-bottom: 1px solid #9391b1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
  }

  .side-menu {
    height: 770px;
    padding: 20px;
    position: relative;
  }
  .active-line {
    width: 5px;
    height: 30px;
    background-color: var(--yellow-tint-90);
    position: absolute;
    left: 0;
    transition: all 0.2s ease;
  }

  .menu-link {
    display: inline-block;
    height: 30px;

    :not(:first-child) {
      margin-top: 15px;
    }
    :first-child {
      margin-top: 0px;
    }

    :hover {
      svg {
        stroke: var(--yellow-tint-90);
        transition: all 0.3s ease-in-out;
      }
      svg path {
        stroke: var(--yellow-tint-90);
        transition: all 0.3s ease-in-out;
      }
      .menu-text {
        color: var(--yellow-tint-90);
        transition: all 0.3s ease-in-out;
      }
    }
  }
  .menu-link.active {
    svg {
      stroke: var(--yellow-tint-90);
    }
    svg path {
      stroke: var(--yellow-tint-90);
    }
    .menu-text {
      color: var(--yellow-tint-90);
    }
  }
  .menu-link.active:nth-child(1) ~ .active-line {
    top: 15px;
  }
  .menu-link.active:nth-child(2) ~ .active-line {
    top: 60px;
  }
  .menu-link.active:nth-child(3) ~ .active-line {
    top: 105px;
  }
  .menu-link.active:nth-child(4) ~ .active-line {
    top: 150px;
  }
  .menu-link.active:nth-child(5) ~ .active-line {
    top: 195px;
  }
  .menu-link.active:nth-child(6) ~ .active-line {
    top: 240px;
  }
  .menu-link.active:nth-child(7) ~ .active-line {
    top: 285px;
  }
  .menu-link.active:nth-child(8) ~ .active-line {
    top: 330px;
  }
  .menu-link.active:nth-child(9) ~ .active-line {
    top: 375px;
  }
  .menu-link.active:nth-child(10) ~ .active-line {
    top: 420px;
  }
  .menu-item {
    display: flex;
    align-items: center;
  }
  .menu-text {
    margin-left: 16px;
    color: #7a777d;
    font-weight: 600;
    font-size: 15px;
  }

  .user {
    padding-top: 20px;
    border-top: 1px solid #9391b1;
    margin-top: auto;
    height: 150px;

    .user-innerwrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px;
    }

    .profile-info {
      display: flex;
      align-items: center;
    }

    .user-name {
      margin-left: 10px;
      color: #fff;
      font-size: 14px;
      font-weight: 600;
    }
    .img-wrap {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      overflow: hidden;
      background-color: #fff;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .logout-icon {
      cursor: pointer;
    }
  }
`;

export default Sidebar;
