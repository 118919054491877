import Api from "./Api";

const Services = {
  getAllOutlets(filterBy: string, limit: number, page: number) {
    var params = {
      ...(Boolean(filterBy) && { filter: filterBy }),
      limit,
      page,
    };
    console.log("/////PARAMS", params);
    return Api().get("getOutlets", { params });
  },
  getReodaOutlets(
    limit: number,
    page: number,
    specificDate: string,
    startDate: string,
    endDate: string
  ) {
    var params = {
      ...(Boolean(specificDate || startDate || endDate) && { type: "filter" }),
      ...(Boolean(specificDate) && { specificDate }),
      ...(Boolean(startDate) && { startDate }),
      ...(Boolean(endDate) && { endDate }),
      limit,
      page,
    };
    return Api().get("getOutlets", { params });
  },
  getMobiletraderOutlets(
    limit: number,
    page: number,
    specificDate: string,
    startDate: string,
    endDate: string
  ) {
    var params = {
      ...(Boolean(specificDate || startDate || endDate) && { type: "filter" }),
      ...(Boolean(specificDate) && { specificDate }),
      ...(Boolean(startDate) && { startDate }),
      ...(Boolean(endDate) && { endDate }),
      limit,
      page,
    };
    return Api().get("getOutletsMT", { params });
  },
  getActiveOutlets(
    limit: number,
    page: number,
    specificDate: string,
    startDate: string,
    endDate: string
  ) {
    var params = {
      ...(Boolean(specificDate || startDate || endDate) && { type: "filter" }),
      ...(Boolean(specificDate) && { specificDate }),
      ...(Boolean(startDate) && { startDate }),
      ...(Boolean(endDate) && { endDate }),
      limit,
      page,
    };
    return Api().get("active-outlets", { params });
  },
  CheckUserLogin(urno: number) {
    var params = {
      urno,
    };
    return Api().get("check-login-user", { params });
  },
  clearSession(urno: number, formData: any) {
    var params = {
      urno,
    };

    return Api().post("clearSession", formData, { params });
  },
};

export default Services;
