import React, { Dispatch, SetStateAction, useState } from "react";
import styled from "styled-components";
import { useAppContext } from "context/AppContext";
import { ConfigInputs } from "./ConfigurationSettings";
import ConfigService from "../../../services/ConfigService";
import toastr from "toastr";

interface EditWaitFormProps {
  handleModalClose: () => void;
  setWillFilter: any;
  configInputs: ConfigInputs;
  setConfigInputs: Dispatch<SetStateAction<ConfigInputs>>;
}

const EditWaitForm: React.FC<EditWaitFormProps> = ({
  handleModalClose,
  setWillFilter,
  setConfigInputs,
  configInputs,
}) => {
  const { dispatch } = useAppContext();
  const [waitNumber, setWaitNumber] = useState("");

  const handleKeyPress = (currentValue: any) => (e: any) => {
    const char = e.key;
    if (!/[0-9]/.test(char) && char !== "." && char !== "Backspace") {
      e.preventDefault();
    }
    if (char === "." && currentValue.includes(".")) {
      e.preventDefault();
    }
  };

  const handleEditOrder = (e: any) => {
    e.preventDefault();

    const params = {
      config: {
        ...configInputs,
        order_duration: waitNumber,
      },
    };

    async function updateData() {
      dispatch({ type: "data-loading" });
      try {
        const {
          data: { code, message },
        } = await ConfigService.updateOrderConfig(params);
        if (code === 201) {
          setConfigInputs((prev: any) => ({
            ...prev,
            order_duration: waitNumber,
          }));
          toastr.success(message);
          setWillFilter("true");
          handleModalClose();
        }
      } catch (error: any) {
        console.log(error.message);
      } finally {
        dispatch({ type: "data-loaded" });
      }
    }

    updateData();

    setWillFilter("order");
    // handleModalClose();
  };

  return (
    <Form>
      <form className="form-container" onSubmit={handleEditOrder}>
        <h2>Wait Time</h2>
        <div className="form-control">
          <div className="int-single">
            <label>Wait time</label>
            <input
              type="text"
              required
              placeholder="Enter time"
              value={waitNumber}
              onChange={(e) => setWaitNumber(e.target.value)}
              onKeyPress={handleKeyPress(waitNumber)}
            />
            <p>*The value of the time is in minutes</p>
          </div>
          <div className="btn-container">
            <button disabled={waitNumber === "" ? true : false}>Save</button>
          </div>
        </div>
      </form>
    </Form>
  );
};

const Form = styled.div`
  .form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .form-container h2 {
    color: #022f1a;
    font-weight: 500;
    margin-bottom: 30px;
  }
  .create {
    display: none;
  }
  .form-control {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  .int-group {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .int-group input {
    width: 48%;
    background: #fff;
    height: 40px;
    padding: 5px;
    border: 2px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
  }
  .int-group select {
    width: 48%;
    background: #fff;
    height: 40px;
    padding: 5px;
    border: 2px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
  }
  .int-single select {
    width: 100%;
    background: #fff;
    height: 40px;
    padding: 5px;
    border: 2px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
  }
  .int-single input {
    width: 100%;
    background: #fff;
    height: 40px;
    padding: 5px;
    border: 2px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
  }
  .int-single p {
    font-style: italic;
    font-size: 0.9rem;
    color: #111 !important;
    margin-top: 20px;
  }
  .btn-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    padding-bottom: 30px;
  }
  .btn-container button {
    background: #022f1a;
    width: 300px;
    color: #fff;
    padding: 10px 35px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 600;
  }
  .btn-container button:disabled {
    background: #ccd5d1;
    cursor: auto;
  }
  .int-single input[type="file"] {
    position: absolute;
    width: 100%;
    height: 100px;
    z-index: 102;
    opacity: 0;
  }
  .int-single label {
    display: block;
    color: #888;
    font-size: 0.9rem;
    font-weight: 600;
    margin-bottom: 15px;
  }
  .int-single p {
    font-size: 0.8rem;
    color: #999;
  }
  .int-single ._label {
    background: #ececec;
    width: 100%;
    height: 50px;
    border-top: 2px dashed #d4d4d4;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .lrg {
    height: 100px !important;
  }
  .int-single ._label p {
    font-size: 0.9rem;
    color: #022f1a;
    cursor: pointer;
  }

  .datee {
    width: 48%;
  }
  .datee label {
    display: block;
    color: #888;
    font-size: 0.9rem;
    font-weight: 600;
    margin-bottom: 15px;
  }
  .datee input {
    width: 100%;
    cursor: auto;
  }
  .datee select {
    width: 100%;
  }
`;
export default EditWaitForm;
