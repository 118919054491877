import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useAppContext } from "context/AppContext";
import styled from "styled-components";
import toastr from "toastr";
import AppVersionService from "services/AppVersionService";
import BannerService from "services/BannerService";
import { DepotSelect } from "components/DepotSelect";
import { RegionSelect } from "components/RegionSelect";
import { ItemType } from "../AppDistribution";
import UploadImg from "../../../../assets/svg/apk-upload.svg";
import UploadApkImg from "../../../../assets/svg/upload-img.svg";
import ImageItem from "./ImageItem";

interface appVersionFormProps {
  handleModalClose: () => void;
  item?: ItemType;
  setWillFilter?: any;
}

const CreateAppVersion: React.FC<appVersionFormProps> = ({
  handleModalClose,
  item,
  setWillFilter,
}) => {
  const [appVersion, setAppVersion] = useState("");
  const [start_date, setStart_Date] = useState("");
  const [end_date, setEnd_Date] = useState("");
  const [selectedDepots, setSelectedDepots] = useState<[]>([]);
  const [selectedRegions, setSelectedRegions] = useState<[]>([]);
  const [releaseNote, setReleaseNote] = useState("");
  const [apkFile, setApkFile] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [display, setDisplay] = useState("first");
  const [images, setImages] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isImgLoading, setIsImgLoading] = useState(false);

  const { dispatch } = useAppContext();

  const handleKeyPress = (currentValue: any) => (e: any) => {
    const char = e.key;
    if (!/[0-9.]/.test(char) && char !== "Backspace") {
      e.preventDefault();
      return;
    }

    if (char === "." && (currentValue.endsWith(".") || currentValue === "")) {
      e.preventDefault();
      return;
    }
  };
  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const fileList = event.target.files;

    if (fileList && fileList.length > 0) {
      setApkFile(URL.createObjectURL(fileList[0]));
    }
  };

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    const uploadPromises = acceptedFiles.map(async (file) => {
      const formData = new FormData();
      formData.append("file", file);

      try {
        setIsImgLoading(true);
        const response = await BannerService.uploadImage(formData); // Upload immediately
        setIsImgLoading(false);
        return response.data.data.imageUrl; // Return uploaded URL
      } catch (error) {
        console.error("Upload failed:", error);
        return null;
      }
    });

    // Wait for all images to be uploaded
    const urls = await Promise.all(uploadPromises);
    setImages((prevUrls: any) => [...prevUrls, ...urls.filter(Boolean)]); // Store valid URLs
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/*": [], // Accept any image type
    },
    multiple: true, // Allow multiple files
  });

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (images.length < 1) {
      toastr.error("APK Images are required");
    }

    var formData = new FormData(e.target as HTMLFormElement);

    const modifiedData = selectedDepots.map((obj: any) => {
      if (obj.name && typeof obj.name === "string") {
        const match = obj.name.match(/^[^(]+/); // Match characters before the opening parenthesis
        obj.name = match ? match[0].trim() : ""; // Trim whitespace and update the depot property
      }
      return obj;
    });
    const depotNames = modifiedData.map((data: any) => data.name);
    const newDepot: any = depotNames.map(
      (word) => word.charAt(0) + word.slice(1).toLowerCase()
    );

    const modifiedRegion = selectedRegions.map((obj: any) => {
      if (obj.name && typeof obj.name === "string") {
        const match = obj.name.match(/^[^(]+/); // Match characters before the opening parenthesis
        obj.name = match ? match[0].trim() : ""; // Trim whitespace and update the depot property
      }
      return obj;
    });
    const RegionNames = modifiedRegion.map((data: any) => data.name);
    const newRegion: any = RegionNames.map(
      (word) => word.charAt(0) + word.slice(1).toLowerCase()
    );
    const newImages: any = images;

    formData.append("version", appVersion);
    formData.append("depots", newDepot);
    formData.append("regions", newRegion);
    formData.append("releaseNote", releaseNote);
    formData.append("startDate", start_date);
    formData.append("endDate", end_date);
    // Append each image from the array
    formData.append("images", newImages);

    console.log(">D>D>D>D", formData);

    if (item) {
      let formData = {
        version: appVersion,
        depots: newDepot,
        regions: newRegion,
        images: images,
        releaseNote: releaseNote,
        startDate: start_date,
        endDate: end_date,
      };
      try {
        dispatch({ type: "action-loading" });
        setIsLoading(true);

        const {
          data: { code, message },
        } = await AppVersionService.updateAppVersion(item._id, formData);

        if (code === 200) {
          setIsLoading(false);
          toastr.success(message);

          item.version = appVersion;
          item.depots = newDepot;
          item.releaseNote = releaseNote;
          item.startDate = start_date;
          item.endDate = end_date;

          handleModalClose();
        }
      } catch (error: any) {
        setIsLoading(false);
        console.log(error);
        if (error.message === "Request failed with status code 503") {
          toastr.error("Version already exist");
        }
      } finally {
        dispatch({ type: "action-loaded" });
      }
    } else {
      try {
        dispatch({ type: "action-loading" });
        setIsLoading(true);
        const {
          data: { code, message },
        } = await AppVersionService.addAppVersion(formData);
        console.log(message);

        if (code === 200) {
          setIsLoading(false);
          toastr.success(message);
          setWillFilter("reload");
          handleModalClose();
        } else {
          console.log(code, "something");
        }
      } catch (error: any) {
        setIsLoading(false);
        console.log(error);
        if (error.message === "Request failed with status code 503") {
          toastr.error("Version already exist");
        }
      } finally {
        dispatch({ type: "action-loaded" });
      }
    }
  };

  const handleCloseImg = async (id: number) => {
    const tempData = images.filter((data, index) => index !== id);
    setImages(tempData);
  };

  useEffect(() => {
    if (item?.startDate) {
      const startDate = new Date(item.startDate);
      setStart_Date(startDate.toISOString().split("T")[0]);
    }
    if (item?.endDate) {
      const endDate = new Date(item.endDate);
      setEnd_Date(endDate.toISOString().split("T")[0]);
    }
    if (item?.version) {
      setAppVersion(item.version);
    }
    if (item?.releaseNote) {
      setReleaseNote(item?.releaseNote);
    }
    if (item?.downloadUrl) {
      setApkFile(item.downloadUrl);
    }
    if (item?.images) {
      setImages(item.images);
    }
  }, [item]);

  useEffect(() => {
    if (
      appVersion !== "" &&
      start_date !== "" &&
      releaseNote !== "" &&
      selectedDepots.length > 0 &&
      selectedRegions.length > 0
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [appVersion, start_date, releaseNote, selectedDepots, selectedRegions]);

  return (
    <Form>
      <form className="form-container" onSubmit={handleSubmit}>
        <h2>{item ? "Update APK version" : "Upload New APK"}</h2>
        <Label>
          <div className="label-ech" onClick={() => setDisplay("first")}>
            <p className="p">1.App details</p>
            <div className="liner"></div>
          </div>
          <div className="label-ech">
            <p className={display === "second" ? "p" : "ps"}>
              2.Upload app file
            </p>
            <div className={display === "second" ? "liner" : "liners"}></div>
          </div>
        </Label>
        {display === "first" && (
          <div className="form-control">
            <div className="int-single">
              <label>App version</label>
              <input
                type="text"
                required
                placeholder="Enter version number"
                value={appVersion}
                name="version"
                onChange={(e) => setAppVersion(e.target.value)}
                onKeyPress={handleKeyPress(appVersion)}
              />
            </div>
            <div className="int-group">
              <div className="datee">
                <label>Start date</label>
                <input
                  type="date"
                  required
                  name="startDate"
                  value={start_date}
                  onChange={(e) => setStart_Date(e.target.value)}
                  min={new Date().toISOString().split("T")[0]}
                />
              </div>
              <div className="datee">
                <label>End date</label>
                <input
                  type="date"
                  name="endDate"
                  value={end_date}
                  onChange={(e) => setEnd_Date(e.target.value)}
                  min={new Date().toISOString().split("T")[0]}
                />
              </div>
            </div>
            <RegionSelect
              selectedRegions={selectedRegions}
              setSelectedRegions={setSelectedRegions}
              regions={item?.regions ? item.regions : []}
            />

            <DepotSelect
              setSelectedDepots={setSelectedDepots}
              depots={item?.depots ? item.depots : []}
              selectedDepots={selectedDepots}
              selectedRegions={selectedRegions}
            />
            <div className="int-single">
              <label>Release note</label>
              <textarea
                required
                placeholder="Enter release note"
                value={releaseNote}
                name="releaseNote"
                onChange={(e) => setReleaseNote(e.target.value)}
              ></textarea>
            </div>
            <div className="btn-container">
              <button
                disabled={isDisabled ? true : false}
                onClick={() => setDisplay("second")}
              >
                Next
              </button>
            </div>
          </div>
        )}
        {display === "second" && (
          <div className="form-control">
            <div className="int-single">
              <label>Upload APK</label>

              <label className="_label lrg">
                <img src={UploadImg} alt="" />
                <input
                  type="file"
                  accept=".apk"
                  name="file"
                  required
                  disabled={item ? true : false}
                  onChange={handleFileUpload}
                />
                {apkFile && <div className="img__container">{apkFile}</div>}
                <p>Upload file APK</p>
              </label>
            </div>
            <div className="int-single">
              <label>Upload APK Image</label>

              <label className="_label lrg" {...getRootProps()}>
                {!isImgLoading || !isDragActive ? (
                  <img src={UploadApkImg} alt="" />
                ) : null}

                <input
                  type="file"
                  accept="image/*"
                  multiple
                  // required
                  // onChange={handleImages}
                  {...getInputProps()}
                />
                {isDragActive ? (
                  <div className="drop-files">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      height="50"
                      width="50"
                      fill="currentColor"
                    >
                      <path d="M1 14.5C1 12.1716 2.22429 10.1291 4.06426 8.9812C4.56469 5.044 7.92686 2 12 2C16.0731 2 19.4353 5.044 19.9357 8.9812C21.7757 10.1291 23 12.1716 23 14.5C23 17.9216 20.3562 20.7257 17 20.9811L7 21C3.64378 20.7257 1 17.9216 1 14.5ZM16.8483 18.9868C19.1817 18.8093 21 16.8561 21 14.5C21 12.927 20.1884 11.4962 18.8771 10.6781L18.0714 10.1754L17.9517 9.23338C17.5735 6.25803 15.0288 4 12 4C8.97116 4 6.42647 6.25803 6.0483 9.23338L5.92856 10.1754L5.12288 10.6781C3.81156 11.4962 3 12.927 3 14.5C3 16.8561 4.81833 18.8093 7.1517 18.9868L7.325 19H16.675L16.8483 18.9868ZM13 13V17H11V13H8L12 8L16 13H13Z"></path>
                    </svg>
                  </div>
                ) : (
                  <>
                    {isImgLoading ? (
                      <p>Please hold a little bit</p>
                    ) : (
                      <p>Click to upload image or drag and drop</p>
                    )}
                  </>
                )}
              </label>
              {images && (
                <div className="imgContainer">
                  {images.map((data, index) => (
                    <div key={index}>
                      <ImageItem
                        data={data}
                        handleCloseImg={handleCloseImg}
                        id={index}
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className="btn-container">
              <button disabled={isDisabled || isLoading ? true : false}>
                Finish Upload
              </button>
            </div>
          </div>
        )}
      </form>
    </Form>
  );
};

const Form = styled.div`
  .form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .form-container h2 {
    color: #022f1a;
    font-weight: 500;
    margin-bottom: 30px;
  }
  .form-control {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    height: 400px;
    overflow-y: auto;
    padding: 0 15px;
  }
  .int-group {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .int-single {
    position: relative;
  }
  .int-group input {
    width: 48%;
    background: #fff;
    height: 40px;
    padding: 5px;
    border: 2px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
  }
  .int-group select {
    width: 48%;
    background: #fff;
    height: 40px;
    padding: 5px;
    border: 2px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
  }
  .int-single select,
  .int-single input {
    width: 100%;
    background: #fff;
    height: 40px;
    padding: 5px;
    border: 2px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    position: relative;
    outline: none;
  }
  .int-single input {
    cursor: text;
  }
  .int-single textarea {
    width: 100%;
    background: #fff;
    height: 120px;
    padding: 5px;
    border: 2px solid #ccc;
    border-radius: 5px;
    outline: none;
  }
  .btn-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .btn-container button {
    background: #022f1a;
    width: 200px;
    color: #fff;
    padding: 10px 35px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 600;
  }
  .btn-container button:disabled {
    background: #ccd5d1;
    cursor: auto;
  }
  .int-single input[type="file"] {
    position: absolute;
    width: 100%;
    height: 100px;
    // z-index: 102;
    opacity: 0;
    cursor: pointer;
  }
  .int-single label {
    display: block;
    color: #888;
    font-size: 0.9rem;
    font-weight: 600;
    margin-bottom: 15px;
  }
  .int-single p {
    font-size: 0.8rem;
    color: #999;
  }
  .int-single ._label {
    background: #ececec;
    width: 100%;
    height: 50px;
    border-top: 2px dashed #d4d4d4;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  .lrg {
    height: 100px !important;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .int-single ._label p {
    font-size: 0.9rem;
    color: #333;
    cursor: pointer;
    font-weight: normal;
  }
  .img__container {
    position: absolute;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    padding: 10px;
    overflow-x: scroll;
    overflow-y: hidden;
    scrollbar-width: none;
    background: #fff;
    height: 100%;
    border-bottom: 2px dashed #d4d4d4;
  }
  .imgContainer {
    position: position;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 25px;
    padding: 10px;
    overflow-x: auto;
    overflow-y: hidden;
    margin-top: 20px;
  }
  .error_message {
    width: 100%;
    display: flex;
    justify-content: center;
    color: #d22730 !important;
    font-size: 0.9rem;
  }
  .datee {
    width: 48%;
  }
  .datee label {
    display: block;
    color: #888;
    font-size: 0.9rem;
    font-weight: 600;
    margin-bottom: 15px;
  }
  .datee input {
    width: 100%;
    cursor: auto;
  }
  .datee select {
    width: 100%;
  }
`;

const Label = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 18px;
  margin-bottom: 20px;

  .label-ech {
    width: 45%;
    text-align: left;

    .p {
      font-size: 0.9rem;
      color: #022f1a;
      margin-bottom: 10px;
      cursor: pointer;
    }
    .ps {
      font-size: 0.9rem;
      color: #8d8b8f;
      margin-bottom: 10px;
      cursor: default;
    }

    .liner {
      height: 5px;
      width: 100%;
      border-radius: 100px;
      background: #022f1a;
    }
    .liners {
      height: 5px;
      width: 100%;
      border-radius: 100px;
      background: #8d8b8f;
    }
  }
`;

export default CreateAppVersion;
