import filter from "assets/svg/filter.svg";
import userPlus from "assets/svg/user-plus.svg";
import Pagination from "components/Pagination";
import React, { Dispatch, useEffect, useState } from "react";
import styled from "styled-components";
import { UserDetailsTable } from "./UserDetails";
import AuthService from "services/AuthenticationService";

import { useSearchParams } from "react-router-dom";
import { TableSkeleton } from "components";

interface AdminProps {
  setIsInvite: Dispatch<React.SetStateAction<boolean>>;
}

export type AdminUserType = {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  location: string;
  roles: string[];
  isActive: boolean;
  blocked: boolean;
};

export const Admin: React.FC<AdminProps> = ({ setIsInvite }) => {
  const [tableData, setTableData] = useState<AdminUserType[]>([]);
  const [pageParams, setPageParams] = useSearchParams();
  const [page, setPage] = useState(() => {
    let p = Number(pageParams.get("page"));
    return p > 0 ? p : 1;
  });
  const [pages, setPages] = useState<number>(2);
  const [limit, setLimit] = useState(10);
  useEffect(() => {
    async function init() {
      try {
        const response = await AuthService.getAllAdmin(limit, page);
        const {
          data: { data },
        } = response;
        setTableData(data);
      } catch (error: any) {
        console.error(error);
      }
    }
    init();
  }, [limit, page]);
  return (
    <>
      <Div className="grid admin-content">
        <nav className="flex">
          <div>
            <h4 className="fs-500 text-black-900 fw-600">
              Users{" "}
              <span className="fs-300 text-light bg-blue-tint-050 badge ">
                {tableData.length}
              </span>
            </h4>
          </div>
          <div className="flex">
            <button className="flex btn btn-default-ghost pointer">
              <div>
                <svg
                  width="15"
                  height="12"
                  viewBox="0 0 15 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.3334 0.75H1.66675L6.33342 6.26833V10.0833L8.66675 11.25V6.26833L13.3334 0.75Z"
                    stroke="#022f1a"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <span>Filter</span>
            </button>
            <button
              className="flex btn btn-default pointer"
              onClick={() => {
                setIsInvite(true);
              }}
            >
              <div>
                <img src={userPlus} alt="add user" />
              </div>
              <span>Invite User</span>
            </button>
          </div>
        </nav>
        {tableData.length !== 0 ? (
          <UserDetailsTable {...{ tableData, setTableData }} />
        ) : (
          <TableSkeleton />
        )}
        <Pagination
          limit={limit}
          page={page}
          pages={pages}
          setLimit={setLimit}
          setPage={setPage}
        />
      </Div>
    </>
  );
};

const Div = styled.div.attrs(() => {})`
  /* Admin Profile */
  --gap: 0.1rem;
  margin-block: 2em;
  width: 100%;
  nav {
    background-color: hsla(var(--clr-light));
    align-items: center;
    justify-content: space-between;
    padding-inline: 1.2em;
    padding-block: 1.5em;

    div:nth-child(2) {
      --gap: 2rem;
    }

    button {
      --gap: 0.2rem;
      display: inline-flex;
      align-items: center;
      justify-content: space-around;
      padding: 0.3em 1em;
      transition: 200ms;
    }
    .btn-default {
      &:hover {
        background-color: hsla(var(--clr-blue-tint-300));
        color: hsla(var(--clr-gray-50));
      }
      &:disabled {
        background: hsla(var(--clr-blue-tint-900));
        border-color: hsla(var(--clr-blue-tint-900));
        color: hsla(var(--clr-black-tint-100));
      }
    }
    .btn-default-ghost {
      &:hover {
        color: hsla(var(--clr-blue-tint-300));
        border-color: hsla(var(--clr-blue-tint-300));
        svg > path {
          stroke: hsla(var(--clr-blue-tint-300));
        }
      }
      &:disabled {
        color: hsla(var(--clr-black-tint-100));
        border-color: hsla(var(--clr-black-tint-100));
      }
    }
    .badge {
      display: inline-grid;
      place-items: center;
      border-radius: 50%;
      width: 27px;
      height: 27px;
    }
  }
`;
