import styled from "styled-components";
import ReactDOM from "react-dom";
import { Button } from "components";
import React, { Dispatch, useEffect, useState } from "react";
import ProductService from "services/ProductService";
import DownloadImg from "../../../assets/svg/download.svg";
import { ItemType } from "./AppDistribution";
import { useAppContext } from "context/AppContext";
import closeSvg from "../../../assets/svg/close.svg";

interface ModalProps {
  item: ItemType;
  display: boolean;
  setIsModal: Dispatch<React.SetStateAction<boolean>>;
}

export const ViewDetailsModal: React.FC<ModalProps> = ({
  item,
  display,
  setIsModal,
}) => {
  const { state, dispatch } = useAppContext();

  const [version, setVersion] = useState(item.version);
  const [relaseNote, setReleaseNote] = useState(item.releaseNote);
  const [selectedImg, setSelectedImg] = useState("");
  const [showPreview, setShowPreview] = useState(false);
  const [images, setImages] = useState(item.images);
  let splittedDepots = item.depots.join(", ");
  let startDate = new Date(item.startDate);
  let endDate = new Date(item.endDate);

  const handleImageClick = (img: string) => {
    setShowPreview(true);
    setSelectedImg(img);
  };

  if (!display) return null;
  return ReactDOM.createPortal(
    <>
      <StyledOverlay />
      <StyledModal className="modal-container">
        <Close className="pointer close" onClick={() => setIsModal(false)} />
        <h1 className="fs-600 fw-400 text-blue-dark-050 hed">
          App version {version}
        </h1>
        <div className="contain">
          <div className="details-container">
            <header>
              <p>Date</p>
            </header>
            <div className="det-det">
              <p>
                {`${startDate.getDate()}/${
                  startDate.getMonth() + 1
                }/${startDate.getFullYear()} - ${endDate.getDate()}/${
                  endDate.getMonth() + 1
                }/${endDate.getFullYear()}`}
              </p>
            </div>
          </div>
          <div className="details-container">
            <header>
              <p>Depot</p>
            </header>
            <div className="det-det">
              <p> {splittedDepots}</p>
            </div>
          </div>
          <div className="details-container">
            <header>
              <p>Release Note</p>
            </header>
            <div className="det-det">
              <p> {relaseNote}</p>
            </div>
          </div>
          <div className="details-container">
            <header>
              <p>Image(s)</p>
            </header>
            {images?.length > 0 ? (
              <div className="image-container">
                {images?.map((img, index) => (
                  <div
                    key={index}
                    className="img-cnt"
                    onClick={() => handleImageClick(img)}
                  >
                    <img src={img} alt="" />
                  </div>
                ))}
              </div>
            ) : (
              <div className="image-container">
                <p>No Images</p>
              </div>
            )}
          </div>
          <div className="details-container">
            <div className="btn-container">
              <a href={item.downloadUrl}>
                Download app
                <img src={DownloadImg} alt="" />
              </a>
            </div>
          </div>
        </div>
      </StyledModal>
      {showPreview && (
        <Preview>
          <div className="img-preview-cnt">
            <div className="close">
              <img
                src={closeSvg}
                alt=""
                onClick={() => setShowPreview(false)}
              />
            </div>
            <div className="main">
              <div className="img-main-cnt">
                <div className="img-main">
                  <img src={selectedImg} alt="" />
                </div>
              </div>
              <div className="img-thumbnail">
                {images?.map((img, index) => (
                  <div
                    key={index}
                    className={`img-thumb ${
                      selectedImg === img ? "active" : ""
                    }`}
                    onClick={() => setSelectedImg(img)}
                  >
                    <img src={img} alt="" />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Preview>
      )}
    </>,
    document.getElementById("portal")!
  );
};

const Close = ({ ...props }) => {
  return (
    <div {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18 6L6 18M6 6L18 18"
          stroke="#0D0C0E"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

const StyledOverlay = styled.div.attrs(() => {})`
  /* position: relative; */
  position: absolute;
  inset: 0;
  height: 100vh;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.7);
  transition: 0.5s;
  &::after {
  }
`;

const StyledModal = styled.div`
  position: fixed;
  width: 66.5em;
  height: 47.125em;
  top: 50%;
  left: 55%;
  background-color: hsla(var(--clr-light));
  background-color: #ececec;
  margin: 0 auto;
  transform: translate(-50%, -50%);
  z-index: 99;
  transition: 0.3s;
  place-items: center;

  .close {
    position: absolute;
    right: 5%;
    top: 5%;
  }
  .hed {
    text-align: center;
    padding: 20px 0;
    padding: 2em;
  }

  .contain {
    height: 600px;
  overflow-y: auto;
  }
  .details-container,
  .images-container,.products-container {
    background-color: hsla(var(--clr-light));
  }

  header {
    padding: 1em 3em 0 3em;
    font-size: var(--fs-500);
    font-weight: 600;
    color: var(--clr-dark);
    margin: 0
  }
  .det-det {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 1em 4em;
    font-size: var(--fs-250);
  }
.image-container {
    width: 100%;
     padding: 1em 4em;
    display: flex;
    align-items: center;
    gap: 25px;
  }
.img-cnt{
    width: 80px;
    height: 80px;
  }
.img-cnt img{
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

    .btn-container {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 4em 0;
  }
  .btn-container a {
    background: #022f1a;
    width: 250px;
    color: #fff;
    padding: 10px 35px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 600;
    display: flex;
    gap: 10px;
  }

}
`;

const Preview = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 400;
  background-color: rgba(0, 0, 0, 0.7);
  transition: 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s;

  .close {
    display: flex;
    justify-content: flex-end;
  }
  .close img {
    cursor: pointer;
  }

  .main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .img-preview-cnt {
    // height: 400px;
    width: 50%;
    padding: 50px;
    border-radius: 15px;
    background: #fff;
  }
  .img-main-cnt {
    display: flex;
    justify-content: center;
  }
  .img-main {
    width: 300px;
    height: 300px;
    display: flex;
    justify-content: center;
  }
  .img-main img {
    width: 100%;
    height: 100%;
  }

  .img-thumbnail {
    width: 500px;
    margin-top: 40px;
    display: flex;
    gap: 40px;
    justify-content: center;
    overflow-x: auto;
  }
  .img-thumb {
    width: 80px;
    height: 80px;
    cursor: pointer;
    flex: 0 0 auto;
  }
  .img-thumb.active {
    border: 3px solid blue;
    padding: 5px;
    border-radius: 10px;
  }
  .img-thumb img {
    width: 100%;
    height: 100%;
  }
`;
