import React, { useState, useEffect } from "react";
import Pagination from "../../components/Pagination";
import OrderService from "../../services/OrderManagementService";
import {
  useNavigate,
  useSearchParams,
  useOutletContext,
  useParams,
} from "react-router-dom";
import { formatMoney } from "../../utils/formatMoney";
import { useAppContext } from "context/AppContext";

import styled from "styled-components";
import { EmptyList, TableSkeleton } from "components";
import { OrderByOutletType, orderHeaders, OrdersProp } from "./types";

const OutletHistory = ({ status }: OrdersProp) => {
  const { state, dispatch } = useAppContext();
  const [tableData, setTableData] = useState<OrderByOutletType[]>([]);
  const [pageParams, setPageParams] = useSearchParams();
  const [page, setPage] = useState(() => {
    let p = Number(pageParams.get("page"));
    return p > 0 ? p : 1;
  });
  const [pages, setPages] = useState<number>(0);
  const [limit, setLimit] = useState(10);
  const { outletId } = useParams();
  // const [toCsv, setText, setFileName, , startDate, endDate, filter] =
  // useOutletContext<RouterOutletContextType>();
  const navigate = useNavigate();

  useEffect(() => {
    console.log("##: ", outletId);
    const fetchData = async () => {
      try {
        dispatch({ type: "data-loading" });
        const {
          data: {
            data: { data, meta },
          },
        } = await OrderService.getOrdersByOutlet(
          outletId!,
          page,
          limit,
          status!
        );
        console.log(data, meta);

        setTableData(data);
        // setText(toCsv(csvData));
        // setFileName("all-orders.csv");
        setPages(meta.pageCount);
        setPageParams({ page: page.toString() });
      } catch (error) {
        console.error(error);
        setTableData([]);
      } finally {
        dispatch({ type: "data-loaded" });
      }
    };
    fetchData();
  }, [limit, page /*filter*/]);

  const handleNavigate = (item: OrderByOutletType) => {
    navigate("/dashboard/outlet-management/order-details", { state: { item } });
  };

  return (
    <HistoryWrap className="grid">
      {state?.isLoading ? (
        <TableSkeleton />
      ) : tableData.length === 0 ? (
        <EmptyList />
      ) : (
        <>
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  {orderHeaders.map((header) => (
                    <th key={header.key}>{header.item}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {tableData.map((item, index) => {
                  let date = new Date(item.createdAt);
                  let method = item.IsMobileMoney ? "wallet" : "cash";
                  return (
                    <tr key={index}>
                      <td onClick={() => handleNavigate(item)}>{item.token}</td>
                      <td>{item.paymentStatus}</td>
                      <td>{item.customerInfo.region}</td>
                      <td>{item.customerInfo.depot}</td>
                      <td>{item.deviceChannel}</td>
                      <td>{item.customerInfo.urno}</td>
                      <td>{item.customerInfo.customercode}</td>
                      <td className="quantity">{item.totalQuantity}</td>
                      <td className={`method ${method}`}>{method}</td>
                      <td>{formatMoney(item.totalAmount)}</td>
                      <td className={`status ${item.deliveryStatus}`}>
                        {item.deliveryStatus}
                      </td>
                      <td className="table-date">
                        <p>{`${date.getDate()}/${
                          date.getMonth() + 1
                        }/${date.getFullYear()}`}</p>
                        <p>{`${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`}</p>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <Pagination
            limit={limit}
            setLimit={setLimit}
            page={page}
            pages={pages}
            setPage={setPage}
          />
        </>
      )}
    </HistoryWrap>
  );
};

const HistoryWrap = styled.div`
  .table-container {
    min-height: calc(100vh - 370px);
  }

  th,
  td {
    font-size: 13px;
  }

  .table-date p:nth-child(2) {
    font-size: 11px;
  }
  .quantity {
    text-align: center;
  }
  .status {
    font-weight: 600;
  }
  .status.delivered {
    color: #198155;
  }
  .status.pending {
    color: #834d1d;
  }
  .status.failed {
    color: #d3180c;
  }
  .status.awaiting {
    color: #221f58;
  }
  .method {
    font-weight: 600;
    text-transform: capitalize;
  }
  .method.cash {
    color: #060607;
  }
  .method.wallet {
    color: #995a22;
  }
`;

export default OutletHistory;
