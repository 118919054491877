import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAppContext } from "../../context/AppContext";
import BaseLoader from "../Loaders/BaseLoader";

const Authenticated = () => {
  const { state } = useAppContext();

  if (
    !state?.loading &&
    localStorage.getItem("token") &&
    !state?.isAuthenticated
  ) {
    return <div>Loading123...</div>;
  }

  if (state?.loading) {
    return <BaseLoader />;
  } else if (!state?.isAuthenticated) {
    return <Navigate to="/" />;
  } else {
    return <Outlet />;
  }
};

export default Authenticated;
